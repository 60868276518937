export const TITLE_SECTION_COMPLEMENTARY_BENEFITS = {
  es_kc: 'Beneficios Complementarios',
  es_km: 'Beneficios Complementarios',
  es_mx: 'Beneficios Complementarios',
};

export const TITLE_SECTION_OPTIONAL_BENEFITS = {
  es_kc: 'Beneficios Opcionales',
  es_km: 'Beneficios Opcionales',
  es_mx: 'Beneficios Opcionales',
};

export const LABEL_BENEFITS_INCLUDED = {
  es_kc: 'Beneficios Incluidos',
  es_km: 'Beneficios Incluidos',
  es_mx: 'Beneficios Incluidos',
};

export const TITLE_SECTION_SCHENGEN_ELIGIBLE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/pago',
  es_km: '/cotizar-asistencia/pago',
  es_mx: '/cotizar-asistencia/pago',
};

export const COVERAGE_DESCRIPTION = {
  es_kc: 'Descripción de la cobertura',
  es_km: 'Descripción de la cobertura',
  es_mx: 'Descripción de la cobertura',
};

export const BTN_SELECT = {
  es_kc: 'Seleccionar',
  es_km: 'Seleccionar',
  es_mx: 'Seleccionar',
};
