import React from 'react';

import {
  langKc, langKm, langMx,
} from '../../location/urls';
import contactanosCo01 from '../../assets/img/contactanos/contactanos-co-01.png';
import contactanosCo02 from '../../assets/img/contactanos/contactanos-co-02.png';
import contactanosCo03 from '../../assets/img/contactanos/contactanos-co-03.png';
import contactanosMx01 from '../../assets/img/contactanos/contactanos-mx-01.png';
import contactanosMx02 from '../../assets/img/contactanos/contactanos-mx-02.png';
import contactanosMx03 from '../../assets/img/contactanos/contactanos-mx-03.png';
import contactanosMx04 from '../../assets/img/contactanos/contactanos-mx-04.png';

const coContactanos = [
  {
    id: 1,
    title: 'Ventas:',
    content: (
      <p>
        <strong>Horario:</strong>
        {' '}
        Lunes a Viernes De 8.00 am – 6.00 pm
        <br />
        {' '}
        <strong>Tel:</strong>
        {' '}
        +57 601 6462828 / EXT 1143105 – 1143103
        <br />
        {' '}
        <strong>Mail:</strong>
        {' '}
        Rocio.salinas@axa-assistance.com.co
        <br />
        {' '}
        Yoriany.amaya@axa-assistance.com.co
      </p>
    ),
    image: contactanosCo01,
  },
  {
    id: 2,
    title: 'PQR:',
    content: (
      <p>
        <strong>Horario:</strong>
        {' '}
        Lunes a Viernes De 8.00 am – 6.00 pm
        <br />
        {' '}
        <strong>Tel:</strong>
        {' '}
        +57 601 6462828 / 1148061
        <br />
        {' '}
        <strong>Mail:</strong>
        {' '}
        servicioalcliente@axa-assistance.com.co
      </p>
    ),
    image: contactanosCo02,
  },
  {
    id: 3,
    title: 'Solicitar asistencia:',
    content: (
      <p>
        <strong>En Estados Unidos Toll Free Tel:</strong>
        {' '}
        1 888 323 17 34
        <br />
        {' '}
        <strong>En Colombia (por cobrar) Tel:</strong>
        {' '}
        (57)(601) 644 61 99
        <br />
        {' '}
        <strong>Resto del mundo (por cobrar):</strong>
        {' '}
        1 866 681 90 23'
      </p>
    ),
    image: contactanosCo03,
  },
];

const mxContactanos = [
  {
    id: 1,
    title: 'Área de ventas',
    content: (
      <p>
        Lunes a viernes de las 09:00hrs. a 20:00 hrs.
        <br />
        {' '}
        Sábados y Domingos 10:00 hrs. a 16:00 hrs.
        <br />
        {' '}
        01800 681 5696 - Opción 1'
      </p>
    ),
    image: contactanosMx01,
  },
  {
    id: 2,
    title: 'Dudas sobre tu póliza de viaje',
    content: (
      <p>
        Lunes a viernes de las 09:00hrs. a 20:00 hrs.
        <br />
        {' '}
        Sábados y Domingos 10:00 hrs. a 16:00 hrs.
        <br />
        {' '}
        01800 681 5696 - Opción 1
        <br />
        {' '}
        <br />
        {' '}
      </p>
    ),
    image: contactanosMx02,
  },
  {
    id: 3,
    title: 'Recibir Asistencia inmediata',
    content: (
      <p>
        24 horas / 7 días a la semana
        <br />
        {' '}
        Interior de la República: 01800 681 5696 - Opción 2
        <br />
        {' '}
        Teléfono local para recibir Asistencia fuera de México: 42-11-19-25
        <br />
        {' '}
        + LADA (correspondiente del país donde se encuentre).
      </p>
    ),
    image: contactanosMx03,
  },
  {
    id: 4,
    title: 'Siniestros',
    content: (
      <p>
        Lunes a Viernes de 8:00hrs. a 20:00hrs.
        <br />
        {' '}
        01800 681 5696 - Opción 3
      </p>
    ),
    image: contactanosMx04,
  },
];

export default {
  [langKc]: coContactanos,
  [langKm]: mxContactanos,
  [langMx]: mxContactanos,
};
