import React from 'react';
import {
  langKm, langKc, langMx,
} from '../../location/urls';

import logoAxa from '../../assets/img/logo_axa.png';
import logoUstia from '../../assets/img/footer/USTIA_LOGO.jpg';
import logoVotedBestTravelInsurance from '../../assets/img/footer/voted_best_travel_insurance.png';

const es_kc = {
  left: {
    logo: logoAxa,
    text: [
      { text: 'AXA Assistance', id: 'CO_LEFT_TEXT_1' },
      { text: 'Partners Colombia', id: 'CO_LEFT_TEXT_2' },
      { text: 'Todos los Derechos Reservados', id: 'CO_LEFT_TEXT_3' },
    ],
  },
  center: {
    options: [
      {
        title: 'SOBRE NOSOTROS',
        id: 'CO_CENTER_OBJECT_1',
        links: [
          {
            value: 'AXA Assistance',
            to: 'https://www.axa-assistance.com.co/',
            id: 'CO_CENTER_LINK_1',
            external: true,
          },
          {
            value: 'Nuestros Productos',
            to: '/planes',
            id: 'CO_CENTER_LINK_2',
          },
          { value: 'Blog', to: '/blog', id: 'CO_CENTER_LINK_3' },
        ],
      },
      {
        title: 'AYUDA',
        id: 'CO_CENTER_OBJECT_2',
        links: [
          {
            value: 'Solicitar Asistencia',
            to: '/solicitar-asistencia',
            id: 'CO_CENTER_LINK_4',
          },
          {
            value: 'Preguntas Frecuentes',
            to: '/faqs',
            id: 'CO_CENTER_LINK_5',
          },
          { value: 'Contactanos', to: '/contactanos', id: 'CO_CENTER_LINK_6' },
          {
            value: 'Politicas de Privacidad',
            to: '/aviso-de-privacidad',
            id: 'CO_CENTER_LINK_7',
          },
        ],
      },
    ],
  },
  right: {
    text: {
      title: 'VENTA TELEFONICA',
      number: '+57 601 6462828',
      id: 'CO_RIGHT_TEXT_1',
    },
    logos: [
      { src: logoUstia, link: 'https://www.ustia.org/', id: 'CO_RIGHT_LOGO_1' },
      {
        src: logoVotedBestTravelInsurance,
        link: 'https://www.interbrand.com/',
        id: 'CO_RIGHT_LOGO_3',
      },
    ],
  },
  down: {
    text:
      'En caso de dudas, sugerencias, reclamos ó para mayor información, contáctenos en el siguiente horario de Lunes a Viernes de 09 a 21hs en los teléfonos +54 11 4378-6002 / 0800-3331163 ó vía mail a ventadirecta@axa-assistance.com.ar',
  },
};

const es_km = {
  left: {
    logo: logoAxa,
    text: [
      { text: 'AXA Partners', id: 'MX_LEFT_TEXT_1' },
      { text: 'México S.A de C.V', id: 'MX_LEFT_TEXT_2' },
      { text: 'Todos los Derechos Reservados', id: 'MX_LEFT_TEXT_3' },
    ],
  },
  center: {
    options: [
      {
        title: 'SOBRE NOSOTROS',
        id: 'MX_CENTER_OBJECT_1',
      },
      {
        title: 'AYUDA',
        id: 'MX_CENTER_OBJECT_2',
      },
    ],
  },
  right: {
    text: {
      title: 'CONTRATA TU PLAN',
      number: '0800-333-1163',
      id: 'MX_RIGHT_TEXT_1',
    },
    logos: [
      { src: logoUstia, link: 'https://www.ustia.org/', id: 'MX_RIGHT_LOGO_1' },
      {
        src: logoVotedBestTravelInsurance,
        link: 'https://www.interbrand.com/',
        id: 'MX_RIGHT_LOGO_3',
      },
    ],
  },
  down: {
    text:
      'En caso de dudas, sugerencias, reclamos ó para mayor información, contáctenos en el siguiente horario de Lunes a Viernes de 09 a 21hs en los teléfonos +54 11 4378-6002 / 0800-3331163 ó vía mail a ventadirecta@axa-assistance.com.ar',
  },
};
const es_mx = {
  left: {
    logo: logoAxa,
    text: [
      { text: 'AXA Partners', id: 'MX_LEFT_TEXT_1' },
      { text: 'México S.A de C.V', id: 'MX_LEFT_TEXT_2' },
      { text: 'Todos los Derechos Reservados', id: 'MX_LEFT_TEXT_3' },
    ],
  },
  center: {
    options: [
      {
        title: 'SOBRE NOSOTROS',
        id: 'MX_CENTER_OBJECT_1',
        links: [
          {
            value: 'Nuestros Productos',
            to: '/mis-planes',
            id: 'MX_CENTER_LINK_2',
          },
          { value: 'Blog', to: '/blog', id: 'MX_CENTER_LINK_3' },
        ],
      },
      {
        title: 'AYUDA',
        id: 'MX_CENTER_OBJECT_2',
        links: [
          {
            value: 'Solicitar Asistencia',
            to: '/asistencia',
            id: 'MX_CENTER_LINK_4',
          },
          { value: 'Preguntas Frecuentes', to: '/faq', id: 'MX_CENTER_LINK_5' },
          { value: 'Contactanos', to: '/contactanos', id: 'MX_CENTER_LINK_6' },
          {
            value: 'Politicas de Privacidad',
            to: '/avisodeprivacidad',
            id: 'MX_CENTER_LINK_7',
          },
        ],
      },
    ],
  },
  right: {
    text: {
      title: 'VENTA TELEFONICA',
      number: '0800-333-1163',
      id: 'MX_RIGHT_TEXT_1',
    },
    logos: [
      { src: logoUstia, link: 'https://www.ustia.org/', id: 'MX_RIGHT_LOGO_1' },
      {
        src: logoVotedBestTravelInsurance,
        link: 'https://www.interbrand.com/',
        id: 'MX_RIGHT_LOGO_3',
      },
    ],
  },
  down: {
    text:
      'En caso de dudas, sugerencias, reclamos ó para mayor información, contáctenos en el siguiente horario de Lunes a Viernes de 09 a 21hs en los teléfonos +54 11 4378-6002 / 0800-3331163 ó vía mail a ventadirecta@axa-assistance.com.ar',
  },
};

export default {
  [langKc]: es_kc,
  [langKm]: es_km,
  [langMx]: es_mx,
};
