import React from 'react';
import EsInformation from './EsInformation/EsInformation';

function NotSet() {
  return <h1>Error Page</h1>;
}

const components = {
  es_kc: EsInformation,
  es_km: EsInformation,
  es_mx: EsInformation,
};
function AsistenciaInformation({ formType, ...props }) {
  const Component = components[formType] || NotSet;
  return <Component {...props} />;
}

export default AsistenciaInformation;
