import React, { useContext } from 'react';
import { Formik } from 'formik';
import { LocationContext } from '../../../../../components/Location/Location';
import * as translations from './intl';
import { IntlContext } from '../../../../../intl';
import { formInitialValues } from './InitialValues';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import PersonArea from './PersonArea/PersonArea';
import { validationDataSchema } from './validationSchema';
import PaymentForm from '../PaymentForm/PaymentForm';
import styles from './EsDataForm.module.scss';

function EsDataForm({
  aceptLosTermos,
  amountFormatedWithBenefits,
  children,
  existsOptionalBenefitSelected,
  finalPrice,
  formSubmitRefs,
  formatNumber,
  incidentSubmitRef,
  recibirEmail,
  schemas,
  setAmountWithInterestInstallments,
  setLoading,
  stateForm,
  step3,
  submit,
  updateParent,
}) {
  const { idiom } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const { Quote } = utils.getFormDataStep1();
  const initialValues = formInitialValues(idiom, Quote);
  const validationSchema = validationDataSchema(intl, idiom);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const {
          values, touched, errors, handleSubmit,
        } = props;

        updateParent(values, validationSchema);

        const mappedPerson = values.viajeiros.map((currentValue, index) => {
          const viajeirosErrors = errors.viajeiros
            ? errors.viajeiros[index]
            : {};
          const touchedViajeiros = touched.viajeiros
            ? touched.viajeiros[index]
            : {};
          return (
            <PersonArea
              key={`Person-${index}`}
              _touched={touchedViajeiros}
              _errors={viajeirosErrors}
              index={index}
              step1={utils.getFormDataStep1()}
              step2={utils.getFormDataStep2()}
              step3={step3}
              setLoading={setLoading}
              existsOptionalBenefitSelected={existsOptionalBenefitSelected}
              {...props}
            />
          );
        });

        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
              />
              <div className="row">
                <div className="col-6">
                  <div className="col-12" style={{ fontSize: 14 }}>
                    <strong>{intl.TRIP_INFORMATION}</strong>
                  </div>
                </div>
              </div>
              <div className={styles.step3Body}>
                <div className={styles.leftBody}>{mappedPerson}</div>
                <div className={styles.rightBody}>
                  {children}
                  <PaymentForm
                    finalPrice={finalPrice}
                    step3={step3}
                    setAmountWithInterestInstallments={
                      setAmountWithInterestInstallments
                    }
                    recibirEmail={recibirEmail}
                    aceptLosTermos={aceptLosTermos}
                    submit={submit}
                    stateForm={stateForm}
                    formSubmitRefs={formSubmitRefs}
                    setLoading={setLoading}
                    schemas={schemas}
                    updateParent={updateParent}
                    amountFormatedWithBenefits={amountFormatedWithBenefits}
                    formatNumber={formatNumber}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default EsDataForm;
