import React from 'react';

import styles from './Select.module.scss';
import { safeGet } from '../../utils/object';

const removeRequiredCharLabel = data => (data && data.slice(-1) === '*' ? data.substring(0, data.length - 1) : data);

function Select({
  autoComplete,
  children,
  dangerouslySetInnerHTML,
  dataTestid,
  defaultStyle,
  defaultValue,
  disabled,
  error,
  errorNoMessage,
  formikProps,
  hideArrow = false,
  label,
  name,
  onBlur,
  onChange,
  touched,
  value,
}) {
  if (formikProps) {
    onChange = onChange || formikProps.handleChange;
    onBlur = formikProps.handleBlur;
    error = safeGet(formikProps, `errors.${name}`);
    touched = safeGet(formikProps, `touched.${name}`);
    value = safeGet(formikProps, `values.${name}`);
  }

  let className = '';
  if (errorNoMessage && error && touched) className += `${styles.error}`;
  if (defaultStyle) className += `${styles.defaultStyle}`;
  const classHideArrow = hideArrow ? styles.selectHideArrow : '';
  const formikError = error && '1';
  return (
    <div className={` ${styles.Select} ${classHideArrow} `}>
      <select
        formik-error={formikError}
        className={className}
        placeholder={label}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        data-testid={dataTestid}
        autoComplete={autoComplete}
      >
        {children}
      </select>
      {error && touched && !errorNoMessage && (
        <label className={styles.errorLabel}>{error}</label>
      )}
    </div>
  );
}

export default Select;
