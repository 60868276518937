/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';

function OTCookieHTMILInit() {
  useEffect(() => {
    const oneTrusss = typeof window.OneTrust !== 'undefined';
    oneTrusss && window.OneTrust.initializeCookiePolicyHtml(true);
  }, []);
  return (<></>);
}
export default OTCookieHTMILInit;
