import {
  langKm, langKc, langMx,
} from '../location/urls';

export const ISOCo = 'COP';
export const ISOMx = 'MXN';

export const getCurrencyByFormType = (formType) => {
  let currencyValue;

  switch (formType) {
    case langKc:
      currencyValue = ISOCo;
      break;

    case langMx:
      currencyValue = ISOMx;
      break;
    case langKm:
      currencyValue = ISOMx;
      break;
    default:
      currencyValue = ISOCo;
      break;
  }
  return currencyValue;
};

export const getAmountFormat = (amount) => (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
