import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Nosotros.module.scss';
import { NOSOTROS } from '../../utils/category';
import { LocationContext } from '../../components/Location/Location';
import Banner from '../../components/Banner/Banner';
import NosotrosContent from './NosotrosContent';
import { dataLayerTrack } from '../../utils/GTM_helper';

function Nosotros() {
  const { idiom, country } = useContext(LocationContext);
  const location = useLocation();

  useEffect(() => {
    dataLayerTrack(
      {},
      idiom,
      country,
      location,
      'Sobre nosotros | AXA Assistance Mexico',
    );
  }, []);

  return (
    <div
      className={`container-fluid ${styles.nosotros}`}
      data-testid="pageNosotros"
    >
      <Banner datatestid="bannerNosotros" category={NOSOTROS} />
      <NosotrosContent idiom={idiom} />
    </div>
  );
}

export default Nosotros;
