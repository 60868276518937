export const TITLE_META_TAG = {
  es_kc:
    'AXA Partners: Asistencias de viaje I Viajen sin preocupación',
  es_mx: 'AXA Partners: Asistencias de viaje I Viajen sin preocupación',
  es_km: 'AXA Partners: Asistencias de viaje I Viajen sin preocupación',
};

export const DESCRIPTION_META_TAG = {
  es_kc:
    '¡Contacte a AXA Assistance Colombia para una cotización de seguro de viaje hoy! Desde la protección de cancelación de viaje hasta la emergencia médica y los efectos personales, AXA lo tiene cubierto.',
  es_mx:
    '¡Contacte a AXA Assistance Mexico para una cotización de seguro de viaje hoy! Desde la protección de cancelación de viaje hasta la emergencia médica y los efectos personales, AXA lo tiene cubierto.',
  es_km:
    '¡Contacte a AXA Assistance Mexico para una cotización de seguro de viaje hoy! Desde la protección de cancelación de viaje hasta la emergencia médica y los efectos personales, AXA lo tiene cubierto.',
};

export const KEYWORDS_META_TAG = {
  es_kc:
    'Seguro de viaje, seguro de viaje, asistencia de viaje, retraso de equipaje, retraso de viaje, cancelación de viaje, cobertura médica de viaje, seguro de viaje nacional, seguro de viaje internacional, seguro de viaje AXA',
  es_mx:
    'Seguro de viaje, seguro de viaje, asistencia de viaje, retraso de equipaje, retraso de viaje, cancelación de viaje, cobertura médica de viaje, seguro de viaje nacional, seguro de viaje internacional, seguro de viaje AXA',
  es_km:
    'Seguro de viaje, seguro de viaje, asistencia de viaje, retraso de equipaje, retraso de viaje, cancelación de viaje, cobertura médica de viaje, seguro de viaje nacional, seguro de viaje internacional, seguro de viaje AXA',
};

export const TITLE_SEO = {
  es_kc: 'Cotizar Asistencia al viajero',
  es_mx: 'Cotizar seguro de viaje',
  es_km: 'Cotizar seguro de viaje',

};

export const BTN_COTIZAR = {
  es_kc: 'Cotizar Ahora',
  es_mx: 'Cotizar',
  es_km: 'Cotizar',
};
