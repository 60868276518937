import Prismic from 'prismic-javascript';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getBenefits: async headers => {
    const result = await getDataFromPrismic(
      headers,
      'benefits',
      Prismic.Predicates.gt('my.benefits.enable', 0),
    );

    const benefits = result.map(benefit => ({
      id: benefit.data.id,
      title: benefit.data.title,
      content: benefit.data.content,
      url: benefit.data.icon_url.url,
    }));
    return benefits;
  },

  getBenefitByBenefitCode: async (headers, benefitCode) => {
    const result = await getDataFromPrismic(
      headers,
      'benefits_icon',
      Prismic.Predicates.at('my.benefits_icon.BenefitCode', benefitCode),
      { field: 'id', orderings: 'asc' },
    ).catch(err => console.warn('Error fetching benefit icons content', err));

    let benefitRes = {};
    if (result) {
      result.forEach(benefit => {
        benefitRes = {
          id: benefit.data.id,
          BenefitCode: benefit.BenefitCode,
          icon_url: benefit.data.icon_url.url,
        };
      });
    }
    return benefitRes;
  },
});
