import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Asistencia.module.scss';

import { LocationContext } from '../../components/Location/Location';
import AsistenciaContent from './AsistenciaContent';
import Banner from '../../components/Banner/Banner';
import { ASISTENCIA } from '../../utils/category';
import { dataLayerTrack } from '../../utils/GTM_helper';

function Asistencia() {
  const { idiom, country } = useContext(LocationContext);
  const location = useLocation();
  useEffect(() => {
    let textValue = '';

    if (country === 'mx' || country === 'km') {
      textValue = '¿Cómo solicitar una Asistencia? | AXA Assistance Mexico';
    }
    if (country === 'co' || country === 'kc') {
      textValue = 'Solicitar Asistencia';
    }

    dataLayerTrack({}, idiom, country, location, textValue);
  }, []);

  return (
    <div className={`${styles.asistencia}`} data-testid="pageAsistencia">
      <Banner datatestid="bannerAsistencia" category={ASISTENCIA} />
      <AsistenciaContent idiom={idiom} />
    </div>
  );
}

export default Asistencia;
