import Prismic from 'prismic-javascript';
import moment from 'moment';
import 'moment/locale/es';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getBlogs: async (headers, category) => {
    headers.category = category;
    const result = await getDataFromPrismic(
      headers,
      'blog',
      Prismic.Predicates.gt('my.blog.enable', 0),
      { field: 'last_publication_date', orderings: 'desc' },
    );

    const lang = headers['Accept-Language'].substring(0, 2);

    moment.locale(lang);

    const blogs = result.map((blog) => ({
      uid: blog.uid,
      title: blog.data.title,
      short_content: blog.data.short_content,
      icon_url: blog.data.icon_url.url,
      code_seo: blog.data.code_seo,
      date_of_publication: moment(blog.data.date_of_publication).format(
        'DD MMMM YYYY',
      ),
      last_publication_date: moment(blog.last_publication_date)
        .format('MMMM YYYY')
        .replace(/\b(\w)/g, (match, char) => char.toUpperCase()),
    }));

    return blogs;
  },

  getBlogById: async (headers, blogID) => {
    const result = await getDataFromPrismic(
      headers,
      'blog',
      Prismic.Predicates.at('my.blog.uid', blogID),
    );

    const lang = headers['Accept-Language'].substring(0, 2);

    moment.locale(lang);

    const fullDetail = result[0].data;

    const blogDetail = {
      uid: result[0].uid,
      id: fullDetail.id,
      title: fullDetail.title,
      short_content: fullDetail.short_content,
      description: fullDetail.description,
      icon_url: fullDetail.icon_url.url,
      date_of_publication: moment(fullDetail.date_of_publication).format(
        'DD MMMM YYYY',
      ),
      last_publication_date: moment(result[0].last_publication_date)
        .format('DD MMMM YYYY')
        .replace(/\b(\w)/g, (match, char) => char.toUpperCase()),
    };
    return blogDetail;
  },
});
