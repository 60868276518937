import React, { useState, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { LocationContext } from '../Location/Location';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import Carousel from '../Carousel/Carousel';
import styles from './Banner.module.scss';
import { BannerService } from '../../services';
import BannerItem from './BannerItem/BannetItem';

const objectBanner = [
  {
    id: '1',
    title: null,
    content: null,
    url:
      'https://images.prismic.io/b2c-banorte/41f72bf4-6db9-4079-8b94-6c3b57b50339_SolicitarAsistencia-compressed.png?auto=compress,format',
  },
];

function Banner({ category, datatestid, form }) {
  const [loading, setLoading] = useState(true);
  const { idiom, idiomAPI } = useContext(LocationContext);
  const [banner, setBanner] = useState();

  const [ismobile, setIsmobile] = useState(isMobile);
  const isMX = idiom === 'es_mx' || idiom === 'es_km';
  const isMexNote = banner && banner[0] && banner[0].content && isMX;

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (!ismobile) {
        try {
          const response = await Promise.resolve(
            BannerService.getBanner(idiomAPI, category),
          );
          setBanner(response);
        } catch (err) {
          setBanner(objectBanner);
        }
      }
      setLoading(false);
    };
    if (idiom) loadData(setBanner);
  }, [idiom]);

  const mappedImages = banner
    && banner.map((value, index) => (
      <BannerItem
        idiom={idiom}
        form={form}
        key={`banner-${index}`}
        banner={value}
        setLoading={setLoading}
      />
    ));

  return (
    <>
      {loading && <LoadingCircle />}
      <div className={styles.wrapBanner} data-testid={datatestid}>
        {!ismobile && banner && banner.length === 1 && (
          <BannerItem
            idiom={idiom}
            form={form}
            banner={banner[0]}
            setLoading={setLoading}
          />
        )}
        {!ismobile && banner && banner.length > 1 && (
          <Carousel>{mappedImages}</Carousel>
        )}
        {ismobile && (
          <BannerItem
            idiom={idiom}
            form={form}
            banner={{}}
            setLoading={setLoading}
          />
        )}
      </div>

      {isMexNote && <p className={styles.note}>{banner[0].content}</p>}

      <div className={styles.formMobile}>
        <BannerItem
          idiom={idiom}
          form={form}
          setLoading={setLoading}
          banner={{}}
        />
      </div>
    </>
  );
}

export default Banner;
