import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { BannerService } from '../../services';
import styles from './Header.module.scss';
import HeaderList from './HeaderList/HeaderList';
import { LocationContext } from '../Location/Location';
import headerOptions from './Header_Text';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import PromotionsBanner from '../PromotionsBanner/PromotionsBanner';
import { PROMOTION } from '../../utils/category';
import HeaderStep from '../HeaderStep/HeaderStep';
import Button from '../Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import kayakAxaSafeTrip from '../../assets/img/axa_logo_solid_rgb.png';
import kayakLogoSafeTrip from '../../assets/img/KAYAK_Icon_RGB.png';

const loadData = async (setBanner, idiomAPI) => {
  try {
    const bannerPromotion = await Promise.resolve(
      BannerService.getBanner(idiomAPI, PROMOTION),
    );
    const banner = bannerPromotion.length > 0 ? bannerPromotion[0] : false;
    setBanner(banner);
  } catch (err) {
    console.log(err);
  }
};

const stepRoutes = [
  ['/cotizar-asistencia/cotizar', '/get-a-quote-now/cotizar'], // step1
  ['/cotizar-asistencia/planes', '/get-a-quote-now/planes'], // step2
  ['/cotizar-asistencia/pago', '/get-a-quote-now/pago'], // step3
  ['/cotizar-asistencia/gracias', '/get-a-quote-now/gracias'], // step4
];

const getStepNumberByRouter = (path) => {
  let stepNumber = false;
  stepRoutes.forEach((item, index) => {
    item.forEach((route) => {
      if (path === route) {
        stepNumber = index + 1;
      }
    });
  });
  return stepNumber;
};

function Header(props) {
  const { location } = props;
  const [textData, setTextData] = useState({
    logo: {},
    headerMenu: [],
  });
  const [navMobile, setNavMobile] = useState(false);
  const [banner, setBanner] = useState(false);
  const [stepByRoute, setStepByRoute] = useState(false);
  const [scroll, setScroll] = useState(false);
  const { idiom, idiomAPI } = useContext(LocationContext);
  const { logo } = textData;
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const isHome =
    location && (location.pathname === '/home' || location.pathname === '/');

  const [headerText] = useAllPrismicDocumentsByType('contact_phone_header', {
    lang: 'es-km',
  });

  const listenerScroll = () => {
    setScroll(window.scrollY > 600);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenerScroll);

    return () => {
      window.removeEventListener('scroll', listenerScroll);
    };
  });
  const windowWidth = window.matchMedia('(max-width: 768px)');

  const verifyWindowWidth = (e) => {
    if (e.matches) {
      setNavMobile(true);
      return;
    }
    setNavMobile(false);
  };

  useEffect(() => {
    verifyWindowWidth(windowWidth);
    windowWidth.addListener(verifyWindowWidth);
    return () => {
      windowWidth.removeListener(verifyWindowWidth);
    };
  }, []);

  useEffect(() => {
    const headerMenuOption = headerOptions[idiom];
    const isEspecificPage = location && location.pathname === '/clubmedterms';
    const headMenuClone = { ...headerMenuOption };

    if (isEspecificPage) {
      headMenuClone.headerMenu = [];
    }
    setTextData(headMenuClone);
  }, [location.pathname, idiom]);

  useEffect(() => {
    setBanner(null);
    if (isHome) {
      loadData(setBanner, idiomAPI);
    }
    setStepByRoute(getStepNumberByRouter(location.pathname));
  }, [location.pathname]);

  const scrollTop = () => window.scrollTo(0, 0);

  const HeaderButtonAndLogo = useCallback(
    () => (
      <>
        <button
          onClick={() => {
            setNavMobile(!navMobile);
          }}
          className={`navbar-toggler ${styles.btnMobileNav}`}
          type="button"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <img
          data-testid="kayakLogoSafeTrip"
          className={`${styles.navBarBrandLogoKayak} ml-4`}
          src={kayakLogoSafeTrip}
          alt="logo_"
        />
      </>
    ),
    [kayakLogoSafeTrip, navMobile, setNavMobile],
  );

  return (
    <>
      <h1 className={styles.none}>{intl.TITLE_SEO}</h1>
      {banner && <PromotionsBanner banner={banner} />}
      <nav
        className={`navbar sticky-top navbar-light navbar-expand-md navbar-expand-lg ${styles.customNav}`}
      >
        <Helmet>
          <title>{intl.TITLE_META_TAG}</title>
          <meta
            name="description"
            lang="es-ES"
            content={intl.DESCRIPTION_META_TAG}
          />
          <meta name="keywords" lang="es-ES" content={intl.KEYWORDS_META_TAG} />
        </Helmet>
        <Link
          className={`navbar-brand ${
            stepByRoute === 2 || stepByRoute === 3 ? styles.logoAxaHeader : ''
          }`}
          to="/"
        >
          {logo && (
            <div className="d-flex flex-row">
              <img
                data-testid="kayakAxaSafeTrip"
                className={styles.navBarBrandLogoAXA}
                src={kayakAxaSafeTrip}
                alt="logo_"
              />
            </div>
          )}
        </Link>
        {stepByRoute === 2 || stepByRoute === 3 ? (
          <div className={styles.stepsHeader}>
            <HeaderStep step={stepByRoute} />
          </div>
        ) : navMobile ? (
          <>
            <div className="d-flex flex-row">
              <HeaderButtonAndLogo />
            </div>
            {headerText && headerText.length > 0 && (
              <HeaderList
                navMobile={navMobile}
                setNavMobile={setNavMobile}
                headerMenu={[
                  {
                    value: headerText[0].data.phone,
                    id: headerText[0].id,
                    link: 'tel:5547444727',
                    icon: headerText[0].data.icon_phone.url,
                  },
                ]}
              />
            )}
          </>
        ) : (
          <div className="d-flex flex-row">
            {headerText && headerText.length > 0 && (
              <HeaderList
                navMobile={navMobile}
                setNavMobile={setNavMobile}
                headerMenu={[
                  {
                    value: headerText[0].data.phone,
                    id: headerText[0].id,
                    link: 'tel:5547444727',
                    icon: headerText[0].data.icon_phone.url,
                  },
                ]}
              />
            )}
            <HeaderButtonAndLogo />
          </div>
        )}
        {isHome && scroll && (
          <div className={styles.buttonCotizar}>
            <Button homeBanner blue onClick={scrollTop}>
              {intl.BTN_COTIZAR}
            </Button>
          </div>
        )}
      </nav>
    </>
  );
}

export default withRouter(Header);
