import { get } from 'lodash';

export const deepEqual = (x, y) => (x && y && typeof x === 'object' && typeof y === 'object'
  ? Object.keys(x).length === Object.keys(y).length
        && Object.keys(x).reduce((isEqual, key) => isEqual && deepEqual(x[key], y[key]), true)
  : x === y);

export const safeGet = (target, key, defaultValue = '') => {
  if (!target) return undefined;
  return get(target, key, defaultValue);
};
