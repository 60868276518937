export const TEXT_TITLE = {
  es_kc: 'Planes SAFETRIP',
  es_km: 'Planes SAFETRIP',
  es_mx: 'Planes SAFETRIP',
};

export const TEXT_DESCRIPTION = {
  es_kc:
    'La prevención es lo más importante, por este motivo le invitamos a conocer nuestro planes de asistencia en viaje Internacional, no permita que un incidente arruine su viaje, por eso, antes de pensar en las maletas, lo que va a llevar o todas las cosas maravillosas que va a ver, es importante pensar en adquirir el mejor plan de asistencia para que su experiencia no se arruine con nada.<br /><br /> En AXA nos preocupamos por usted, por eso queremos brindarle varias alternativas, le presentamos nuestros diferentes planes de asistencia para que elija el que mejor se adapte a sus necesidades y no tenga que preocuparse por no tener los recursos necesarios ante cualquier situación de emergencia que se presente durante su viaje. <br /><br /> <a href="https://www.axa-asistenciaviaje.com/documents/21611176/0/T%C3%A9rminos+y+Condiciones+%281%29.pdf/f1cbcc53-48fc-c427-cbbe-5cec0210e132" target="_BLANK">Términos y Condiciones</a>',
  es_km: 'La prevención es lo más importante para que un incidente no arruine tu viaje, por eso, antes de pensar en las maletas, lo que vas a llevar o todas las cosas maravillosas que vas a ver, es importante pensar en una asistencia de viaje para que tu experiencia no se arruine con nada.<br /><br /> SAFETRIP ofrece diferentes planes para que se adapten a tus necesidades y no tengas que preocuparte por no tener los recursos necesarios ante cualquier situación que se presente durante tu viaje.',
  es_mx:
    'La prevención es lo más importante para que un incidente no arruine tu viaje, por eso, antes de pensar en las maletas, lo que vas a llevar o todas las cosas maravillosas que vas a ver, es importante pensar en una asistencia de viaje para que tu experiencia no se arruine con nada.<br /><br /> SAFETRIP ofrece diferentes planes para que se adapten a tus necesidades y no tengas que preocuparte por no tener los recursos necesarios ante cualquier situación que se presente durante tu viaje.',
};

export const YOUTUBE_LINK = {
  es_kc: 'https://www.youtube.com/user/MexicoSegurosAXA',
  es_km: 'https://www.youtube.com/user/MexicoSegurosAXA',
  es_mx: 'https://www.youtube.com/user/MexicoSegurosAXA',
};

export const TEXT_TITLE_YOUTUBE = {
  es_kc: '',
  es_km: 'Comprar una póliza',
  es_mx: 'Comprar una póliza',
};

export const TEXT_DESCRIPTION_YOUTUBE = {
  es_kc: '',
  es_km: 'Comprar una <strong>póliza con AXA</strong> es súper-sencillo. En este vídeo te mostramos cómo.',
  es_mx: 'Comprar una <strong>póliza con AXA</strong> es súper-sencillo. En este vídeo te mostramos cómo.',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Planes',
  es_km: 'Comparar planes de seguro de viaje | AXA Assistance Mexico',
  es_mx: 'Comparar planes de seguro de viaje | AXA Assistance Mexico',
};
