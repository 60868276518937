import React, { Component } from 'react';
import styles from './CookiePolicyPage.module.css';
import OTCookieHTMLInit from './OTCookieHTMLInit';

const CookiePolicyPage = () => (
  <section className={styles['main-section']}>
    <OTCookieHTMLInit />
    <h1 className="text-center pb-3">POLITICA DE COOKIES</h1>
    <section className={styles['sub-section']}>
      <p className="mt-0">Una cookie es un pequeño fichero de datos (archivo de texto) que un sitio web,
        cuando es visitado por un usuario, pide a su navegador que almacene en su
        dispositivo para conservar información sobre él, como sus preferencias de idioma o
        sus datos de acceso. AXA Partners recopila e instala cookies "internas" durante
        su visita a <a href="https://safetrip.axapartners.mx">https://safetrip.axapartners.mx</a>,
        y también utiliza cookies "de terceros",
        que son cookies de un dominio diferente al del sitio web que está visitando,
        de conformidad con los fines descritos en la presente política.
      </p>
      <h4 className={styles['cookie-heading']}>Actualización de la política actual</h4>
      <p>AXA Partners puede actualizar esta política de cookies de vez en cuando en respuesta
        a los cambios relativos a las cookies eliminadas durante la navegación por el sitio
        web <a href="https://safetrip.axapartners.mx">https://safetrip.axapartners.mx</a>.
        Esta política de privacidad se actualizó por
        última vez el 2 de Junio de 2023.
      </p>
      <h4 className={styles['cookie-heading']}>¿QUIÉNES SON LOS RESPONSABLES DEL TRATAMIENTO DE SUS DATOS PERSONALES? </h4>
      <p>AXA Assistance México, S.A. de C.V., con domicilio en Avenida Félix Cuevas 366 - piso 6,
        México, actúa como responsable independiente del tratamiento de sus datos personales
        (significa que determina los fines y los medios del tratamiento de sus datos personales).
      </p>
      <p>El responsable de los datos independiente se denomina en esta política
        <b> "AXA Partners" o "Responsable de los datos" o "nosotros" o "nos".</b>
      </p>
      <h4 className={styles['cookie-heading']}>¿CUÁLES SON SUS DERECHOS EN RELACIÓN CON SUS DATOS? </h4>
      <p>El depósito de cookies<b> puede implicar</b> el
        <b> tratamiento de sus datos personales</b>.
        En tal caso, y de acuerdo con los requisitos de la LFPDPPP, usted tiene
        <b> derechos</b> que puede ejercer.
        Consulte nuestro<a href="/avisodeprivacidad"> Aviso de Privacidad </a>
        para consultar dichos derechos.
      </p>
      <p>Además, usted tiene derecho de <b>dar consentimiento </b>
        ("opt-in") al depósito de cookies opcionales.
      </p>
      <p>Usted puede <b>actualizar</b> su <b>configuración</b> de cookies en
        <b> cualquier momento</b> a través del centro de preferencias
        disponible aquí <button type="button" id="ot-sdk-btn" style={{ background: 'transparent', fontSize: '1.2rem', border: '0', padding: '0', cursor: 'pointer', color: '#007bff' }} class="ot-sdk-show-settings preferences-button">Centro de Preferencias de Cookies</button>.
      </p>
      <h4 className={styles['cookie-heading']}>¿CÓMO PONERSE EN CONTACTO CON EL DPO PARA ATENDER CUALQUIER SOLICITUD O
        EJERCER SUS DERECHOS?
      </h4>
      <p>Si tiene alguna pregunta, queja o comentario sobre este aviso de información o para
        ejercer sus derechos enumerados anteriormente, diríjase por correo electrónico a
        <a href="mailto:protecciondedatos@axa-assistance.com.mx"> protecciondedatos@axa-assistance.com.mx</a>.
        Su solicitud se remitirá al DPO correspondiente.
        AXA Partners podrá solicitarle información adicional para confirmar su identidad y/o
        para ayudarle a localizar los datos que busca.
      </p>
      <h4 className={styles['cookie-heading']}>¿Cómo reclamar ante una autoridad supervisora?</h4>
      <p>En caso de alguna inquietud respecto del tratamiento de datos personales,
        puedes consultar al Instituto Nacional de Transparencia, Acceso a
        la Información y Protección de Datos Personales (INAI)
        en <a href="https://home.inai.org.mx/" target="_blank" rel="noopener noreferrer">www.inai.org.mx</a>.
      </p>
      <h4 className={styles['cookie-heading']}>¿Cuáles son las categorías de las cookies utilizadas mientras
        navega por nuestro sitio web y para qué sirven?
      </h4>
      {/* OneTrust Cookies List start */}
      <div id="ot-sdk-cookie-policy" />
      {/* <!-- OneTrust Cookies List end */}
      <h4 className={styles['cookie-heading']}>LISTA DE NUESTROS SOCIOS COMERCIALES (PROVEEDORES EXTERNOS DE COOKIES)</h4>
      <p>
        Algunas <b>funciones</b> de este sitio web dependen de <b>servicios</b> ofrecidos
        por <b> terceros proveedores</b>. Si usted da su <b>consentimiento ("opt-in")</b>, estos
        <b> terceros proveedores</b> dejarán depositar cookies que, por ejemplo, le permitirán
        <b> visualizar</b> contenidos alojados por estos terceros en nuestro sitio web y
        <b> compartir</b> nuestros contenidos. Estos terceros pueden <b>recoger</b> sus
        datos de navegación y <b>utilizar</b> los datos recogidos a través de sus cookies
        <b> para los fines que hayan determinado</b> de conformidad con
        <b> su política de privacidad</b>. Para ejercer sus derechos sobre los datos recogidos
        a través de cookies de terceros o para cualquier pregunta sobre su tratamiento,
        debe ponerse en contacto directamente con sus organizaciones. En este sitio web
        utilizamos cookies de los siguientes terceros:
      </p>
      <table>
        <tbody>
          <tr>
            <th className="text-center">Nombre del tercero</th>
            <th className="text-center">Categorías de cookies y sus finalidades</th>
            <th className="text-center">Política de privacidad de terceros </th>
          </tr>
          <tr>
            <td>Google (Doubleclick)</td>
            <td>
              <ul>
                <li>Cookies funcionales y técnicas (cookies estrictamente necesarias)</li>
                <br />
                <li>Cookies de publicidad comportamental (segmentación)</li>
              </ul>
            </td>
            <td><a href="https://policies.google.com/privacy?hl=es" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=es</a> </td>
          </tr>
          {/* <tr>
            <td>Microsoft Application Insights - cdn.cookielaw.org</td>
            <td>ai_session - Cookies de segmentación</td>
            <td><a href="/avisodeprivacidad">Politicas de Privacidad</a></td>
          </tr> */}
        </tbody>
      </table>
      <h4 className={styles['cookie-heading']}>¿Cómo aceptar o rechazar las cookies?</h4>
      <p>
        A partir de su primera visita al sitio web
        <a href="https://safetrip.axapartners.mx"> https://safetrip.axapartners.mx</a>,
        aparecerá un banner con una cookie. Este banner de cookies le permite
      </p>
      <ul>
        <li className={styles['li__cookie-function']}>
          <b>Aceptar todas las cookies opcionales</b> que no sean estrictamente
          necesarias para el correcto funcionamiento del sitio web, o bien
        </li>
        <li className={styles['li__cookie-function']}>
          <b>Rechazar todas las cookies</b> que no sean estrictamente necesarias
          para el correcto funcionamiento del sitio web,
        </li>
        <li className={styles['li__cookie-function']}>
          <b>Acceda al Centro de preferencias de cookies</b> para
          <b> configurar sus opciones</b> en términos de consentimiento
          (lo que significa que puede seleccionar individualmente las cookies
          que acepta que caigan durante la navegación aunque no sean esenciales
          para el correcto funcionamiento del sitio web).
        </li>
      </ul>
      <p>Almacenaremos sus preferencias, tanto si acepta como si rechaza las cookies,
        durante <b>6 meses</b>.Una vez transcurrido este plazo, aparecerá el banner de cookies
        para que vuelva a configurar sus preferencias. Puede ajustar la configuración de las
        cookies en cualquier momento a través del <button type="button" id="ot-sdk-btn" style={{ background: 'transparent', fontSize: '1.2rem', border: '0', padding: '0', cursor: 'pointer', color: '#007bff' }} class="ot-sdk-show-settings preferences-button">Centro de Preferencias de Cookies</button>.
      </p>
      <h4 className={styles['cookie-heading']}>¿Es obligatorio el depósito de cookies?</h4>
      <p>
        El carácter <b>estrictamente necesario</b> u <b>opcional</b> de cada categoría
        de cookies se indica en el mismo momento en que se le pide que otorgue
        (o deniegue) su consentimiento, bien a través del banner de cookies con
        ocasión de su primera visita a nuestro sitio web, bien a través del <button type="button" id="ot-sdk-btn" style={{ background: 'transparent', fontSize: '1.2rem', border: '0', padding: '0', cursor: 'pointer', color: '#007bff' }} class="ot-sdk-show-settings preferences-button"> Centro de  Preferencias de Cookies</button>.
      </p>
      <p>
        El <button type="button" id="ot-sdk-btn" style={{ background: 'transparent', border: '0', padding: '0', fontSize: '1.2rem', cursor: 'pointer', color: '#007bff' }} class="ot-sdk-show-settings preferences-button">Centro de Preferencias de Cookies</button> sigue siendo accesible
        <b> en cualquier momento en el</b> pie de página del sitio web, pero también haciendo
        clic en <b>'Personalizar mis opciones'</b> en el <b>banner de cookies</b>.
      </p>
      <p>
        Tenga en cuenta que, si rechaza las cookies opcionales, es posible que algunas
        funciones, páginas y áreas del sitio web no estén disponibles, de lo cual no
        nos hacemos responsables.
      </p>
    </section>
  </section>
);

export default CookiePolicyPage;
