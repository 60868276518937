import { formatData } from './formatData';
import { CotizarService } from '../../../../services';
import { trackExceptionMonitor } from '../../../../AppInsightsTracks';

let enrollResponse = '';
let bodyFormater = {};

const submit = async (props) => {
  const { formType } = props;
  const enroll = formatData(props);

  bodyFormater = enroll;

  enrollResponse = await CotizarService.step3CotizarEnroll(formType, enroll);
};

export const submitEnroll = async (props) => {
  const {
    setSubmitting, history, actions, values,
  } = props;
  let reponseSubmit = null;
  let reponseErrorSubmit = null;

  try {
    await submit(props);
    if (enrollResponse) {
      if (
        enrollResponse
        && enrollResponse.data
        && enrollResponse.data.Reserve
      ) {
        if (enrollResponse.data.Reserve.length > 0) reponseSubmit = enrollResponse.data;
      } else {
        reponseErrorSubmit = enrollResponse;
      }
    } else {
      trackExceptionMonitor(
        'submitEnroll - Step3 - enrollResponse',
        {},
        { bodyFormater, enrollResponse },
      );
    }
  } catch (error) {
    const errorObject = JSON.parse(JSON.stringify(error));
    trackExceptionMonitor('submitEnroll - Step3 - catch', error, errorObject);
    return reponseSubmit;
  }

  return { reponseSubmit, reponseErrorSubmit };
};
