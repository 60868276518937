import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';
import BlogItem from './BlogItem/BlogItem';
import styles from './Blog.module.scss';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import banner from '../../assets/img/blog-page.png';
import { BlogService } from '../../services';
import { LocationContext } from '../../components/Location/Location';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import Banner from '../../components/Banner/Banner';
import { BLOG } from '../../utils/category';
import { dataLayerTrack } from '../../utils/GTM_helper';

function Blog() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const response = await Promise.resolve(
          BlogService.getBlogList(idiomAPI),
        );
        setPosts(response);
      } catch (err) {
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };
    if (idiom) loadData();
  }, [idiom]);

  useEffect(() => {
    dataLayerTrack(intl, idiom, country, location);
  }, []);

  const mappedPosts = posts.map(currentValue => (
    <BlogItem key={currentValue.code_seo} value={currentValue} />
  ));

  if (loading) {
    return (
      <h1 className={styles.loading}>
        <LoadingCircle />
      </h1>
    );
  }

  return (
    <div className={`${styles.blog}`} data-testid="pageBlogs">
      <Banner datatestid="bannerBlog" category={BLOG} />
      <div className={`row ${styles.content}`}>
        {posts.length > 0 && mappedPosts}
      </div>
    </div>
  );
}

export default Blog;
