import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Banner from '../../components/Banner/Banner';
import { LocationContext } from '../../components/Location/Location';
import { IntlContext } from '../../intl';
import { BenefitService, BlogService } from '../../services';
import { dataLayerTrack } from '../../utils/GTM_helper';
import { HOME } from '../../utils/category';
import AdvertisementsList from './AdvertisementsList/AdvertisementsList';
import BenefitsList from './BenefitsList/BenefitsList';
import BlogsList from './BlogsList/BlogsList';
import styles from './Home.module.scss';
import * as translations from './intl';

const loadData = async (setBenefits, setBlogs, idiomAPI) => {
  const blogs = BlogService.getBlogList(idiomAPI, HOME).catch((error) => {
    throw new Error({ error, data: [] });
  });

  const benefits = BenefitService.getBenefitsToHome(idiomAPI).catch((error) => {
    throw new Error({ error, data: [] });
  });

  setBenefits(await benefits);
  setBlogs(await blogs);
};

function Home() {
  const [benefits, setBenefits] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [adverts] = useAllPrismicDocumentsByType('advertisement', {
    lang: 'es-km',
  });

  /* eslint-disable */
  const advertisements = useMemo(
    () =>
      adverts
        ? adverts.map(({ data }) => ({
            ...data,
            short_content: data.content,
            icon_url: data.icon_url.url,
          }))
        : [],
    [adverts],
  );
  /* eslint-enable */

  const { idiomAPI, country, idiom } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const location = useLocation();

  useEffect(() => {
    loadData(setBenefits, setBlogs, idiomAPI);

    dataLayerTrack(intl, idiom, country, location);
  }, []);

  return (
    <div className={`${styles.home}`}>
      <Helmet>
        <title>{intl.PAGE_TITLE_TAG}</title>
      </Helmet>
      <Banner form datatestid="bannerHome" category={HOME} />
      <div className="row">
        <div className="col-sx-1 text-center col-12 p-4">
          <h5 style={{ fontSize: '1.06rem' }}>{intl.TITLE_BELOWBANNER}</h5>
        </div>
      </div>
      <div className={styles.content}>
        <BenefitsList title={intl.TITLE_BENEFITS} benefits={benefits} />
        <AdvertisementsList
          title={intl.TITLE_ADVERTISEMENTS}
          advertisements={advertisements}
        />
        <BlogsList
          title={intl.TITLE_BLOGS}
          subtitle={intl.SUBTITLE_BLOGS}
          blogs={blogs}
        />
      </div>
    </div>
  );
}

export default Home;
