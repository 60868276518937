import React, { useContext } from 'react';
import styles from './Steps.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../intl/index';

function Steps(props) {
  const { step: currentStep } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const checkStep = (step) => {
    if (step === currentStep) {
      return styles.stepSelected;
    }
    if (step < currentStep) {
      return styles.stepDone;
    }
    return undefined;
  };

  const checkLabel = (step) => (step === currentStep ? styles.currentLabel : undefined);

  return (
    <div className={styles.wrapSteps}>
      <div className={styles.step}>
        <span className={`${styles.label} ${checkLabel(1)}`}>
          {intl.LABEL_TRIP_1}
        </span>
        <div className={`${styles.stepDot} ${checkStep(1)}`} />
      </div>
      <div className={styles.bar} />
      <div className={styles.step}>
        <span className={`${styles.label} ${checkLabel(2)}`}>
          {intl.LABEL_PLAN_2}
        </span>
        <div className={`${styles.stepDot} ${checkStep(2)}`} />
      </div>
      <div className={styles.bar} />
      <div className={styles.step}>
        <span className={`${styles.label} ${checkLabel(3)}`}>
          {intl.LABEL_TRAVELERS_3}
        </span>
        <div className={`${styles.stepDot} ${checkStep(3)}`} />
      </div>
    </div>
  );
}

export default Steps;
