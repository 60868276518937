import moment from 'moment';
import { langKm, langKc, langMx } from '../../../../location/urls';
import { getCurrencyByFormType } from '../../../../utils/currencyISOCode';
import { TotalPremiumAsc } from '../../../../utils/sort';

const formatQuoteEs = props => {
  const { Cotizar } = props.values;
  const { Quote } = Cotizar;
  const { formType } = props;

  const TripInitialDate = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
  const TripEndDate = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  const AmountCurrencyISOCode = getCurrencyByFormType(formType);
  const {
    DestinationCountryIsoCode,
    TypeOfPlan,
    TripType,
    Email,
    PhoneNumber,
  } = Quote;
  const Sort = TotalPremiumAsc;
  const Age = Quote.Ages.map((item, index) => item.value);
  const { recaptcha } = Quote;
  const PromotionalCode = Quote.PromotionalCode ? Quote.PromotionalCode : null;

  const IncludesEurope = 'checar com o Hiro';

  const quote = {
    Quote: {
      TripInitialDate,
      TripEndDate,
      Age,
      Sort,
      DestinationCountryIsoCode,
      AmountCurrencyISOCode,
      IncludesEurope: null,
      Top: 100,
      PromotionalCode,
    },
    ExpirationName: TripType,
    IsFamily: TypeOfPlan === 'Familiar' ? 'true' : 'false',
    Status: 0,
    Action: process.env.REACT_APP_CREATE_COTIZAR_ACTION,
    recaptcha,
    Email,
    PhoneNumber,
    ProductDescription: '',
  };

  return quote;
};

export const formatStep3QuoteEs = (Quote, formType) => {
  const TripInitialDate = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
  const TripEndDate = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  const AmountCurrencyISOCode = getCurrencyByFormType(formType);
  const {
    DestinationCountryIsoCode,
    TypeOfPlan,
    TripType,
    Email,
    PhoneNumber,
    ProductDescription,
  } = Quote;
  const Sort = TotalPremiumAsc;
  const Age = Quote.Ages.map((item, index) => item.value);
  const { recaptcha } = Quote;
  const PromotionalCode = Quote.PromotionalCode ? Quote.PromotionalCode : null;

  const quote = {
    Quote: {
      TripInitialDate,
      TripEndDate,
      Age,
      Sort,
      DestinationCountryIsoCode,
      AmountCurrencyISOCode,
      IncludesEurope: null,
      Top: 100,
      PromotionalCode,
    },
    ExpirationName: TripType,
    IsFamily: TypeOfPlan === 'Familiar' ? 'true' : 'false',
    Status: 0,
    Action: process.env.REACT_APP_CREATE_COTIZAR_ACTION,
    recaptcha,
    Email,
    PhoneNumber,
    ProductDescription,
  };
  return quote;
};

export const formatQuoteToApi = props => {
  const { formType } = props;
  if (formType === langKm || formType === langMx || formType === langKc) {
    return formatQuoteEs(props);
  }
  return null;
};
