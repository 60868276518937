import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import styles from './ButtonLink.module.scss';

function ButtonLink({
  blue,
  css,
  dataTestId,
  external,
  fontAwesome,
  icon,
  link,
  size = 12,
  value,
  weight,
}) {
  let className = `${styles.btn} ${css}`;

  if (blue) {
    className += ` ${styles.btnBlue} `;
  }

  const style = {
    fontSize: size,
    fontFamily: 'Source Sans Pro',
    fontWeight: weight ? 600 : 0,
  };

  const button = () => {
    if (icon) {
      return (
        <button className={className} style={style} type="button">
          <img src={icon} className={styles.icon} alt="" />
          {' '}
          {value}
        </button>
      );
    }

    if (fontAwesome) {
      return (
        <button className={className} style={style} type="button">
          {value}
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            color="#010090"
            style={{ marginLeft: '10%' }}
          />
        </button>
      );
    }

    return (
      <button className={className} style={style} type="button">
        {value}
      </button>
    );
  };

  if (external) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {button()}
      </a>
    );
  }
  return (
    <Link data-testid={dataTestId} to={link}>
      {button()}
    </Link>
  );
}

export default ButtonLink;
