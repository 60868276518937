import React, { memo, useContext, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PaymentForm.module.scss';
import * as translations from './intl';
import { LocationContext } from '../../../../../components/Location/Location';
import { IntlContext } from '../../../../../intl';
import Input from '../../../../../components/Input/Input';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import paypal from '../../../../../assets/img/paypal.png';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { CARD, PAYPAL } from '../../constants';
import PaymentCard from './PaymentCard/PaymentCard';
import PaymentFactura from './PaymentFactura/PaymentFactura';

import tarjetaVisaMaster from '../../../../../assets/img/cards/tarjetas-Visa-Master.png';
import Button from '../../../../../components/Button/Button';
import PayPalPaymentButtonV1 from '../../../../../components/PayPalPaymentButtonV1/PayPalPaymentButtonV1';

const cardsByIdiom = {
  es_kc: tarjetaVisaMaster,
  es_km: tarjetaVisaMaster,
  es_mx: tarjetaVisaMaster,
};

function PaymentForm(props) {
  const {
    values,
    setFieldValue,
    recibirEmail,
    aceptLosTermos,
    step3,
    submit,
    stateForm,
    formSubmitRefs,
    setLoading,
    schemas,
    updateParent,
    amountFormatedWithBenefits,
    formatNumber,
    finalPrice,
  } = props;

  const { idiom } = useContext(LocationContext);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const intl = translate(translations);
  const isKM = getCountryPrefix() === 'km';
  const isMX = getCountryPrefix() === 'mx';
  const isKC = getCountryPrefix() === 'kc';

  const [showFactura, setShowFactura] = useState(
    isMX || isKM ? values.payment.isFatura : false,
  );
  const [notShowDataAddress, setShowDataAddress] = useState(false);

  const paymentTarjeta = values.payment.tarjeta === 'card';
  const paymentPaypal = values.payment.tarjeta === 'paypal';

  const changePayment = method => {
    setFieldValue('payment.tarjeta', method);
  };

  return (
    <>
      <div className="row">
        <div className={`col-7 ${styles.marginBottom2}`}>
          <label className={styles.labelTitleForm}>{intl.PAGO}</label>
        </div>
      </div>
      <div className={styles.PaymentArea}>
        <div className="col-12">
          <div className="row">
            <div
              className={`col-11 col-md-6 ${styles.tarjetas} ${paymentTarjeta
                && styles.paymentCheck}`}
              onClick={() => changePayment(CARD)}
            >
              <img src={cardsByIdiom[idiom]} alt="tarjeta" />
            </div>
          </div>
        </div>

        {values.payment.tarjeta === CARD && (
          <PaymentCard getCountryPrefix={getCountryPrefix} {...props} />
        )}

        {values.payment.tarjeta === PAYPAL && (
          <div className="col-12">
            <p className={styles.textPaypal}>{intl.TEXT_PAYPAL_TERMS}</p>
          </div>
        )}

        {(isMX || isKM) && <PaymentFactura {...props} intl={intl} />}
        <div className={`row ${styles.termsArea}`}>
          <div className={styles.checkArea}>
            <div className={styles.checkbox}>
              <CheckBox
                type="checkbox"
                value={recibirEmail.recibirNoticias}
                onClick={() => recibirEmail.setRecibirNoticias(!recibirEmail.recibirNoticias)}
              />
            </div>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: intl.CONTENT_CHECK1 }}
            />
          </div>
          <div className={styles.checkArea}>
            <div className={styles.checkbox}>
              <CheckBox
                type="checkbox"
                value={aceptLosTermos.aceptoLosTermos}
                onClick={() => aceptLosTermos.setAceptoLosTermos(
                  !aceptLosTermos.aceptoLosTermos,
                )}
              />
            </div>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: intl.CONTENT_CHECK2(step3.data.pdf_url),
              }}
            />
          </div>
          {aceptLosTermos.aceptoLosTermos === false && (
            <span className={styles.checkBoxError}>
              {intl.VALIDATE_CHECKBOX}
            </span>
          )}
        </div>

        <div className={styles.totalDetail}>
          <p className={styles.total}>
            <span className={styles.title}>{intl.TOTAL_APLICADO}</span>
          </p>
          <p className={`${styles.valueFinal} ${styles.valueRight}`}>
            $
            {amountFormatedWithBenefits}
            <span className={` ${styles.value} ${styles.currency}`}>
              {step3.data.currency}
            </span>
          </p>
        </div>

        <div className={styles.footerBtn}>
          <div
            className={`${isKC && styles.btnGreater}`}
            style={{
              display:
                values.payment.tarjeta === PAYPAL
                && stateForm.payment.paypalPaymentId === null
                  ? 'none'
                  : '',
            }}
          >
            <Button
              blue
              type={'submit'}
              onClick={submit}
              value={(
                <>
                  <span>{intl.COMPLETAR_PAGO(amountFormatedWithBenefits)}</span>
                  {isKC && (
                    <FontAwesomeIcon
                      icon="arrow-right"
                      className={styles.icon}
                    />
                  )}
                </>
              )}
              formSubmitRefs={formSubmitRefs}
            />
          </div>
          <div className={styles.btnPaypal}>
            {values.payment.tarjeta === PAYPAL
              && stateForm.payment.paypalPaymentId === null && (
                <PayPalPaymentButtonV1
                  data={step3.data}
                  setLoading={setLoading}
                  updateParent={updateParent}
                  formSubmitRefs={formSubmitRefs}
                  stateForm={stateForm}
                  schemas={schemas}
                  idiom={idiom}
                  aceptLosTermos={aceptLosTermos}
                />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(PaymentForm);
