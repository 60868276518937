export const TRIP_NAME_SINGLE = {
  es_kc: 'Single trip',
  es_mx: 'Single trip',
  es_km: 'Single trip',
};

export const TRIP_NAME_MULTI = {
  es_kc: 'Multitrip annual',
  es_mx: 'Multitrip annual',
  es_km: 'Multitrip annual',
};

export const TRIP_VALUE_SINGLE = {
  es_kc: 'SingleTrip',
  es_mx: 'SingleTrip',
  es_km: 'SingleTrip',
};

export const TRIP_VALUE_MULTI = {
  es_kc: 'Annual',
  es_mx: 'Annual',
  es_km: 'Annual',
};
