import { departamentos } from '../../../../../../intl/es_co_departamentos';

export const LABEL_NOMBRE = {
  es_kc: 'Nombre *',
  es_km: 'Nombre *',
  es_mx: 'Nombre *',
};

export const LABEL_APELIDO = {
  es_kc: 'Apellidos *',
  es_km: 'Apellidos *',
  es_mx: 'Apellidos *',
};

export const LABEL_EMAIL = {
  es_kc: 'Correo electrónico *',
  es_km: 'Correo electrónico *',
  es_mx: 'Correo electrónico *',
};

export const LABEL_EMAIL_CONFIRM = {
  es_kc: 'Confirmación correo electrónico *',
  es_km: 'Confirmación de correo electrónico *',
  es_mx: 'Confirmación de correo electrónico *',
};

export const LABEL_MOBILE = {
  es_kc: 'Celular *',
  es_km: 'Celular *',
  es_mx: 'Celular *',
};

export const LABEL_IDENTIFICATION = {
  es_kc: 'No. Identificación *',
  es_km: 'No. Identificación *',
  es_mx: 'No. Identificación *',
};

export const VIAJERO_COUNT = {
  es_kc: (count = '') => `VIAJERO ${count}`,
  es_km: (count = '') => `Viajero ${count}`,
  es_mx: (count = '') => `Viajero ${count}`,
};

export const FECHA_NACIMIENTO = {
  es_kc: 'Fecha de nacimiento *',
  es_km: 'Fecha de nacimiento *',
  es_mx: 'Fecha de nacimiento *',
};

export const LABEL_ADDRESS = {
  es_kc: 'Dirección *',
  es_km: 'Dirección *',
  es_mx: 'Dirección *',
};

export const LABEL_CITY = {
  es_kc: 'Ciudad *',
  es_km: 'Ciudad *',
  es_mx: 'Ciudad *',
};

export const LABEL_ZIP_CODE = {
  es_kc: 'Código postal *',
  es_km: 'Código postal *',
  es_mx: 'Código postal *',
};

export const LABEL_STATE_OF_RESIDENCE = {
  es_kc: 'Departamento de residencia *',
  es_km: 'Estado de residencia *',
  es_mx: 'Estado de residencia *',
};

export const OPTIONS_DEPARTAMENTOS = {
  es_kc: departamentos,
  es_km: '',
  es_mx: '',
};

export const LABEL_BIRTH_DATE = {
  es_kc: 'Fecha de nacimiento *',
  es_km: 'Fecha de nacimiento *',
  es_mx: 'Fecha de nacimiento *',
};

export const LABEL_AGE = {
  es_kc: 'Años *',
  es_km: 'Años *',
  es_mx: 'Años *',
};
