import { langKm, langKc, langMx } from '../location/urls';

export const getClientId = idiom => {
  let clientId = '';

  switch (idiom) {
    case langKm:
      clientId = process.env.REACT_APP_PAYPAL_MX_CLIENT_ID;
      break;
    case langKc:
      clientId = process.env.REACT_APP_PAYPAL_CO_CLIENT_ID;
      break;
    case langMx:
      clientId = process.env.REACT_APP_PAYPAL_MX_CLIENT_ID;
      break;
    default:
      break;
  }

  return clientId;
};

export const getSecretId = idiom => {
  let clientId = '';

  switch (idiom) {
    case langKm:
      clientId = process.env.REACT_APP_PAYPAL_MX_CLIENT_SECRET;
      break;
    case langKc:
      clientId = process.env.REACT_APP_PAYPAL_CO_CLIENT_SECRET;
      break;
    case langMx:
      clientId = process.env.REACT_APP_PAYPAL_MX_CLIENT_SECRET;
      break;
    default:
      break;
  }

  return clientId;
};
