import React from 'react';
import styles from './AsistenciaContent.module.scss';
import siniestro from '../../../assets/img/asistencia/Atencion_al_cliente_desk_person.png';
import phone from '../../../assets/img/asistencia/Como_solicitar_una asistencia_phone.png';
import clerk from '../../../assets/img/asistencia/Como_notificar_un_siniestro_Laptop_right.png';
import page from '../../../assets/img/asistencia/Como facturar_Notepad_right.png';

function AsistenciaMx(props) {
  return (
    <div className={styles.asistentContent} data-testid="asistenciaContentMx">
      <div className={styles.list}>
        <div className={`col-md-3 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <div className={`${styles.rounded}`}>
              <img src={phone} alt="asistencia_" />
            </div>
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>¿Cómo solicitar una asistencia?</h4>
            <p className={styles.content}>
              <strong>1. Llame</strong>
              {' '}
              a nuestras oficinas al (55) 4744-4723.
              <br />
              <strong>2. Elige</strong>
              {' '}
              la opción 1 si se trata de una asistencia médica.
              En el siguiente menú, elige la opción 1 si estas en el
              territorio de los Estados Unidos Mexicanos.
              Si estas fuera del territorio mexicano, elige la opción
              2, no olvides añadir la LADA +52.
              <br />
              <br />
              Si requieres otro servicio que no es emergencia médica, elige la opción 3.
              <br />
              <br />
              <strong>Nuestro horario de atención es de lunes a domingo, las 24 horas.</strong>
            </p>
          </div>
        </div>

        <div className={`col-md-3 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <div className={`${styles.rounded}`}>
              <img src={clerk} alt="asistencia_" />
            </div>
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>¿Cómo notificar un Siniestro?</h4>
            <p className={styles.content}>
              <strong>1. Llame</strong>
              {' '}
              al (55) 4744-4723.
              <br />
              <strong>2. Elige</strong>
              {' '}
              la opción 2 si el caso ya está abierto.
              En el caso contrario, elige la opción 3 para notificar un siniestro relacionado con:
              <br />
              {' '}
              <br />
              a. Cancelación y/o interrupción de viaje
              <br />
              b. Asistencia por Pérdida o Retraso de Equipaje
              <br />
              c. Asistencia por Cancelación o Retraso de vuelo
              <br />
              d. Asistencia por Pérdida o Retraso de Equipaje Deportivo
              <br />
              <br />
              Nuestro horario de atención es de lunes a domingo, las 24 horas.
            </p>
          </div>
        </div>

        <div className={`col-md-3 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <div className={`${styles.rounded}`}>
              <img src={page} alt="asistencia_" />
            </div>
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>¿Cómo facturar?</h4>
            <p className={styles.content}>
              En caso de requerir facturas, es importante que nos envíes los siguientes datos,
              al correo:<br />
              <a href="mailto:facturaasistencia@axa-assistance.com.mx:">
                facturaasistencia@axa-assistance.com.mx:
              </a>:
              <br />
              <br />
              3. Constancia de situación fiscal
              <br />
              4. Número de certificado
              <br />
              5. Correo Electrónico
              <br />
              <br />
              Recuerda que el tiempo límite para procesar tu solicitud de facturación es de
              <strong> 30 días</strong>
            </p>
          </div>
        </div>

        <div className={`col-md-3 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <div className={`${styles.rounded}`}>
              <img src={siniestro} alt="asistencia_" />
            </div>
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>Atención al Cliente</h4>
            <p className={styles.content}>
              ¿Tienes alguna duda sobre SAFETRIP?
              {' '}
              Por favor, comunícate al <strong>(55) 4744-4723</strong> y elige la opción 4.
              <br />
              <br />
              Horario de atención, de lunes a domingo las 24 horas
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AsistenciaMx;
