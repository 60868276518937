import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Promociones.module.scss';
import mxContent from './Promociones_Text';
import { LocationContext } from '../../components/Location/Location';
import { dataLayerTrack } from '../../utils/GTM_helper';

function Promociones() {
  const { country, idiom } = useContext(LocationContext);
  const location = useLocation();

  useEffect(() => {
    dataLayerTrack(
      {},
      idiom,
      country,
      location,
      'Estas son nuestras promociones especiales | AXA Assistance',
    );
  }, []);

  const mappedTexts = useMemo(
    () => mxContent.map(value => (
      <div key={value.id} className={`row ${styles.item}`}>
        <div className="col-1" />
        <div className={`col-6 ${styles.text}`}>
          <h2>{value.title}</h2>
          {value.content}
        </div>
        <div className={`col-5 ${styles.rounded}`}>
          <img className="rounded-circle" src={value.image} alt="" />
        </div>
      </div>
    )),
    [mxContent],
  );

  return (
    <div className={styles.promociones} data-testid="pagePromociones">
      <div className="container">
        <div className="row">
          <div className={`col-12 ${styles.header}`}>
            <h1>Promociones</h1>
            <p>Estas son nuestras promociones especiales:</p>
          </div>
        </div>
        {mappedTexts}
      </div>
    </div>
  );
}

export default Promociones;
