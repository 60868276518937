import { focusOnErrorElement } from '../../../utils/elements';

const IGNORE_CAPTCHA = process.env.REACT_APP_IGNORE_CAPTCHA === 'on';

export const checkErrorsOnForm = async (
  submitRefs,
  schemas,
  values,
  recaptchaRef,
) => {
  // if (!IGNORE_CAPTCHA) recaptchaRef.Cotizar.current.execute();
  submitRefs.Cotizar.current.click();

  try {
    await schemas.Cotizar.validate(values.Cotizar);
    // if (!IGNORE_CAPTCHA && !values.Cotizar.recaptcha.recaptcha) {
    //   return false;
    // }
    return true;
  } catch (error) {
    focusOnErrorElement(0);
    return false;
  }
};
