import React, { useContext } from 'react';
import Modal from '../../Modal/Modal';
import styles from './ModalMultitrip.module.scss';
import Button from '../../Button/Button';
import { IntlContext } from '../../../intl/index';
import * as translations from './intl';

function ModalMultitrip(props) {
  const { choiceMultitrip, choiceConfirmPlan } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  return (
    <Modal attention width96 background active>
      <div className={styles.modalMultitrip}>
        <div className={styles.header} />
        <div className={styles.content}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: intl.TEXT_DESCRIPTION }}
          />
          <div className={styles.footer}>
            <Button
              orange
              value={intl.TEXT_BUTTON_MULTITRIP}
              onClick={choiceMultitrip}
            />
            <Button
              orange
              value={intl.TEXT_BUTTON_NEXT}
              onClick={choiceConfirmPlan}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMultitrip;
