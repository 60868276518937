import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FormBarStep1.module.scss';

import { IntlContext } from '../../intl';
import * as translations from './intl';

import AsistenciaInformation from './AsistenciaInformation/AsistenciaInformation';
import { submitACotizar } from '../CotizarFormStep1/submit';
import { checkErrorsOnForm } from '../CotizarFormStep1/checkErrorsOnForm';
import { getRefs } from '../CotizarFormStep1/getRefs';
import { LocationContext } from '../Location/Location';
import Button from '../Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import { dataLayerTrack } from '../../utils/GTM_helper';

const showFormBarStep1 = (setShowFormCotizar, showFormCotizar) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setShowFormCotizar(!showFormCotizar);
};

function FormBarStep1({ history, setLoading }) {
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const intl = translate(translations);
  const [schemas] = useState({});
  const [values] = useState({});
  const { actions } = useContext(StateContext);
  const submitRefs = getRefs(useRef);
  const accordeonRefs = getRefs(useRef);
  const recaptchaRef = getRefs(useRef);
  const formSubmitRefs = getRefs(useRef);
  const [notFound, setNotFound] = useState(false);

  const [showFormCotizar, setShowFormCotizar] = useState(false);
  const windowWidth = window.matchMedia('(min-width: 1100px)');

  const verifyWindowWidth = e => {
    if (e.matches) {
      setShowFormCotizar(true);
      return;
    }
    setShowFormCotizar(false);
  };

  useEffect(() => {
    verifyWindowWidth(windowWidth);
    windowWidth.addListener(verifyWindowWidth);
    return () => {
      windowWidth.removeListener(verifyWindowWidth);
    };
  }, []);
  const step1GTM = {
    event: 'enhanced-ecommerce',
    gaEventCategory: 'enhanced ecommerce',
    gaEventAction: 'checkout step',
    gaEventLabel: '1',
    gaEventValue: undefined,
    gaEventNonInteraction: true,
    ecommerce: { checkout: { actionField: { step: 1, action: undefined } } },
  };

  const tagManagerArgsStep1 = {
    dataLayer: { ...step1GTM },
    dataLayerName: 'PageDataLayer',
  };

  const styleFormContent = styles.formContent;

  const submit = async () => {
    const areFormsOk = await checkErrorsOnForm(
      submitRefs,
      accordeonRefs,
      schemas,
      values,
      recaptchaRef,
    );
    // areFormsOk = true;
    if (areFormsOk) {
      const cotizarProps = {
        formType: idiom,
        values,
        setSubmitting: setLoading,
        intl,
        idiom,
        history,
        idiomForApi: idiomAPI,
        actions,
        setNotFound,
      };

      TagManager.dataLayer(tagManagerArgsStep1);

      const submitResult = await submitACotizar(cotizarProps);

      if (!submitResult) {
        setNotFound(true);
      } else {
        actions.step.setStep(2);
        history.push(intl.LINK_FORM_COTIZAR);
      }
    }
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  return (
    <>
      <div
        style={{ display: showFormCotizar ? 'block' : 'none' }}
        className={styles.cotizarFillForm}
      >
        <div className={`${styleFormContent}`}>
          {notFound && (
            <div className={`row ${styles.errorArea}`}>
              <div className="col-12">
                <label className={styles.errorLabel}>{intl.ERROR_LABEL}</label>
              </div>
            </div>
          )}
          <div className="row pl-2">
            <AsistenciaInformation
              formType={idiom}
              parentValues={values}
              updateParent={updateForm.bind(null, 'Cotizar')}
              incidentSubmitRef={submitRefs.Cotizar}
              recaptchaRef={recaptchaRef.Cotizar}
              formSubmitRefs={formSubmitRefs.Cotizar}
              hideTravelAgencyId
              submitOnChangeStateResidence
              submit={submit}
            />
            <div className={`col-12 col-xl-2 pl-5 ${styles.footerBtn}`}>
              <Button
                dataTestid="btnSubmitForm1"
                step1Banner
                onClick={submit}
                value={<span>{intl.BTN_CONTINUE}</span>}
                formSubmitRefs={formSubmitRefs.Cotizar}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="backToTop" className={styles.buttonBackToTop}>
        <Button
          dataTestid="btnSubmitForm1"
          homeBanner
          onClick={() => showFormBarStep1(setShowFormCotizar, showFormCotizar)}
          value={(
            <span>
              {showFormCotizar ? intl.CLOSE : ''}
              {' '}
              {intl.BTN_CONTINUE}
            </span>
          )}
        />
      </div>
    </>
  );
}

export default FormBarStep1;
