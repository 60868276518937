/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import moment from 'moment';
import styles from './Calendar.module.scss';
import './Calendar.scss';
import { applyDateMask } from '../../utils/stringUtils';

import {
  langKm, langKc, langMx,
} from '../../location/urls';

import iconCalendar from '../../assets/img/icon-calendar.png';
import { safeGet } from '../../utils/object';
import { LocationContext } from '../Location/Location';

registerLocale(langKm, es);
registerLocale(langKc, es);
registerLocale(langMx, es);

const onChangeRaw = (event, onChange, extraAction, name) => {
  const customEvent = event;
  const { target } = event;
  target.value = applyDateMask(target.value);
  if (target.value.length > 10) {
    target.value = target.value.substring(0, 10);
  }
  if (target.value.length === 10) {
    const date = new Date(target.value);
    if (date !== 'Invalid Date') {
      onChange(event);
      if (extraAction) {
        const e = {
          target: { name, value: customEvent.target.value },
        };
        extraAction(e);
      }
    } else onChange('');
  }
};

function Calendar({
  value,
  onChange,
  label,
  error,
  touched,
  name,
  maxDate,
  minDate,
  touchedParent,
  touchedParentIndex,
  setFieldTouched,
  notShowIcon,
  formikProps,
  setFieldValue,
  onBlur,
  errorNoMessage,
  extraAction,
  ...props
}) {
  if (formikProps) {
    onChange = formikProps.handleChange;
    setFieldTouched = formikProps.setFieldTouched;
    error = safeGet(formikProps, `errors.${name}`);
    touched = safeGet(formikProps, `touched.${name}`);
    value = safeGet(formikProps, `values.${name}`);
  }
  const { idiom } = useContext(LocationContext);
  const dateFormat = 'dd/MM/yyyy';
  const showIcon = !notShowIcon;
  const _onChangeRaw = (e) => {
    setFieldTouched && setFieldTouched(name, true);
    onChangeRaw(e, onChange, extraAction, name);
  };
  const _onSelect = (data, e) => {
    const event = { target: { name, value: data } };
    setFieldTouched && setFieldTouched(name, true);
    onChange(event);

    const { target } = e;
    const htmlContent = target.innerHTML;

    if (htmlContent.length === 2 && extraAction) {
      extraAction(event);
    }
  };
  const openCalendar = (event) => {
    if (event && event.target && event.target.parentNode) {
      const parentCalendar = event.target.parentNode;
      parentCalendar.querySelector('input').click();
    }
  };

  let className = styles.Calendar;
  if (errorNoMessage && error && touched) className += ` ${styles.error}`;
  const formikError = error && '1';
  let dateValue;
  try {
    const momentValue = moment.utc(value.toUTCString());
    const fomatedValue = momentValue.format('MM/DD/YYYY');
    dateValue = new Date(fomatedValue);
  } catch (e) {
    dateValue = value;
  }

  useEffect(() => {
    const element = document.querySelector(`input[calendar="${name}"]`);
    element.addEventListener('blur', (e) => {
      setFieldTouched(e.target.getAttribute('calendar'), true);
    });
  }, []);
  return (
    <div className={className}>
      <DatePicker
        disabled={props.disabled}
        utcOffset={0}
        customInput={(
          <input
            formik-error={formikError}
            onFocus={() => alert('a')}
            calendar-formik="true"
            autoComplete="new-password"
            calendar={name}
            data-testid={props.dataTestid}
          />
        )}
        onChangeRaw={_onChangeRaw}
        placeholderText={label}
        selected={dateValue}
        dateFormat={dateFormat}
        locale={idiom}
        onSelect={_onSelect}
        maxDate={maxDate}
        minDate={minDate}
      />
      {showIcon && (
        <img
          src={iconCalendar}
          className={styles.icon}
          alt="_"
          onClick={openCalendar}
        />
      )}
      {error && touched && !errorNoMessage && (
        <label className={styles.errorLabel}>{error}</label>
      )}
    </div>
  );
}

export default Calendar;
