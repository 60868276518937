import moment from 'moment';
import {
  EMPTY_STRING,
  RFC,
  MPH_DEFAULT_NATIONALITY_COLOMBIA,
  MALE,
  PASSENGER_DEFAULT_EMERGENCY_CONTACT_TELEPHONE,
  CAMPO_STATIC,
  DNI,
  MPH_DEFAULT_NATIONALITY_ARGENTINA,
  MPH_DEFAULT_NATIONALITY_MEXICANA,
  DEFAULT_COUNTRY_MEXICO,
} from '../constantsSubmit';
import { langKm, langMx } from '../../../../../location/urls';
import { formatDateOfBirthByAge } from '../../../../../utils/date';

export const formatPassengerToAPI = (props, Quote) => {
  const { formType } = props;

  const { Cotizar } = props.values;

  const { viajeiros, payment } = Cotizar;

  const viajeirosList = [];
  const firstViajeiro = viajeiros[0];

  viajeiros.forEach((element, index) => {
    const viajeiro = {};

    viajeiro.EmergencyContactName = EMPTY_STRING;
    viajeiro.EmergencyContactTelephone = PASSENGER_DEFAULT_EMERGENCY_CONTACT_TELEPHONE;

    if (index === 0) {
      viajeiro.IsMPH = true;
    } else {
      viajeiro.IsMPH = false;
    }

    viajeiro.LastName = element.apelido;
    viajeiro.MotherLastName = EMPTY_STRING;
    viajeiro.Name = element.nombre;
    viajeiro.MiddleName = EMPTY_STRING;
    viajeiro.FiscalIdentityCode = EMPTY_STRING;
    viajeiro.IdentificationType = RFC;
    viajeiro.Identification = element.numer_identification;
    viajeiro.Nationality = MPH_DEFAULT_NATIONALITY_COLOMBIA;

    viajeiro.DateOfBirth = moment(element.date_of_birth).format('YYYY-MM-DD');

    if (formType === langMx || formType === langKm) {
      viajeiro.Nationality = MPH_DEFAULT_NATIONALITY_MEXICANA;
    }

    viajeiro.Profession = EMPTY_STRING;
    viajeiro.Gender = MALE;
    viajeiro.Telephone = element.mobile;
    viajeiro.Mobile = element.mobile;
    viajeiro.Kinship = EMPTY_STRING;
    viajeiro.PoliticallyExposed = false;
    viajeiro.ExercisedRelevantPublicOffice = EMPTY_STRING;

    viajeiro.Address = addressSchema(element, payment, formType, firstViajeiro);

    viajeirosList.push(viajeiro);
  });

  return viajeirosList;
};

const addressSchema = (viajeiro, payment, formType, firstViajeiroAddress) => {
  const address = {};
  address.Street = viajeiro.address || firstViajeiroAddress.address;
  address.Number = EMPTY_STRING;
  address.Complement = EMPTY_STRING;
  address.Neighborhood = EMPTY_STRING;
  address.City = viajeiro.city || firstViajeiroAddress.city;
  address.State = viajeiro.state_of_residence || firstViajeiroAddress.state_of_residence;
  address.Country = MPH_DEFAULT_NATIONALITY_COLOMBIA;

  if (formType === langMx || formType === langKm) {
    address.Country = DEFAULT_COUNTRY_MEXICO;
  }

  address.ZipCode = viajeiro.zip_code || payment.postalCode;
  address.InteriorNumber = CAMPO_STATIC;

  return { ...address };
};
