import React, {
  useState, useContext, useEffect, useMemo,
} from 'react';
import { withRouter } from 'react-router';

import { usePrismicDocumentByUID } from '@prismicio/react';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle';
import { LocationContext } from '../../../components/Location/Location';
import styles from './PlanesDetail.module.scss';
import { PLANES_DETAIL } from '../../../utils/category';
import Banner from '../../../components/Banner/Banner';
import { dataLayerTrack } from '../../../utils/GTM_helper';

function PlanesDetail({
  history, location, match: { params }, plane_uid,
}) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { idiom, country } = useContext(LocationContext);
  const [doc, { state }] = usePrismicDocumentByUID('planes', params.code_seo, { lang: 'es-km', fetchLinks: 'tab.body' });

  const benefitData = useMemo(() => {
    if (state !== 'loaded' && !doc) return <p>Loading...</p>;

    const extractCellData = (item) => Object.values(item)
      .map(([data]) => data.text);

    const [benefits] = doc.data.benefits;
    const [cols, rows] = benefits.benefituid.data.body;

    const [columnNames] = cols.items.map(extractCellData);
    const rowsData = rows.items.map(extractCellData);

    return {
      ...doc.data,
      benefits: {
        cols: columnNames,
        rows: rowsData,
      },
    };
  }, [doc, state]);

  const getContent = content => (content ? (
    <div className={`col-12 col-md-6 ${styles.text}`}>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  ) : (
    ''
  ));

  useEffect(() => {
    if (params.code_seo !== null) {
      dataLayerTrack(intl, idiom, country, location, params.code_seo);
    }
  }, []);

  if (state !== 'loaded') {
    return (
      <h1 className={styles.loading}>
        <LoadingCircle />
      </h1>
    );
  }

  return (
    <div className={`${styles.planesDetail}`} data-testid="pageDetailPlanes">
      <Banner datatestid="bannerPlanesDetail" category={PLANES_DETAIL} />
      <div className={styles.content}>
        <div className={`${styles.description} row`}>
          <div
            className={` col-12 col-md-6 ${styles.title} ${benefitData.content ? '' : styles.fullTitle}`}
          >
            <h1>{benefitData.title}</h1>
            <h2>{benefitData.subtitle}</h2>
          </div>
          {getContent(benefitData.content)}
        </div>

        <table className="table table-striped table-hover table-bordered rounded w-75 position-relative mx-auto overflow-hidden">
          <thead>
            <tr key="planes-table-headed" className={'text-white'} style={{'background-color': '#ff690f'}}>
              {benefitData.benefits.cols.map((col, i) => <th key={`table-heading-${i}`} scope="col">{col}</th>)}
            </tr>
          </thead>
          <tbody>
            {benefitData.benefits.rows.map((row, i) => (
              <tr key={`table-row-${i}`}>
                {row.map((cellValue, idx) => <td key={`table-cell-${i}-${idx}`}>{cellValue}</td>)}
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.compare}>
          <a href={benefitData.pdf_url} rel="noopener noreferrer" target="_BLANK">
            {intl.TEXT_COMPARE}
          </a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PlanesDetail);
