import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './EsInformation.module.scss';
import { formInitialValues } from './initialValues';
import { validationMissedSchema } from './validationSchema';
import PeriodArea from './PeriodArea/PeriodArea';
import TripArea from './TripArea/TripArea';
import PersonArea from './PersonArea/PersonArea';
import TripType from './TripType/TripType';
import { LocationContext } from '../../../Location/Location';
import { StateContext } from '../../../StateContextParent/StateContextParent';
import InvisibleRecaptcha from '../../../InvisibleRecaptcha/InvisibleRecaptcha';

const IGNORE_CAPTCHA = process.env.REACT_APP_IGNORE_CAPTCHA === 'on';

function EsInformation(props) {
  const { idiom } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    updateParent,
    incidentSubmitRef,
    banner,
    recaptchaRef,
    formSubmitRefs,
  } = props;

  const { utils } = useContext(StateContext);
  const form1 = utils.getFormDataStep1();

  const validationSchema = useMemo(() => validationMissedSchema(intl), [intl]);
  const initialValues = useMemo(() => formInitialValues(idiom, form1), [
    idiom,
    form1,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const {
          values, handleSubmit, setFieldValue, setSubmitting,
        } = formikProps;
        updateParent(values, validationSchema);

        return (
          <form
            className={styles.formBarStep1}
            onSubmit={handleSubmit}
            data-testid="FullFormCliamCarRental"
          >
            <button
              ref={incidentSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            />
            <div className="col-12 col-xl-3">
              <TripType {...formikProps} banner={banner} />
            </div>
            <div className="col-12 col-xl-4">
              <TripArea {...formikProps} banner={banner} />
              <PeriodArea {...formikProps} banner={banner} />
            </div>
            <div className="col-12 col-xl-6">
              <PersonArea {...formikProps} banner={banner} />
            </div>
            {/* {!IGNORE_CAPTCHA && (
                <InvisibleRecaptcha
                  recaptchaRef={recaptchaRef}
                  onResolved={response => {
                    setFieldValue('Quote.recaptcha', response);
                    formSubmitRefs.current.click();
                    handleSubmit();
                  }}
                  onError={() => {
                    setSubmitting(false);
                  }}
                />
              )} */}
          </form>
        );
      }}
    </Formik>
  );
}

export default EsInformation;
