export const NUMBER_PERSON = {
  es_kc: 'Pasajeros',
  es_mx: 'Pasajeros',
  es_km: 'Pasajeros',
};

export const AGE_PERSON = {
  es_kc: 'Edad Pasajeros',
  es_mx: 'Edad Pasajeros',
  es_km: 'Edad Pasajeros',
};

export const TYPE_OF_PLAN = {
  es_kc: 'Tipo de Plan',
  es_mx: 'Tipo de Plan',
  es_km: 'Tipo de Plan',
};
export const EMAIL = {
  es_kc: 'Correo electrónico ',
  es_mx: 'Correo electrónico ',
  es_km: 'Correo electrónico ',
};

export const PHONENUMBER = {
  es_kc: 'Celular ',
  es_mx: 'Celular ',
  es_km: 'Celular ',
};
