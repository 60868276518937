import React from 'react';
import styles from './Modal.module.scss';

function Modal(props) {
  const {
    children, background, width96, active,
  } = props;

  const adjustWidth = width96 ? styles.width96 : '';

  if (!active) {
    return <></>;
  }

  return (
    <div
      className={`${adjustWidth} ${styles.activate} ${styles.modalAttention}`}
    >
      {/* {attention && background && ( */}
      <div className={styles.backgroundOpacityBlue} />
      {/* )} */}
      {children}
    </div>
  );
}

export default Modal;
