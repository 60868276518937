import React from 'react';

import styles from '../../Contactanos.module.scss';

function ContactanosItem(props) {
  const { value } = props;
  return (
    <div
      className={`${styles.rowContactanos} ${
        value.id % 2 === 0 ? styles.right : ''
      }${value.id === 3 ? styles.thirdLine : ''}`}
    >
      <div className={styles.image}>
        <img src={value.image} alt="" />
      </div>
      <div className={`${styles.text}`}>
        <h5>{value.title}</h5>
        {value.content}
      </div>
    </div>
  );
}

export default ContactanosItem;
