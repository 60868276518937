import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './StaticPageContent.module.scss';
import privacyPolicyTexts from './contents/PrivacyPolicy_Text';
import { LocationContext } from '../../components/Location/Location';
import StaticPageContentList from './StaticPageContentList/StaticPageContentList';
import { dataLayerTrack } from '../../utils/GTM_helper';

function StaticPageContent(props) {
  const { idiom, country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { type } = props;
  const location = useLocation();

  const getContentPage = (language, pageType) => {
    const content = { texts: '', title: '' };
    switch (pageType) {
      case 'policy':
        content.texts = privacyPolicyTexts[language];
        content.title = intl.TEXT_TITLE_POLICY;
        break;
      default:
        break;
    }
    return content;
  };

  const contents = getContentPage(idiom, type);

  useEffect(() => {
    dataLayerTrack(intl, idiom, country, location);
  }, []);

  return (
    <div className={styles.staticPageContent} data-testid="pageStaticPage">
      <div className={`container-fluid ${styles.content}`}>
        <div className="row">
          <div className="col-12">
            <h1>{contents.title}</h1>
            <p
              style={{
                color: '#555',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              Julio 2024
            </p>
            <p>El presente Aviso de Privacidad se emite con el fin de dar
              cumplimento a la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares (LFPDPPP).
            </p>
          </div>
          <div className="col-12">
            <StaticPageContentList idiom={idiom} list={contents.texts} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaticPageContent;
