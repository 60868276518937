export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  es_ES: 'Campo obligatorio.',
};

export const TRIP_INFORMATION = {
  es_kc: 'INFORMACIÓN DEL VIAJE',
  es_km: 'INFORMACIÓN DEL VIAJE',
  es_mx: 'INFORMACIÓN DEL VIAJE',
};

export const ERROR_DESTINY_COUNTRY = {
  es_kc: 'Debe rellenar el campo "País de Destino"',
  es_km: 'Debe rellenar el campo "País de Destino"',
  es_mx: 'Debe rellenar el campo "País de Destino"',
};

export const ERROR_EXIT_DATE = {
  es_kc: 'Debe rellenar el campo "Fecha de Salida"',
  es_km: 'Debe rellenar el campo "Fecha de Salida"',
  es_mx: 'Debe rellenar el campo "Fecha de Salida"',
};

export const ERROR_BACK_DATE = {
  es_kc: 'Debe rellenar el campo "Fecha de Regreso"',
  es_km: 'Debe rellenar el campo "Fecha de Regreso"',
  es_mx: 'Debe rellenar el campo "Fecha de Regreso"',
};

export const ERROR_INVALID_DATE = {
  es_kc: 'Fecha invalida',
  es_km: 'Fecha invalida',
  es_mx: 'Fecha invalida',
};

export const ERROR_REQUIRED = {
  es_kc: 'Obligatorio',
  es_km: 'Obligatorio',
  es_mx: 'Obligatorio',
};

export const ASISTENCIA = {
  es_kc: 'Adquiere online tu Asistencia de viaje',
  es_km: 'Adquiere online tu Protección de viaje',
  es_mx: 'Adquiere online tu Protección de viaje',
};

export const VALIDATION_REQUIRED = {
  es_kc: 'Obligatorio',
  es_km: 'Obligatorio',
  es_mx: 'Obligatorio',
};

export const INVALID_EMAIL = {
  es_kc: 'Email inválido',
  es_km: 'Email inválido',
  es_mx: 'Email inválido',
};

export const CELULAR_REQUIRED = {
  es_kc: 'Debe rellenar el campo "Celular"',
  es_km: 'Debe rellenar el campo "Celular"',
  es_mx: 'Debe rellenar el campo "Celular"',
};

export const TARJETA_MINLENGTH = {
  es_kc: 'El campo debe contener al menos 10 dígitos',
  es_km: 'El campo debe contener al menos 10 dígitos',
  es_mx: 'El campo debe contener al menos 10 dígitos',
};
