import React from 'react';
import { RichText } from 'prismic-reactjs';

import { isMobile } from 'react-device-detect';
import styles from '../Banner.module.scss';
import CotizarFormStep1 from '../../CotizarFormStep1/CotizarFormStep1';
import { htmlSerializer } from '../../../utils/richText';

function BannerForm({ idiom, setLoading }) {
  return (
    <div className={styles.formArea}>
      <CotizarFormStep1 banner formType={idiom} setLoading={setLoading} />
    </div>
  );
}

function BannerTexts({
  description, content, title, short_content,
}) {
  return (
    <div className={styles.textArea}>
      {content && (
        <p data-testid="bannerContent" className={styles.content}>
          {content}
        </p>
      )}
      {description && (
        <p data-testid="bannerContent" className={styles.content}>
          <RichText render={description} htmlSerializer={htmlSerializer} />
        </p>
      )}
      {short_content && (
        <p data-testid="bannerContent" className={styles.content}>
          {short_content}
        </p>
      )}
    </div>
  );
}

function BannerItem({
  banner, form, idiom, setLoading,
}) {
  const {
    title, content, url, short_content, description,
  } = banner;

  return (
    <div className={isMobile ? styles.wrapanner : styles.wrapBanner}>
      {!isMobile && (
        <img
          className={`${styles.imageBanner}`}
          key={`banner-${banner}`}
          src={url}
          alt={title || ''}
        />
      )}
      <div className={styles.bannerInfo}>
        {title && (content || isMobile) && <BannerTexts {...banner} />}
        <div className={styles.bannerInfoForm}>
          {form && <BannerForm idiom={idiom} setLoading={setLoading} />}
        </div>
      </div>
    </div>
  );
}

export default BannerItem;
