import React, { useContext, useEffect, useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useAllPrismicDocumentsByType } from '@prismicio/react';
import Route from './Route';
import { EspecificRoutes } from './EspecificRoutes';
import { LocationContext } from '../components/Location/Location';
import Home_new from '../pages/Home_new/Home';
import Blog from '../pages/Blog/Blog';
import BlogDetail from '../pages/Blog/BlogDetail/BlogDetail';
import AirportComfort from '../pages/AirportConfort/AirportComfort';
import Planes from '../pages/Planes/Planes';
import Asistencia from '../pages/Asistencia/Asistencia';
import Faq from '../pages/Faq/Faq';
import Contactanos from '../pages/Contactanos/Contactanos';
import AvisoPrivacidad from '../pages/AvisoPrivacidad/AvisoPrivacidad';
import CookiePolicyPage from '../pages/CookiePolicyPage/CookiePolicyPage';

const routesComponent = {
  AirportComfort,
  Planes,
  Asistencia,
  Faq,
  Contactanos,
  AvisoPrivacidad,
};

const commomRoutes = [
  <Route exact path="/" key="/" component={Home_new} />,
  <Route key={1} path="/home" component={Home_new} />,
  <Route key={2} path="/blog/:code_seo" component={BlogDetail} />,
  <Route key={3} path="/blog" component={Blog} />,
  <Route key={4} path="/politica-de-cookies" component={CookiePolicyPage} />,
];

function Router() {
  const { country } = useContext(LocationContext);
  const route = commomRoutes.concat(EspecificRoutes[country]);
  const [routes, { state }] = useAllPrismicDocumentsByType('internal_link', {
    lang: 'es-km',
  });

  const links = useMemo(() => {
    if (state !== 'loaded') return [];
    return [
      ...routes.map(({ id, data }) => (
        <Route
          key={id}
          path={data.linkpath}
          component={routesComponent[data.componenttorender]}
        />
      )),
      <Redirect key={4} to="/home" />,
    ];
  }, [routes, state]);

  return (
    <Switch>
      {route}
      {links}
    </Switch>
  );
}

export default Router;
