import React, { useContext, useEffect } from 'react';

import moment from 'moment';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../Calendar/Calendar';
import styles from './PeriodArea.module.scss';

function PeriodArea(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    setFieldValue,
    banner,
  } = props;
  const {
    TripInitialDate,
    TripType,
  } = values.Quote;
  const isAnnual = TripType === 'Annual';
  useEffect(() => {
    if (!isAnnual) return;

    if (TripInitialDate){
      setFieldValue(
        'Quote.TripEndDate',
        new Date(moment(values.Quote.TripInitialDate).add(1, 'year').subtract(1, 'd')),
        false,
      );
    }
  }, [TripInitialDate, TripType]);

  return (
    <div className={`row pt-2 ${styles.rentalPeriodlArea}`}>
      <div className="col-xl-6 col-sm-6 col-12">
        <label className={styles.labelTitle}>{intl.TRIP_START}</label>
        <Calendar
          dataTestid="calendarDateExit"
          label={intl.LABEL_DATE_FORMAT}
          name="Quote.TripInitialDate"
          onChange={handleChange}
          minDate={new Date()}
          formikProps={props}
          errorNoMessage={banner}
        />
      </div>
      <div className="col-xl-6 col-sm-6 col-12">
        <label className={styles.labelTitle}>{intl.TRIP_END}</label>
        <Calendar
          dataTestid="calendarDateBack"
          label={intl.LABEL_DATE_FORMAT}
          name="Quote.TripEndDate"
          onChange={handleChange}
          minDate={
            values.Quote.TripInitialDate
              ? new Date(moment(values.Quote.TripInitialDate).add(1, 'day'))
              : new Date(moment().add(1, 'day'))
          }
          formikProps={props}
          errorNoMessage={banner}
          disabled={isAnnual}
        />
      </div>
    </div>
  );
}

export default PeriodArea;
