export const TITLE = {
  es_kc: '¡Gracias por tu compra<br />ya estas protegido para tu viaje!',
  es_km: '¡Gracias por tu compra ya estas<br />protegido para tu viaje!',
  es_mx: '¡Gracias por tu compra ya estas<br />protegido para tu viaje!',
};

export const TITLE_QRCODE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const QRCODE_DESC = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const QRCODE_PAYBACK_DEVOLUTION = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const QRCODE_ISSUE_DATE = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_POLICE = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_DEPATURE_DATE = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_DESTINATION = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_POLICE_TYPE = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_POLICE_NUMBER = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_RETURN_DATE = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const QRCODE_TRIP_COST = {
  es_kc: (value = '') => '',
  es_km: (value = '') => '',
  es_mx: (value = '') => '',
};

export const SUB_TITLE_QRCODE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const SUB_TITLE = {
  es_kc: (numberReserve = '') => `Tu número de reservación es: <strong>${numberReserve}</strong><br />Descarga toda la documentacion para tu viaje`,
  es_km: (numberReserve = '') => `Tu número de reservación es: <strong>${numberReserve}</strong><br />Descarga toda la documentacion para tu viaje`,
  es_mx: (numberReserve = '') => `Tu número de reservación es: <strong>${numberReserve}</strong><br />Descarga toda la documentacion para tu viaje`,
};

export const DOWNLOAD_POLIZA = {
  es_kc:
    'Para descargar la póliza de viaje del viajero principal da click en <strong>"Descargar Póliza"</strong>',
  es_km:
  'Para descargar la póliza de viaje del viajero principal da click en <strong>"Descargar Póliza"</strong>',
  es_mx:
    'Para descargar la póliza de viaje del viajero principal da click en <strong>"Descargar Póliza"</strong>',
};

export const BTN_TERMS = {
  es_kc: 'Términos y condiciones',
  es_km: 'Términos y condiciones',
  es_mx: 'Términos y condiciones',
};

export const BTN_POLIZA = {
  es_kc: 'Descargar Póliza',
  es_km: 'Descargar Voucher',
  es_mx: 'Descargar Póliza',
};

export const YOUTUBE_LINK = {
  es_kc: 'https://www.youtube.com/user/MexicoSegurosAXA',
  es_km: 'https://www.youtube.com/user/MexicoSegurosAXA',
  es_mx: 'https://www.youtube.com/user/MexicoSegurosAXA',
};

export const POLICY_HOLDER = {
  en_us: 'PolicyHolder:',
};
export const POLICY_NUMBER = {
  en_us: 'Your Policy Number is:',
};
export const DESTINATION = {
  en_us: 'Destination:',
};
export const PURCHASE_DATE = {
  en_us: 'Purchase Date:',
};
export const COVERED_INDIVIDUALS = {
  en_us: 'Covered Individuals:',
};
export const POLICY_TYPE = {
  en_us: 'Policy Type: ',
};
export const TRIP_START_DATE = {
  en_us: 'Trip Start Date:',
};
export const TOTAL_PLAN = {
  en_us: 'Total Plan Premium:',
};

export const BTN_POLICY = {
  en_us: 'POLICY LINK',
};
export const BTN_CONFIRMATION = {
  en_us: 'CONFIRMATION BENEFITS',
};
export const BTN_ID_CARD = {
  en_us: 'ELECTRONIC ID CARD',
};
export const GIVE_US_FEEDBACK = {
  es_kc: 'Danos tu Opinión',
  es_km: 'Danos tu Opinión',
  es_mx: 'Danos tu Opinión',
};
export const NOTE = {
  en_us:
    'Please note AXA Assistance grants its customers a 10 day free look period, in which we will reimburse you any unearned premium.',
};
export const NEW_PURCHASE = {
  en_us: 'NEW PURCHASE',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Cotizar Asistencia',
  es_km: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
  es_mx: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
};

export const PAGE_NAME_GTM_STEP = {
  es_kc: 'Cotizar Asistencia STEP 4',
  es_km: 'Obtenga una cotización ahora STEP 4',
  es_mx: 'Obtenga una cotización ahora STEP 4',
};
