import {
  formatBenefits,
  formatOptionalBenefits,
  filterOptionalBenefits,
} from '../../../components/CotizarFormStep2/formatBenefits';

const getProducts = data => (data.QuoteKAYAK && data.QuoteKAYAK.ProductKAYAK
  ? data.QuoteKAYAK.ProductKAYAK
  : undefined);

export default async (step2, step3, country, Quote, actions) => {
  const { data, formatedStep2Info } = step3;
  const {
    dataList: { listPlanes },
    productIndex,
  } = formatedStep2Info;

  if (productIndex === listPlanes.length - 1) {
    // last plan (better)
    return;
  }

  const products = getProducts(step2.data);
  const positionPlan = productIndex + 1;
  const nextPlanId = listPlanes[positionPlan].Id;

  const plan = products.find(product => product.Product.Id === nextPlanId);

  const { optionalBenefits } = formatBenefits(products, country, Quote);
  const optionalObj = optionalBenefits
    .map(benefit => formatOptionalBenefits(plan.Product, benefit, positionPlan));
  const filterOptionals = filterOptionalBenefits(optionalObj);

  const newProduct = {
    Id: plan.Product.Id,
    PEkey: plan.Product.PEkey,
    amount: plan.Product.Amount,
    currency: plan.Product.Currency,
    name: plan.Product.Name,
    optionalBenefits: filterOptionals,
    rate: plan.Product.Rate,
    pdf_url: plan.Document ? plan.Document[0].url : '',
  };

  const newFormatedStep2Info = { ...formatedStep2Info };
  newFormatedStep2Info.productIndex = positionPlan;
  newFormatedStep2Info.dataList.optionalBenefits = filterOptionals;

  await actions.formDataCotizar.setForm3({
    data: newProduct,
    formatedStep2Info: newFormatedStep2Info,
  });
};
