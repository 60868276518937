/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Route from './Route';

import { prefixKm } from '../location/urls';
import CotizarAsistencia from '../pages/CotizarAsistencia/CotizarAsistencia';
import PlanesDetail from '../pages/Planes/PlanesDetail/PlanesDetail';
import Nosotros from '../pages/Nosotros/Nosotros';
import Promociones from '../pages/Promociones/Promociones';
import AMEXBeneficios from '../pages/AMEXBeneficios/AMEXBeneficios';

export const EspecificRoutes = {
  [prefixKm]: [
    <Route key={34} path="/cotizar-asistencia" component={CotizarAsistencia} />,
    <Route key={36} path="/nosotros" component={Nosotros} />,
    <Route key={37} path="/mis-planes/:code_seo" component={PlanesDetail} />,
    <Route key={39} path="/promociones" component={Promociones} />,
    <Route key={40} path="/AMEXBeneficios" component={AMEXBeneficios} />,
    <Route
      key={516}
      path="/cotizar-asistencia/planes"
      component={CotizarAsistencia}
    />,
    <Route
      key={517}
      path="/cotizar-asistencia/pago"
      component={CotizarAsistencia}
    />,
    <Route
      key={518}
      path="/cotizar-asistencia/gracias"
      component={CotizarAsistencia}
    />,
    <Route
      key={519}
      path="/travelprotection"
      component={() => {
        window.location.href = 'https://prismic-io.s3.amazonaws.com/b2c-axa/a83a0ffb-6aba-4d87-a373-1dc5cc6bd59f_Comunicaci%C3%B3n+Campa%C3%B1a+Pruebas+Covid-19+Amex+Mex+vInternet+20210921.pdf';
        return null;
      }}
    />,
  ],
};
