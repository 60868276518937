import React from 'react';
import styles from './BenefitRow.module.scss';

import Tooltip from '../../Tooltip/Tooltip';

function BenefitRow(props) {
  const { list } = props;

  const content = list.map((benefit, index) => {
    const { detail, description, tooltip } = benefit;
    const isBenefit = !!description;
    if (isBenefit) {
      return (
        <li className={styles.itemDescription} key={index}>
          <span>{description}</span>
          {tooltip && <Tooltip content={tooltip} />}
        </li>
      );
    }
    return (
      <li className={styles.itemDetail} key={index}>
        {detail}
      </li>
    );
  });

  return <ul className={styles.benefitRow}>{content}</ul>;
}

export default BenefitRow;
