import { RichText } from 'prismic-reactjs';
import { Connect } from './connect';
import { NotFoundError } from './lib/errors/not_found_error';
import { getQueryOptions, setPredicates, setOrderings } from './queryHelper';

const preparePredicateOptions = (headers, entity, query, order, fetchLinks) => {
  const queryOptions = getQueryOptions(headers, true);

  const predicates = setPredicates(headers, entity);

  predicates.push(query);

  if (fetchLinks.length > 0) {
    queryOptions.fetchLinks = fetchLinks;
  }

  const fieldsWithOrder = [];
  fieldsWithOrder.push(order);
  const options = setOrderings(queryOptions, entity, fieldsWithOrder);

  return { predicates, options };
};

const queryFromPrismic = async (headers, entity, predicates, options) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await Connect.query(predicates, options);
    if (response.results.length) {
      return response.results;
    }
    throw NotFoundError(entity, headers['Accept-Language']);
  } catch (err) {
    throw err;
  }
};

export const getDataFromPrismic = async (
  headers,
  entity,
  query,
  order = { field: 'id', orderings: 'asc' },
  fetchLinks = [],
) => {
  const { predicates, options } = preparePredicateOptions(
    headers,
    entity,
    query,
    order,
    fetchLinks,
  );

  const result = await queryFromPrismic(headers, entity, predicates, options);
  return result;
};
