/* eslint-disable no-lonely-if */
import moment from 'moment';
import { CotizarService } from '../../../services';

import { formatData } from './formatData';
import { trackExceptionMonitor } from '../../../AppInsightsTracks';

let quoteResponse = '';
let bodyFormater = {};

const submit = async (props) => {
  const { formType } = props;
  const { quote } = formatData(props);
  bodyFormater = quote;

  quoteResponse = await CotizarService.step1CotizarCreate(formType, quote);
};

const checkObjData = (data) => {
  if (data && data.QuoteKAYAK) {
    return (
      data.QuoteKAYAK.ProductKAYAK && data.QuoteKAYAK.ProductKAYAK.length > 0
    );
  }
  return false;
};

export const submitACotizar = async (props) => {
  const { setSubmitting, history, actions, values, setNotFound } = props;
  let submitAreOk = true;
  setSubmitting(true);

  try {
    await submit(props);
    const Quote = { ...values.Cotizar.Quote };
    const TripInitialDate = new Date(Quote.TripInitialDate);
    const TripEndDate = new Date(Quote.TripEndDate);

    Quote.TripInitialDate = TripInitialDate;
    Quote.TripEndDate = TripEndDate;
    Quote.recaptcha = ''; // clear recaptcha

    if (quoteResponse) {
      const { data } = quoteResponse;
      if (checkObjData(data)) {
        actions.step.setStep(2);
        actions.expire.setExpire();
      } else {
        const errMessage =
          quoteResponse &&
          quoteResponse.data &&
          quoteResponse.data.Error &&
          quoteResponse.data.Error[0] &&
          quoteResponse.data.Error[0].Message
            ? quoteResponse.data.Error[0].Message
            : quoteResponse.message ? quoteResponse.message : null;
        trackExceptionMonitor(
          `submitACotizar - Step1  - ${errMessage}`,
          quoteResponse,
          { bodyFormater, data },
        );
        submitAreOk = false;
      }
      actions.formDataCotizar.setForm1({ Quote });
      actions.formDataCotizar.setForm2(quoteResponse);
    } else {
      submitAreOk = false;
    }
  } catch (error) {
    const errorObject = JSON.parse(JSON.stringify(error));
    trackExceptionMonitor('submitACotizar - Step1 - catch', error, errorObject);
    submitAreOk = false;
  }
  setSubmitting(false);
  return submitAreOk;
};
