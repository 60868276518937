export function focusOnErrorElement(time = 0) {
  setTimeout(() => {
    const allElements = document.getElementsByTagName('*');
    let el;

    for (let index = 0; index < allElements.length; index++) {
      if (allElements[index].getAttribute('formik-error') === '1') {
        el = allElements[index];
        break;
      }
    }

    if (el) {
      el.focus();
      const windowScrollY = window.scrollY || document.documentElement.scrollTop;
      const focusErrorScroll = el.getBoundingClientRect().top + windowScrollY - 200;
      window.scrollTo(0, focusErrorScroll);
    }
  }, time);
}

export function getAllElementsWithAttribute(attribute) {
  const matchingElements = [];
  const allElements = document.getElementsByTagName('*');
  for (let i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute(attribute) !== null) {
      // Element exists with attribute. Add to array.
      matchingElements.push(allElements[i]);
    }
  }
  return matchingElements;
}
