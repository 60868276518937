import { langKm, langKc, langMx } from '../../location/urls';
import kayakAxaSafeTrip from '../../assets/img/AXAKYKSafeTrip.png';
import iconPhone from '../../assets/img/header/call.png';
import iconCertificate from '../../assets/img/header/certificate.png';

const es_kc = {
  logo: {
    src: kayakAxaSafeTrip,
    link: '/',
  },
  headerMenu: [
    {
      value: 'EMISIÓN DE CERTIFICADO',
      link: '/certificate/generate',
      icon: iconCertificate,
      id: 'Option_HeaderCO_0',
    },
    {
      value: '+57 601 6462828',
      link: '/contactanos',
      icon: iconPhone,
      id: 'Option_HeaderCO_2',
    },
  ],
};

const es_km = {
  logo: {
    src: kayakAxaSafeTrip,
    link: '/',
  },
  headerMenu: [
    {
      value: '800 681 5696',
      link: '/contactanos',
      icon: iconPhone,
      id: 'Option_HeaderMX_2',
    },
  ],
};

const es_mx = {
  logo: {
    src: kayakAxaSafeTrip,
    link: '/',
  },
  headerMenu: [
    {
      value: '800 681 5696',
      link: '/contactanos',
      icon: iconPhone,
      id: 'Option_HeaderMX_2',
    },
  ],
};

export default {
  [langKc]: es_kc,
  [langKm]: es_km,
  [langMx]: es_mx,
};
