import React from 'react';

import QuestionCollapse from '../QuestionCollapse/QuestionCollapse';
import styles from './QuestionsList.module.scss';

function QuestionsList(props) {
  const { questions } = props;

  const questionsItens = questions.map((item, index) => (
    <QuestionCollapse key={index} question={item} />
  ));

  return <div className={`row ${styles.content}`}>{questionsItens}</div>;
}

export default QuestionsList;
