import Prismic from 'prismic-javascript';
import * as updatedPrismic from '@prismicio/client';
import { config } from '../../config';

const apiEndpoint = config.REACT_APP_PRISMIC_URL;
let accessToken = config.REACT_APP_PRISMIC_SECRET;

if (accessToken === 'test') {
  accessToken = '';
}
export const Connect = Prismic.client(apiEndpoint, { accessToken });

const client = updatedPrismic.createClient(apiEndpoint, { accessToken });

export default client;
