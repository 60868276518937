import bannerCO from '../../assets/img/home/bannerColombiaVerano.jpg';
import bannerMX from '../../assets/img/home/Banner_Axa9.jpg.webp';

export const BANNER_IMG = {
  es_kc: bannerCO,
  es_km: bannerMX,
  es_mx: bannerMX,
};

export const BANNER_TITLE = {
  es_kc: 'Viaja libre de preocupaciones',
  es_km: '',
  es_mx: '',
};

export const BANNER_CONTENT = {
  es_kc:
    'Que tu única preocupación en estas vacaciones sea divertirte. Te invitamos a conocer las mejores coberturas de asistencia en viaje.',
  es_km: '',
  es_mx: '',
};

export const TITLE_BENEFITS = {
  es_kc: 'Los mejores beneficios para tu viaje',
  es_km: 'Los mejores beneficios para tu viaje',
  es_mx: 'Los mejores beneficios para tu viaje',
};

export const TITLE_BLOGS = {
  es_kc: 'Exclusivo para viajeros!',
  es_km: 'Travel blog',
  es_mx: 'Travel blog',
};

export const SUBTITLE_BLOGS = {
  es_kc: 'Encontrá en nuestro blog los mejores tips de viaje y notas sobre tu proximo destino.',
  es_km: 'Un espacio pensado en tus necesidades como viajero.',
  es_mx: 'Un espacio pensado en tus necesidades como viajero.',
};

export const TITLE_ADVERTISEMENTS = {
  es_kc: '¿Por qué AXA Assistance?',
  es_km: '¿Por qué elegir la Protección de viaje SAFETRIP?',
  es_mx: '¿Por qué elegir la Protección de viaje SAFETRIP?',
};

export const TITLE_BELOWBANNER = {
  es_kc: 'SAFETRIP, un servicio de asistencia en viajes de AXA Partners, en alianza promocional con KAYAK',
  es_km: 'SAFETRIP, un servicio de asistencia en viajes de AXA Partners, en alianza promocional con KAYAK',
  es_mx: 'SAFETRIP, un servicio de asistencia en viajes de AXA Partners, en alianza promocional con KAYAK',
};

export const TITLE_REPORTS = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const TITLE_REASONS = {
  es_kc: 'Un viaje libre de preocupaciones',
  es_km: 'Un viaje libre de preocupaciones',
  es_mx: 'Un viaje libre de preocupaciones',
};

export const READ_MORE = {
  es_kc: 'Conoce más',
  es_km: 'Conoce más',
  es_mx: 'Conoce más',
};

export const GET_A_QUOTE_NOW = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const GIVE_US_FEEDBACK = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
  es_km: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
  es_mx: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
};

export const PAGE_TITLE_TAG = {
  es_kc: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
  es_km: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
  es_mx: 'Asistencias de viaje I AXA Partners en alianza promocional con KAYAK',
};

export const BLOG_BUTTON_TEXT = {
  es_kc: 'Leer Más',
  es_km: 'Leer Más',
  es_mx: 'Leer Más',
};
