import React from 'react';

import styles from './CheckBox.module.scss';

import checkedSvg from '../../assets/img/check-box-checked-wite.svg';
import checkedBlueSvg from '../../assets/img/check-box-checked.svg';
import uncheckedSvg from '../../assets/img/check-box-unchecked.svg';
import Tooltip from '../Tooltip/Tooltip';

function CheckBox({
  blue,
  children,
  dataTestid,
  error,
  onClick,
  tooltip,
  touched,
  value,
}) {
  const imgSrc = value ? (blue ? checkedBlueSvg : checkedSvg) : uncheckedSvg;
  const checked = !!value;
  const dataTest = dataTestid || 'divcheckbox';
  const onClickOverride = () => onClick(!checked);
  const formikError = error && '1';
  return (
    <div formik-error={formikError}>
      <div
        data-testid={dataTest}
        className={styles.CheckBox}
        onClick={onClickOverride}
      >
        <div className={styles.checkArea}>
          {tooltip && <Tooltip content={tooltip} />}
          <img data-testid="checkboxImage" src={imgSrc} alt="" />
        </div>
        {children}
      </div>

      {error && touched && <label className={styles.errorLabel}>{error}</label>}
    </div>
  );
}

export default CheckBox;
