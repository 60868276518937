import {
  formatBenefits,
  filterOptionalBenefits,
  formatOptionalBenefits,
} from '../../../../components/CotizarFormStep2/formatBenefits';
import { submitOptionalBenefits } from './SubmitOptionalBenefit/index';

const PrimaryMedicalCoverage = 'PMU';

export default async (
  step2,
  Quote,
  productCode,
  productSelected,
  country,
  idiom,
  setLogicBenefit,
  updateAmount,
  actions,
) => {
  const products = step2.QuoteUS.ProductUS;
  const plan = products.find(product => product.ProductCode === productCode);
  const positionPlan = products.findIndex(
    product => product.ProductCode === productCode,
  );

  const { optionalBenefits } = formatBenefits(products, country, Quote);
  const optionalObj = optionalBenefits
    .map(benefit => formatOptionalBenefits(plan.Product, benefit, positionPlan));

  const filterOptionals = filterOptionalBenefits(optionalObj);

  productSelected.data.optionalBenefits.forEach(benefit => {
    const { BenefitCode, detailToPlan } = benefit;
    const { selected } = detailToPlan;
    const indexBenefitOptionalNewProduct = filterOptionals.findIndex(
      optional => optional.BenefitCode === BenefitCode,
    );
    if (BenefitCode === PrimaryMedicalCoverage) {
      filterOptionals[
        indexBenefitOptionalNewProduct
      ].detailToPlan.selected = !selected;
    } else if (selected) {
      filterOptionals[
        indexBenefitOptionalNewProduct
      ].detailToPlan.selected = true;
    }
  });

  const idsBenefitsSelected = [];
  filterOptionals.forEach(optional => {
    if (optional.detailToPlan.selected) {
      idsBenefitsSelected.push(optional.DetailId);
    }
  });

  const newProduct = {
    Id: plan.Product.Id,
    PEkey: plan.Product.PEkey,
    amount: plan.Product.Amount,
    currency: plan.Product.Currency,
    name: plan.Product.Name,
    optionalBenefits: filterOptionals,
    rate: plan.Product.Rate,
    pdf_url: plan.Document ? plan.Document[0].url : '',
  };

  await actions.formDataCotizar.setForm3({
    data: newProduct,
    formatedStep2Info: productSelected.formatedStep2Info,
  });

  await submitOptionalBenefits(
    { Product: newProduct },
    Quote,
    step2.Token,
    idsBenefitsSelected,
    idiom,
    setLogicBenefit,
    updateAmount,
  );
};
