import React from 'react';

import styles from './NosotrosContent.module.scss';

function NosotrosCo(props) {
  return (
    <div className={styles.content} data-testid="nosotrosContentCo">
      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left} ${styles.center}`}>
          <span className={`${styles.red} ${styles.fontMedium}`}>
            Razón Social: AXA Asistencia S.A N.I.T: 800.244.309-1
          </span>
          <br />
          <span className={`${styles.blue} ${styles.fontMedium}`}>
            Dirección de notificación judicial: Calle 86 n 10 - 88. Piso 4
            Teléfono: +57 601 6462828
          </span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            AXA Assistance es una filial que pertenece en su totalidad al Grupo
            AXA. En la actualidad AXA Assistance figura entre los líderes
            mundiales de la asistencia, con aproximadamente 6000 colaboradores,
            establecido en más de 30 países y con una actividad en más de 200
            países. Después de consolidar su crecimiento gracias a dos líneas de
            actividades históricas, la asistencia al vehiculo y la asistencia en
            viaje durante una situación de urgencia, AXA Assistance se
            diversifica para responder mejor a las nuevas necesidades.
          </p>
          <p>
            Actualmente, interviene en cuatro ejes de desarrollo importantes:
            Autos, Viajes, y más recientemente Hogar y Salud. Con la voluntad de
            reinventar permanentemente la asistencia, el gestor de asistencia
            demuestra cada día su capacidad en aportar soluciones inmediatas
            pero también en manifestar un saber hacer controlado que, basado en
            una red internacional estructurada, ofrece una gran
            complementariedad de servicios.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Nuestra</span>
          <br />
          <span className={styles.blue}>misión</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            Crear soluciones únicas e inmediatas, procesos comunes y trabajar
            para la integración de prestatarios con el fin de garantizar a
            nuestros clientes, un nivel de servicio homogéneo en todo el mundo y
            un apoyo constante en su vida cotidiana.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={`${styles.red}`}>Pensar desde hoy en</span>
          <br />
          <span className={`${styles.blue}`}>el mundo de mañana</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            Los cambios en el mundo transcurren cada vez más rápido: nuevos
            hábitos de consumo, nuevos comportamientos, nuevas necesidades. La
            estrategia de AXA Assistance toma en cuenta profundamente todas
            estas evoluciones, y se esfuerza para realizarla con el mayor
            respeto hacia los valores fundadores y en el cuadro de una visión
            donde el servicio y la escucha del cliente se sitúan en el corazón
            del enfoque.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Nuestros Valores</span>
          <br />
          <span className={styles.blue}>Frente a nuestros clientes</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            Disponibilidad: Estar presente cuando nuestros clientes nos
            necesiten y estar realmente a la escucha de sus inquietudes..
          </p>
          <p>
            Confiabilidad: Decir lo que se hace, hacer lo que se dice. Todo esto
            en pro a las necesidades de nuestros clientes manteniéndolos siempre
            informados y así lograr ser meritorios de su confianza..
          </p>
          <p>
            Atención: Tratar siempre a nuestros clientes con simpatía y
            consideración, procurándoles una información y un consejo
            personalizado. De esta forma recibimos como recompensa su lealtad.
          </p>
          <p>
            Capacidad de respuesta: Repatriación, gestión de crisis,
            intervención en momentos de urgencia, AXA Assistance hizo de la
            reactividad el centro de su actividad. Todo esto direccionado hacia
            el mejoramiento de nuestra capacidad de solucionar, de forma
            adaptada y en los mejores tiempos, los problemas de nuestros
            clientes. Integración de las redes de proveedores, ayudas a
            domicilio o a las personas, multiplicación de las plataformas
            alrededor del mundo, todo esto contribuye a asegurar la continuidad
            de los procesos y de los servicios 7 días a la semana, 24 horas al
            día.
          </p>
          <p>
            La proximidad: Profundamente atenta a la evolución de los fenómenos
            de la sociedad tales como el envejecimiento de la población, la
            generalización del trabajo de las mujeres o el desarrollo de las
            familias de madres o padres solteros, AXA Assistance acompaña a cada
            persona a lo largo de la vida. Pionera en el tema de servicios a las
            personas, AXA Assistance ya es capaz de ofrecer servicios altamente
            innovadores.
          </p>
          <p>
            La Eficacia: AXA Assistance contempla su oferta de manera global y
            eficaz. Todo se hace de forma a optimizar la calidad de los
            servicios para así darles un nivel perfectamente homogéneo alrededor
            del mundo. El intercambio sistemático de las mejores prácticas entre
            las diferentes actividades de AXA Assistance, permite perfeccionar
            la calidad y responder adecuadamente a las nuevas necesidades que
            nacen en todo el planeta.
          </p>
          <p>
            El compromiso: La asistencia no puede concebirse sin un compromiso
            real. Ya sea en el negocio de la emergencia o en las de las
            necesidades cotidianas, AXA Assistance ofrece un compromiso
            concreto: el de intervenir de manera oportuna y conforme a las
            normas de calidad.
          </p>
          <p>
            La responsabilidad: Acostumbrados a los casos difíciles, de
            emergencia, incluso a las más graves crisis humanitarias, AXA
            Assistance es una empresa responsable. Más que un objetivo
            estratégico AXA Assistance tiene como principal vocación, permitirle
            a cada persona vivir con toda confianza. Para satisfacer a sus
            clientes alrededor del mundo, AXA Assistance pone ante todo su
            carácter responsable.
          </p>
          <p>
            Espíritu de equipo: El sentimiento de estar trabajando en conjunto
            para ofrecer servicios útiles, resolver problemas realmente graves,
            ayudar a nuestros clientes, proveer una tranquilidad, todo esto
            explica la pasión con la cual los empleados de AXA Assistance vienen
            a trabajar cada día. La creación de una Dirección Internacional de
            Recursos Humano dentro de la empresa pretende aprovechar este
            dinamismo, este orgullo, y ayuda a favorecer la proyección
            profesional dentro del mismo Grupo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default NosotrosCo;
