import * as Yup from 'yup';
import moment from 'moment';

export const validationMissedSchema = (intl) => Yup.object().shape({
  Quote: Yup.object().shape({
    AmountCurrencyISOCode: Yup.string().required(intl.VALIDATION_REQUIRED),
    DestinationCountryIsoCode: Yup.string().required(
      intl.ERROR_DESTINY_COUNTRY,
    ),
    TripInitialDate: Yup.string()
      .required(intl.ERROR_EXIT_DATE)
      .nullable(intl.ERROR_EXIT_DATE)
      .test('match', intl.ERROR_INVALID_DATE, function (date) {
        return this.parent.TripInitialDate
          ? moment(date).isAfter(new Date(moment().add(-1, 'day')))
          : true;
      }),
    TripEndDate: Yup.string()
      .required(intl.ERROR_BACK_DATE)
      .nullable(intl.ERROR_BACK_DATE)
      .test('match', intl.ERROR_INVALID_DATE, function (date) {
        return this.parent.TripInitialDate
          ? moment(date).isAfter(moment(this.parent.TripInitialDate))
          : true;
      }),
    Ages: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .required(intl.VALIDATION_REQUIRED)
          .max(74, 'La edad debe ser inferior a 75')
          .nullable(intl.VALIDATION_REQUIRED),
      }),
    ),
  }),
});
