import React from 'react';

import NosotrosCo from './Content/AsistenciaCo';
import NosotrosMx from './Content/AsistenciaMx';

const components = {
  es_kc: NosotrosCo,
  es_mx: NosotrosMx,
  es_km: NosotrosMx,
};

function AsistenciaContent(props) {
  const { idiom } = props;
  const Component = components[idiom];
  return <Component {...props} />;
}

export default AsistenciaContent;
