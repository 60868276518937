/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { appInsights } from './AppInsights';

export const trackExceptionMonitor = (name, error, bodyRequest) => {
  if (!appInsights) return;
  const response = {
    status: error && error.status ? error.status : null,
    error_description:
      error &&
      error.data &&
      error.data.Error &&
      error.data.Error[0] &&
      error.data.Error[0].Message
        ? error.data.Error[0].Message
        : error.message ? error.message : null,
  };
  return appInsights.trackException({
    exception: new Error(`${name}`),
    properties: {
      response,
      body: bodyRequest,
    },
  });
};

export const trackDependecyMonitor = (data, url) => {
  const bodyRequest = data;
  if (!appInsights) return;
  return appInsights.addTelemetryInitializer((telemetry) => {
    if (data) {
      telemetry.data.data = { body: bodyRequest };
      telemetry.Properties.data = { body: bodyRequest };
    }
  });
};
