import { HOME } from '../utils/category';
import { PhonePrismicService } from './prismic';

export default (axios, api) => ({
  getPhone: async (idiom = 'es-km', category = HOME) => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };
    const result = await PhonePrismicService.getPhone(headers, category);
    return result;
  },
});
