import { SEND_EMAIL_TO_BILLING_0 } from '../constantsSubmit';

export const formatEmailRequest = (props) => {
  const object = {};

  object.SendPDFpolicy = true;
  object.SendEachPolicyInSeparateEmail = false;
  object.SendEmailTo = SEND_EMAIL_TO_BILLING_0;
  object.ReserveId = null;
  object.ServiceId = null;

  return { ...object };
};
