import Prismic from 'prismic-javascript';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getBanners: async (headers, category) => {
    headers.category = category;
    const result = await getDataFromPrismic(
      headers,
      'banner',
      Prismic.Predicates.gt('my.banner.enable', 0),
    );
    if (result) {
      const banners = result.map((banner) => ({
        id: banner.data.id,
        title: banner.data.title_content,
        content: banner.data.content,
        url: banner.data.icon_url.url,
      }));
      return banners;
    }
    return [];
  },
});
