import React from 'react';

import styles from './RadioButton.module.css';

function RadioButton(props) {
  const {
    text,
    value,
    name,
    onChange,
    defaultChecked,
    checked,
    dataTestid,
    disabled,
    dangerouslySetInnerHTML,
  } = props;
  return (
    <label className={styles.container}>
      <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
      {text}
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        data-testid={dataTestid}
        disabled={disabled}
      />
      <span className={styles.checkmark} />
    </label>
  );
}

export default RadioButton;
