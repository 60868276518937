import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import styles from '../../Footer.module.scss';

// Renders footer center Links columns (both columns left and right)
function CenterOptions() {
  const [internalLinks, internalState] = useAllPrismicDocumentsByType(
    'internal_link',
    {
      lang: 'es-km',
    },
  );
  const [externalLinks, externalState] = useAllPrismicDocumentsByType(
    'external_link',
    {
      lang: 'es-km',
    },
  );

  const organizeColumns = useCallback((internalL, externalL, column) => {
    // reorganizes links into links from left & right column
    const filter = link => link.data.footercolumn === column;
    const filteredLinks = [
      ...internalL.filter(filter),
      ...externalL.filter(filter),
    ];

    // sorts links according to link placement on column
    const sortCriteria = (a, b) => a.data.placementline - b.data.placementline;
    const sortedLinks = filteredLinks.sort(sortCriteria);

    return sortedLinks.map(({ component }) => component);
  }, []);

  const [leftColumn, rightColumn] = useMemo(() => {
    if (
      internalState.state === 'loaded' && externalState.state === 'loaded' && organizeColumns
    ) {
      // formats each link to component according to link type (external, internal)
      // but keeps data for sorting
      const inL = internalLinks.map(({ data, id }) => ({
        data,
        component: (
          <li key={`liid-${id}`}>
            <Link data-testid="centerItem" to={data.linkpath}>
              {data.linkname[0].text}
            </Link>
          </li>
        ),
      }));
      const exL = externalLinks.map(({ data, id }) => ({
        data,
        component: (
          <li key={`liid-${id}-external`}>
            <a
              data-testid="centerItem"
              href={data.href.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.linkname[0].text}
            </a>
          </li>
        ),
      }));

      // reorganizes links into sorted links from left column and sorted links from right column
      const sortedLeftLinks = organizeColumns(inL, exL, 'left');
      const sortedRightLinks = organizeColumns(inL, exL, 'right');

      return [sortedLeftLinks, sortedRightLinks];
    }
    const loading = <p>Loading...</p>;
    return [loading, loading];
  }, [internalState, externalState, organizeColumns]);

  const showCookieBanner = () => {
    const cookieButton = document.querySelector('.ot-floating-button__open');
    cookieButton.click();
  };

  return (
    <div className={`col-md-9 col-xs-12 col-sm-12 ${styles.centerInfo}`}>
      <div className={styles.columnOptions}>
        <ul
          data-id="ul-external-links"
          key="ul-external-links"
          data-align="left"
        >
          {leftColumn}
          <li>
            <Link data-testid="centerItem" to="/politica-de-cookies">
              Politica de Cookies
            </Link>
          </li>
        </ul>
        <ul
          data-id="ul-internal-links"
          key="ul-internal-links"
          data-align="right"
        >
          {rightColumn}
          <li>
            <button
              className={styles['customize-cookie-options-button']}
              type="button"
              onClick={showCookieBanner}
            >
              Centro de Preferencias de Cookies
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CenterOptions;
