/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';

import * as translations from '../intl';
import { IntlContext } from '../../../../../../intl';
import styles from '../PaymentForm.module.scss';
import Input from '../../../../../../components/Input/Input';

function PaymentCard(props) {
  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    finalPrice,
    step3,
    setAmountWithInterestInstallments,
  } = props;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const changeCardNumber = (e) => {
    handleChange(e);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_NOMBRE}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              name="payment.nombre"
              dataTestid="InputPaymentsNombre"
              type="text"
              formikProps={props}
              mask="maskedNotNumber"
              yellowError
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.NUMBER_TARJETA}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.payment.number}
              name="payment.number"
              dataTestid="InputPaymentsnumber"
              type="text"
              onBlur={handleBlur}
              touched={touched && touched.payment && touched.payment.number}
              error={errors && errors.payment && errors.payment.number}
              mask="maskedCardNumber"
              onChange={changeCardNumber}
              yellowError
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="col-12">
            <label
              className={` ${styles.labelTitle} ${styles.customLabelTitle}`}
            >
              {intl.DATE}
            </label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.payment.date}
              name="payment.date"
              dataTestid="Inputdate"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched && touched.payment && touched.payment.date}
              error={errors && errors.payment && errors.payment.date}
              mask="maskedMonthAndYear"
              label="MM/YY"
              yellowError
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.CVC}</label>
          </div>

          <div className={`col-12 ${styles.inputField} ${styles.cvc}`}>
            <Input
              value={values.payment.cvc}
              name="payment.cvc"
              dataTestid="Inputdate"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched && touched.payment && touched.payment.cvc}
              error={errors && errors.payment && errors.payment.cvc}
              mask="maskedCVC"
              yellowError
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCard;
