export const TITLE_MODAL = {
  es_kc: '',
  es_km: 'Mejora la cobertura de tu plan',
  es_mx: 'Mejora la cobertura de tu plan',
};

export const BTN_CONTINUE = {
  es_kc: '',
  es_km: 'Continuar',
  es_mx: 'CONTINUAR',
};

export const BTN_ADD = {
  es_kc: '',
  es_km: 'AÑADIR',
  es_mx: 'AÑADIR',
};

export const BTN_ADDED = {
  es_kc: '',
  es_km: 'AÑADIDO',
  es_mx: 'AÑADIDO',
};

export const PLAN_BENEFITS = {
  es_kc: '',
  es_km: (plan) => `PLAN ${plan}`,
  es_mx: (plan) => `PLAN ${plan}`,
};
