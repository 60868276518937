import { rulesOptionalBenefitsByCountry } from './rulesByCountry';

const getBenefits = (product) => {
  if (product.BenefitKAYAK) {
    return product.BenefitKAYAK;
  }
  return [];
};

export const formatBenefits = (products, country, Quote) => {
  const descriptionBenefits = [];
  let optionalBenefits = [];
  const complementaryBenefits = [];
  const listPlanes = [];

  products.forEach((product, indexProduct, listProducts) => {
    const arrayListPlanes = products[indexProduct].Product;
    arrayListPlanes.Document = product.Document;
    listPlanes.push(arrayListPlanes);
    getBenefits(product).forEach((benefit) => {
      const { Benefit, BenefitDetailComplementary } = benefit;
      const description = {
        Id: Benefit.Id,
        DetailId: [],
        BenefitCode: benefit.BenefitCode,
        description: Benefit.Description,
        tooltip: Benefit.ToolTip,
        content: [],
      };

      let listBenefits;
      if (BenefitDetailComplementary) {
        listBenefits = complementaryBenefits;
      } else if (Benefit.IsOptional) {
        listBenefits = optionalBenefits;
      } else {
        listBenefits = descriptionBenefits;
      }

      const indexBenefit = listBenefits.findIndex(
        (item) => item.Id === description.Id
          && item.description === description.description,
      );

      if (indexBenefit === -1) {
        description.DetailId[indexProduct] = Benefit.DetailId;
        description.content = listProducts.map(() => ({ detail: '- -' }));
        description.content[indexProduct] = {
          detail: Benefit.Detail,
          amount: Benefit.Amount,
          product: products[indexProduct].Product.Name,
        };
        listBenefits.push(description);
      } else {
        listBenefits[indexBenefit].DetailId[indexProduct] = Benefit.DetailId;
        listBenefits[indexBenefit].content[indexProduct] = {
          detail: Benefit.Detail,
          amount: Benefit.Amount,
          product: products[indexProduct].Product.Name,
        };
      }
    });
  });

  optionalBenefits = rulesOptionalBenefitsByCountry(
    optionalBenefits,
    country,
    Quote,
    products,
  );
  return {
    descriptionBenefits,
    optionalBenefits,
    complementaryBenefits,
    listPlanes,
  };
};

export const formatOptionalBenefits = (product, benefit, positionPlan) => ({
  Id: benefit.Id,
  DetailId: benefit.DetailId[positionPlan],
  BenefitCode: benefit.BenefitCode,
  description: benefit.description,
  tooltip: benefit.tooltip,
  detailToPlan: {
    Id: product.Id,
    rate: product.rate || product.Rate,
    detail: benefit.content[positionPlan].detail,
    amount: benefit.content[positionPlan].amount,
    selected: false || (benefit.isDefault && benefit.isDefault[positionPlan]),
    default: benefit.isDefault && benefit.isDefault[positionPlan],
    appear: benefit.isAppear ? benefit.isAppear[positionPlan] : true,
  },
});

export const filterOptionalBenefits = (optionalBenefits) => optionalBenefits.filter(
  (obj) => obj.detailToPlan.detail !== '- -'
      && obj.detailToPlan.detail.toLowerCase() !== 'Not Available'.toLowerCase()
      && obj.detailToPlan.appear === true,
);
