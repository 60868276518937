import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../Calendar/Calendar';
import styles from './AgeArea.module.scss';
import Input from '../../../../../Input/Input';

function AgeArea(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    _touched,
    _errors,
    index,
    banner,
    policyHolder,
    errorNoMessage,
    dataTestId,
  } = props;

  const checkValue = policyHolder
    ? values.Quote.MPH
    : values.Quote.Ages[index].value;

  const checkName = policyHolder ? 'Quote.MPH' : `Quote.Ages[${index}].value`;
  if (!policyHolder) {
    values.Quote.Ages[index].index = index;
  }

  return (
    <div className={`${styles.ageItem}`}>
      <Input
        dataTestid={dataTestId}
        value={checkValue}
        name={checkName}
        // dataTestid={`InputAgesValue-${index}`}
        type="text"
        formikProps={props}
        mask="maskedAge"
        error={errorNoMessage}
      />
    </div>
  );
}

export default AgeArea;
