import { HOME } from '../utils/category';
import { BannerPrismicService } from './prismic';

export default (axios, api) => ({
  getBanner: async (idiom = 'es-km', category = HOME) => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };

    const result = await BannerPrismicService.getBanners(headers, category);
    return result;
  },
});
