import React, { useContext, useState } from 'react';
import styles from './ModalHours.module.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';

import { IntlContext } from '../../../intl';
import * as translations from './intl';

const ModalHours = ({showModalHours, setShowModalHours}) => {
  useState(() => {
    window.scrollTo(0, 0);
  }, []);
  const removeModalHours = () => {
    setShowModalHours({ ...showModalHours, show: false, index: null });
  };
  return (
    <Modal background active>
      <div className={styles.modal}>
        <div className={styles.modalTitle} key="modalTitle">
          <strong>IMPORTANTE</strong>
          <div className={styles.modalBody}>
            <span>
              Beneficios disponibles únicamente para
              usuarios que no hayan iniciado su viaje.
            </span>
          </div>
          <div>
            <Button
              onClick={removeModalHours}
              value="Continuar"
              link=""
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHours;
