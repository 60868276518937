import React, { memo, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Step4ES.module.scss';
import checkImg from '../../../../assets/img/check.png';
import bannerYouTube from '../../../../assets/img/pagoCompletado.png';
import CompleteGreenCheck from '../../../../assets/img/complete_green_check.jpg';
import docImage from '../../../../assets/img/white-doc.png';
import ButtonLink from '../../../ButtonLink/ButtonLink';
import certificado from '../../../../assets/img/certificado.png';
import { base64ToArrayBuffer } from '../../../../utils/file';
// import OpinionFormStep4 from '../../../OpinionFormStep4/OpinionFormStep4';

const FormattedLinks = memo(({ document, intl }) => (
  <li className={styles.btnItem}>
    <ButtonLink
      value={intl.BTN_TERMS}
      link={document.url}
      icon={docImage}
      css={styles.btnLeaveOpinion}
      external
    />
  </li>
));

function Step4ES({ intl, paypal, reserveData }) {
  const isNewDownloadPolice = JSON.stringify(process.env.REACT_APP_USE_NEW_DOWNLOAD_POLICE)
    === JSON.stringify('on');

  const listDocuments = useMemo(
    () => reserveData.Document.filter(item => item.DocumentType === 0),
    [reserveData],
  );
  const listDocumentsPassengers = useMemo(
    () => reserveData.Document.filter(item => item.DocumentType === 1),
    [reserveData],
  );

  const formatLinks = useMemo(
    () => listDocuments.map(document => (
      <FormattedLinks document={document} intl={intl} />
    )),
    [listDocuments, intl],
  );

  const mappedPassengersList = reserveData.Service.map((value, index, arr) => {
    const name = value.PassengerName.substring(
      0,
      value.PassengerName.lastIndexOf(' '),
    );
    const lastName = value.PassengerName.substring(
      value.PassengerName.lastIndexOf(' '),
    );
    const isLastItem = arr.length - 1 === index;

    let fileName;
    let fileDownloadUrl;
    if (listDocumentsPassengers[index] && isNewDownloadPolice) {
      const blob = new Blob([
        base64ToArrayBuffer(listDocumentsPassengers[index].DocumentByte),
      ]);
      fileName = listDocumentsPassengers[index].FileName;
      fileDownloadUrl = URL.createObjectURL(blob);
    }
    if (listDocumentsPassengers[index]){
      return (
        <div>
          <div key={value.id} className={`row ${styles.passengerItem}`}>
            {isNewDownloadPolice ? (
              <a download={fileName} className={styles.aLeaveOpinion} href={fileDownloadUrl}>
                <img className={styles.icon} src={certificado} alt="" /> {`${name} ${lastName} - Mi Certificado`}
              </a>
            ) : (
              <a
                className={styles.aLeaveOpinion}
                href={
                  listDocumentsPassengers[index]
                  && listDocumentsPassengers[index].url
                }
              >
                <img className={styles.icon} src={certificado} alt="" /> {`${name} ${lastName} - Mi Certificado`}
              </a>
            )}
          </div>
        </div>
      );
    }
    return <></>;
  });

  return (
    <div>
      <div className={styles.header}>
        <img className={styles.greenCheck} src={CompleteGreenCheck} alt="Complete Green Check" />
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: intl.TITLE,
          }}
        />
        <p
          className={styles.subtitle}
          dangerouslySetInnerHTML={{
            __html: intl.SUB_TITLE(reserveData.Id),
          }}
        />
      </div>

      <div className={styles.linksList}>
        <ul>{formatLinks}</ul>
      </div>

      <div className={styles.passengerList}>{mappedPassengersList}</div>

      <div>
        {/* <OpinionFormStep4 /> */}
      </div>
    </div>
  );
}

export default Step4ES;
