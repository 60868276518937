export const NUMBER_PERSON = {
  es_kc: 'Pasajeros',
  es_mx: 'Pasajeros',
  es_km: 'Pasajeros',
};

export const AGE_PERSON = {
  es_kc: 'Edad Pasajeros',
  es_mx: 'Edad Pasajeros',
  es_km: 'Edad Pasajeros',
};

export const TYPE_OF_PLAN = {
  es_kc: 'Pasajeros',
  es_mx: 'Tipo de Plan',
  es_km: 'Tipo de Plan',
};
