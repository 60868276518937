export const LABEL_TRIP_1 = {
  es_kc: 'Cotización',
  es_mx: 'Cotización',
  es_km: 'Cotización',
};

export const LABEL_PLAN_2 = {
  es_kc: 'Plan',
  es_km: 'Plan',
  es_mx: 'Plan',
};

export const LABEL_TRAVELERS_3 = {
  es_kc: 'Checkout',
  es_km: 'Check out',
  es_mx: 'Check out',
};

export const LABEL_PROTEGIDO_4 = {
  es_kc: 'Viaje Protegido!',
  es_km: 'Viaje',
  es_mx: 'Viaje',
};

export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/planes',
  es_km: '/cotizar-asistencia/planes',
  es_mx: '/cotizar-asistencia/planes',
};
