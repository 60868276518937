/* eslint-disable array-callback-return */
/* eslint-disable radix */
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import styles from './CotizarStep4.module.scss';

import * as translations from './intl';
import { IntlContext } from '../../intl';
import { LocationContext } from '../Location/Location';
import { StateContext } from '../StateContextParent/StateContextParent';
import Step4ES from './Step4/ES/Step4ES';
import {
  dataLayerTrack,
  dataLayerPageViewEventTrack,
} from '../../utils/GTM_helper';
import { dateFormated } from '../../utils/date';

function CotizarStep4(props) {
  const { formType, setLoading, reserve } = props;
  const reserveData = reserve && reserve.Reserve[0];
  const serviceData = reserveData && reserveData.Service && reserveData.Service[0];
  const paypal = reserve && reserve.paypal;
  const { idiom, country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils, actions } = useContext(StateContext);

  const { Quote } = utils.getFormDataStep1();
  const { data } = utils.getFormDataStep3();

  const location = useLocation();

  const amountFormatedWithBenefits = utils.getAmountWithBenefitsFormated();

  const tripInitialDate = Quote.DepartureDate
    ? Quote.DepartureDate
    : Quote.TripInitialDate;

  const tripEndDate = Quote.ReturnDate ? Quote.ReturnDate : Quote.TripEndDate;

  const totalDays = moment(tripEndDate).diff(moment(tripInitialDate), 'days');

  const getUnder75Years = ages => ages.filter(age => parseInt(age.value) < 75);

  const getOver75Years = ages => ages.filter(age => parseInt(age.value) >= 75);

  const resetStateForm = () => {
    actions.step.setStep(1);
    actions.formDataCotizar.setDataForm({}, {}, {});
    actions.expire.setExpire('');
  };

  useEffect(() => {
    dataLayerPageViewEventTrack(location.pathname, intl.PAGE_NAME_GTM_STEP);
  }, []);

  useEffect(() => {
    const step4GTM = {
      event: 'enhanced-ecommerce',
      gaEventCategory: 'enhanced ecommerce',
      gaEventAction: 'transaction',
      gaEventLabel: reserveData.Id || '',
      gaEventValue: undefined,
      gaEventNonInteraction: true,
      ecommerce: {
        currencyCode: reserveData.Currency || '',
        purchase: {
          actionField: {
            id: reserveData.Id || '',
            affiliation: `AXA ASSISTANCE ${country}`,
            revenue: reserveData.Amount || '',
            tax: '0.00',
            shipping: '0.00',
            coupon: '',
          },
          products: [
            {
              name: reserveData.ProductName || '',
              id: reserveData.ProductName || '',
              price: amountFormatedWithBenefits || '',
              brand: 'AXA',
              category: 'seguro asistencia',
              variant: undefined,
              quantity: Quote.Ages.length || '',
              origin_country: country || '',
              destination_country: reserveData.Destination || '',
              destination_state: reserveData.Destination || '',
              departure_date: serviceData.TripStartDate || '',
              return_date: serviceData.TripEndDate || '',
              total_days: totalDays || '',
              under_75: getUnder75Years(Quote.Ages).length || 0,
              over_75: getOver75Years(Quote.Ages).length || 0,
            },
          ],
        },
      },
    };

    const optionalBenefits = data && data.optionalBenefits;

    const includeBenefits = optionalBenefits.filter(
      benefit => benefit
        && benefit.detailToPlan
        && benefit.detailToPlan.selected
        && benefit.detailToPlan.selected === true,
    );

    includeBenefits.map(benefit => {
      const benefitToAdd = {
        name: benefit.description,
        id: benefit.description,
        price: benefit.detailToPlan.amount,
        brand: 'AXA',
        category: benefit.description,
        variant: undefined,
        quantity: '1',
      };

      step4GTM.ecommerce.purchase.products.push(benefitToAdd);
    });

    const tagManagerArgsStep3 = {
      dataLayer: { ...step4GTM },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgsStep3);

    utils.clearLocalStore();
    return () => {
      resetStateForm();
    };
  }, []);
  return (
    <div className={styles.cotizarStep4} data-testid="CotizarStep4">
      <div className={styles.content}>
        <Step4ES intl={intl} paypal={paypal} reserveData={reserveData} />
      </div>
    </div>
  );
}

export default CotizarStep4;
