import React, { memo } from 'react';
import SmartDelayWidget from './SmartDelayWidget';
import ComfortThumbnail from './ComfortThumbnail';
import TextSections from './TextSections/TextSections';

function AirportComfort() {
  return (
    <>
      <ComfortThumbnail />
      <div className="d-flex flex-column flex-md-row mw-100 m-auto">
        <TextSections />
        <SmartDelayWidget />
      </div>
    </>
  );
}

export default memo(AirportComfort);
