import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Dropdown from '../../../Dropdown/Dropdown';
import NavOption from '../../../NavOption/NavOption';
import styles from '../../Header.module.scss';
import Button from '../../../Button/Button';
import { StateContext } from '../../../StateContextParent/StateContextParent';

const HeaderItem = props => {
  const { option, setNavMobile, history } = props;
  const { actions } = useContext(StateContext);
  const inlineStyle = {
    textAlign: 'left',
  };

  const goInitFormCotizar = link => {
    const { location } = history;
    if (location.pathname.includes(link)) {
      actions.step.setStep(1);
    } else {
      history.push(link);
    }
  };

  const headerItemOption = () => {
    if (option.dropdown) {
      return (
        <li
          className={`nav-item nav-link ${styles.noMarginBottom}`}
          key={option.id}
          onClick={option.link ? () => setNavMobile(false) : null}
        >
          <Dropdown
            dataTestId="headerOption"
            title={option.value}
            link={option.link}
            icon={option.icon}
          >
            {option.dropdown.map(dropDownItem => (
              <NavOption
                setNavMobile={setNavMobile}
                dataTestId="headerOption"
                value={dropDownItem.value}
                link={dropDownItem.link}
                key={dropDownItem.id}
              />
            ))}
          </Dropdown>
        </li>
      );
    }
    if (option.button) {
      return (
        <li
          className={`nav-item nav-link ${styles.btnCotizarArea}`}
          key={option.id}
          onClick={() => setNavMobile(false)}
        >
          <Button
            dataTestId="headerOption"
            blue
            value={option.value}
            onClick={() => goInitFormCotizar(option.link)}
          />
        </li>
      );
    }
    return (
      <NavOption
        setNavMobile={setNavMobile}
        dataTestId="headerOption"
        value={option.value}
        link={option.link}
        icon={option.icon}
        key={option.id}
        inlineStyle={inlineStyle}
      />
    );
  };
  return headerItemOption();
};

export default withRouter(HeaderItem);
