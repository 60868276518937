import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import styles from './BlogsList.module.scss';
import * as translations from '../intl';
import { IntlContext } from '../../../intl/index';
import BlogItem from './BlogItem/BlogItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const getNumberSlideToShow = list => {
  if (!list) return 0;
  return list.length > 3 ? 3 : list.length;
};

function Arrow({ direction, onClick }) {
  const classArrow = direction === 'left' ? styles.btnLeft : styles.btnRight;
  const icon = direction === 'left' ? faChevronLeft : faChevronRight;
  return <FontAwesomeIcon className={`${styles.btnArrow} ${classArrow}`} onClick={onClick} icon={icon} color="#009ae5" />;
}

function BlogsList(props) {
  const { blogs, title, subtitle } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const list = blogs.map((blog, id) => (
    <div key={id} className={styles.marginBlogItem}>
      <BlogItem key={`blogItem_${id}`} intl={intl} blog={blog} />
      {' '}
    </div>
  ));

  if (blogs.length === 0) {
    return null;
  }

  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const settings = {
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 300,
    slidesToShow: getNumberSlideToShow(blogs),
    slidesToScroll: 1,
    nextArrow: showRightArrow && <Arrow direction="right" />,
    prevArrow: showLeftArrow && <Arrow direction="left" />,
    beforeChange: (oldIndex, newIndex) => {
      if (newIndex === 0) {
        setShowLeftArrow(() => false);
      } else {
        setShowLeftArrow(() => true);
      }
      if (newIndex === getNumberSlideToShow(list) - 1) {
        setShowRightArrow(() => false);
      } else {
        setShowRightArrow(() => true);
      }
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          speed: 300,
          slidesToScroll: 1,
          slidesToShow: 3,
          arrows: true,
          nextArrow: showRightArrow && <Arrow direction="right" />,
          prevArrow: showLeftArrow && <Arrow direction="left" />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          speed: 300,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: showRightArrow && <Arrow direction="right" />,
          prevArrow: showLeftArrow && <Arrow direction="left" />,
        },
      },
    ],
  };
  return (
    <div className={` ${styles.wrapBlogs}`}>
      <div className="col-12">
        <h4 data-testid="titleBlogs" className={styles.title}>
          {title}
        </h4>
        <h5 className={styles.subtitle} data-testid="subtitleBlogs">
          {subtitle}
        </h5>
      </div>
      <div className={`col-12 ${styles.blogs}`}>
        <div className={` justify-content-center ${styles.blogs}`}>
          <Slider {...settings}>{list}</Slider>
        </div>
      </div>
    </div>
  );
}

export default BlogsList;
