import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Carousel.module.scss';

/* eslint-disable no-return-assign */
function Carousel(props) {
  const {
    children,
    dots = false,
    show,
    scroll = show,
    infinite = false,
    indexItemSelected,
    // productsLength
  } = props;
  let slideReference = '';

  useEffect(() => {
    slideReference.slickGoTo(indexItemSelected);
  }, [indexItemSelected]);

  const settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 300,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
          dots: false,
          speed: 300,
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`${styles.caroulselContainer} ${styles.container}`}>
      <Slider
        className={styles.slickCarousel}
        dotsClass={styles.dotsClass}
        ref={(slider) => (slideReference = slider)}
        {...settings}
      >
        {children}
      </Slider>
    </div>
  );
}

export default Carousel;
