import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { LocationContext } from '../Location/Location';

const initializeGtm = country => {
  const isMx = country === 'mx' || country === 'km';
  const isCo = country === 'co' || country === 'kc';

  if (isMx) {
    const gtmId = process.env.REACT_APP_GTM_MX;
    const tagManagerArgs = {
      gtmId,
      dataLayerName: 'PageDataLayer',
    };
    TagManager.initialize(tagManagerArgs);
  }

  if (isCo) {
    const gtmId = process.env.REACT_APP_GTM_CO;
    const tagManagerArgs = {
      gtmId,
      dataLayerName: 'PageDataLayer',
    };
    TagManager.initialize(tagManagerArgs);
  }
};

function GTM() {
  const { country } = useContext(LocationContext);

  useEffect(() => {
    initializeGtm(country);
  }, []);

  return <></>;
}

export default GTM;
