import React, {
  useState, useRef, createContext, createRef,
} from 'react';
import useRefCallback from '../CustomHooks/useRefCallback';

export const HtmlContext = createContext({
  state: {
    refUpgradeBanner: {},
  },
  utils: {
    getUpgradeBanner: () => {},
  },
  actions: {
    refCallbackUpgradeBanner: () => {},
  },
});

function HtmlContextParent({ children }) {
  const [refUpgradeBanner, refCallbackUpgradeBanner] = useRefCallback();
  const [state, setState] = useState({ refUpgradeBanner });

  const getUpgradeBanner = () => refUpgradeBanner && refUpgradeBanner.current;

  const value = {
    state,
    utils: {
      getUpgradeBanner,
    },
    actions: {
      refCallbackUpgradeBanner,
    },
  };

  return <HtmlContext.Provider value={value}>{children}</HtmlContext.Provider>;
}

export default HtmlContextParent;
