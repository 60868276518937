import React, { memo } from 'react';
import styles from './ComfortThumbnail.module.scss';

function ComfortThumbnail() {
  return (
    <div className="card bg-dark text-white">
      <img
        className="card-img"
        src="https://images.prismic.io/b2c-banorte/4ca330d7-a1c6-42b6-8de8-331c0b675162_AXA+Airport+Comfort.png?auto=compress,format"
      />
      <div className={'card-img-overlay card- text-end d-flex flex-column px-4'}>
        <h2 className={`card-title  ${styles.responsivetitle}`}>
          AHORA PODRÁS DISFRUTAR DE LA DEMORA DE TU VUELO
        </h2>
        <p className={`card-text  ${styles.responsivetext}`}>
          En caso de 90 minutos de retraso, o más, de un vuelo, podrás acceder gratuitamente
          a las salas VIP de los aeropuertos de todo el mundo,
          donde podrás relajarte cómodamente mientras esperas tu vuelo.
        </p>
      </div>
    </div>
  );
}

export default memo(ComfortThumbnail);
