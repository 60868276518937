import React from 'react';

import styles from '../BenefitsList.module.scss';

function BenefitsItem(props) {
  const {
    benefit: {
      title, content, url, id,
    },
  } = props;
  return (
    <div
      className={`${styles.item}`}
      data-testid="benefitItem"
    >
      <div className={styles.imgArea}>
        <img src={url} alt="benefit_" />
      </div>
      <div className={styles.infoArea}>
        <h5 data-testid="title_benefitItem" className={styles.title}>
          {title}
        </h5>
        <p data-testid="content_benefitItem" className={styles.content}>
          {content}
        </p>
      </div>
    </div>
  );
}

export default BenefitsItem;
