import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './BlogItem.module.scss';
import { LocationContext } from '../../../components/Location/Location';
import { IntlContext } from '../../../intl/index';
import * as translations from '../intl';

function BlogItem(props) {
  const {
    value: {
      title,
      icon_url: iconUrl,
      short_content: shortContent,
      uid: codeSeo,
      date_of_publication: publicationDate,
    },
  } = props;

  const { country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);
  const isCO = country === 'co' || country === 'kc';

  return (
    <div className={`col-lg-4 col-md-6 col-sm-12 ${styles.blogItem}`}>
      <div className={styles.blogItemImage}>
        <img title={title} alt="" src={iconUrl} />
      </div>
      <div className={styles.blogItemDetail}>
        <div className={styles.blogItemDetailTitle}>
          <h2>
            <Link to={`/blog/${codeSeo}`}>{title}</Link>
          </h2>
        </div>
        {isCO && (
          <div className={styles.blogItemDetailDate}>{publicationDate}</div>
        )}
        <div className={styles.blogItemDetailText}>
          {shortContent}
          {' '}
          <Link to={`/blog/${codeSeo}`}>{intl.READ_MORE}</Link>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
