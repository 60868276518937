import Prismic from 'prismic-javascript';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getPlanes: async (headers) => {
    const result = await getDataFromPrismic(
      headers,
      'planes',
      Prismic.Predicates.gt('my.planes.enable', 0),
    );
    if (result) {
      const planes = result.map((plane) => ({
        id: plane.data.id,
        plane_uid: plane.uid,
        title: plane.data.title,
        subtitle: plane.data.subtitle,
        shortcontent: plane.data.shortcontent,
        content: plane.data.content,
        icon_url: plane.data.icon_url.url,
        pdf_url: plane.data.pdf_url.url,
      }));
      return planes;
    }
    return [];
  },

  getPlaneById: async (headers, planeID) => {
    const fetchLinks = [
      'coverage_plane_benefit.title',
      'coverage_plane_benefit.content',
    ];
    const result = await getDataFromPrismic(
      headers,
      'planes',
      Prismic.Predicates.at('my.planes.uid', planeID),
      { field: 'id', orderings: 'asc' },
      fetchLinks,
    );
    if (result) {
      let planeRes;
      result.forEach((plane) => {
        const benefits = plane.data.benefits.map((benefit) => ({
          title: benefit.benefituid.data.title,
          coverage: benefit.benefituid.data.content,
        }));

        planeRes = {
          id: plane.data.id,
          plane_uid: plane.uid,
          title: plane.data.title,
          subtitle: plane.data.subtitle,
          shortcontent: plane.data.shortcontent,
          content: plane.data.content,
          icon_url: plane.data.icon_url.url,
          pdf_url: plane.data.pdf_url.url,
          benefits,
        };
      });
      return planeRes;
    }
    return undefined;
  },
});
