/* eslint-disable no-lonely-if */
import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import styles from './CotizarStep3.module.scss';
import Form from './Form/Form';
import { LocationContext } from '../../../components/Location/Location';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { getRefs } from './getRefs';
import { getDestinationCountryFromFormIsoCode } from '../../../utils/destinationCountryFromIsoCode';
import { dateFormated } from '../../../utils/date';
import { checkErrorsOnForm } from './checkErrorsOnForm';
import { submitEnroll } from './submit';
import { CARD, PAYPAL } from './constants';
import { CotizarService } from '../../../services';
import { trackExceptionMonitor } from '../../../AppInsightsTracks';
import { HtmlContext } from '../../../components/HtmlContextParent/HtmlContextParent';
import upgrade from './upgradePlan';
import { dataLayerPageViewEventTrack } from '../../../utils/GTM_helper';
import { submitPromotionalCode } from './submitPromotionalCode';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { formatStep3QuoteEs } from '../../../components/CotizarFormStep1/submit/quote/index';

function CotizarStep3({
  formType,
  history,
  location,
  setLoading,
  setReserve,
  step1,
  step2,
  step3,
}) {
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const {
    utils: { getUpgradeBanner },
  } = useContext(HtmlContext);
  const upgradeBannerRef = useMemo(() => getUpgradeBanner(), [
    getUpgradeBanner,
  ]);
  const intl = useMemo(() => translate(translations), [translate]);
  const { utils, actions } = useContext(StateContext);
  const [schemas] = useState({});
  const [values] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(CARD);
  const [stateForm, setStateForm] = useState({});
  const submitRefs = getRefs(useRef);
  const recaptchaRef = getRefs(useRef);
  const formSubmitRefs = getRefs(useRef);
  const isMX = useMemo(
    () => getCountryPrefix() === 'mx' || getCountryPrefix() === 'km',
    [getCountryPrefix],
  );

  const [recibirNoticias, setRecibirNoticias] = useState(!!isMX);
  const [isOperatorNameVisible, setIsOperatorNameVisible] = useState(false);
  const [operatorName, setOperatorName] = useState('');
  const [aceptoLosTermos, setAceptoLosTermos] = useState(undefined);
  const [
    amountWithInterestInstallments,
    setAmountWithInterestInstallments,
  ] = useState(null);
  const { Quote } = useMemo(() => utils.getFormDataStep1(), [utils]);
  const { data } = useMemo(() => utils.getFormDataStep3(), [utils]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    Quote.ProductDescription = `${data.Id} ${data.name}`;
    const fv = async (props) => {
      const step1Quote = formatStep3QuoteEs(Quote, formType);
      await CotizarService.step1CotizarCreate(formType, step1Quote);
    };
    fv();
  }, []);

  const existsOptionalBenefitSelected = data.optionalBenefits.some(
    (benefit) =>
      benefit.detailToPlan.selected && benefit.detailToPlan.selected === true,
  );
  const scrollTop = useCallback(() => window.scrollTo(0, 0), []);
  const [cupon, setCupon] = useState(
    data.promotionalCode ? data.promotionalCode : '',
  );
  const [cuponError, setCuponError] = useState('');

  const upgradePlan = useCallback(
    () => upgrade(step2, step3, country, Quote, actions).then(() => window.location.reload()),
    [Quote, actions, country, step2, step3],
  );

  useEffect(() => {
    dataLayerPageViewEventTrack(location.pathname, intl.PAGE_NAME_GTM_STEP);
  }, [intl.PAGE_NAME_GTM_STEP, location.pathname]);

  useEffect(() => {
    if (upgradeBannerRef) {
      upgradeBannerRef.addEventListener('click', upgradePlan);
    }
  }, [upgradeBannerRef, upgradePlan]);

  const updateForm = useCallback(
    (index, childValues, validationSchema) => {
      if (childValues !== values[index]) {
        values[index] = childValues;
      }
      if (validationSchema !== 'none' && validationSchema !== schemas[index]) {
        schemas[index] = validationSchema;
      }
      setPaymentMethod(values.Cotizar.payment.tarjeta);
      setStateForm(values.Cotizar);
    },
    [schemas, values],
  );

  const submit = useCallback(() => {
    let reponseEnroll;
    let reponseErrorSubmit;
    checkErrorsOnForm(submitRefs, schemas, values, recaptchaRef)
      .then(async (areFormsOk) => {
        if (areFormsOk && aceptoLosTermos) {
          const params = {
            formType,
            values,
            setSubmitting: setLoading,
            intl,
            idiom,
            history,
            idiomForApi: idiomAPI,
            actions,
            utils,
            recibirNoticias,
            aceptoLosTermos,
            optionalBenefits: data.optionalBenefits,
            operatorName,
          };
          const isPayPal =
            values.Cotizar.payment.paypalPaymentId !== null &&
            values.Cotizar.payment.tarjeta === PAYPAL;

          setLoading(true);
          let errorIsMatch = false;
          const returnSubmit = await submitEnroll(params);

          reponseEnroll = returnSubmit.reponseSubmit;
          reponseErrorSubmit = returnSubmit.reponseErrorSubmit;
          if (reponseErrorSubmit) {
            const errMessage =
              reponseErrorSubmit &&
              reponseErrorSubmit.data &&
              reponseErrorSubmit.data.Error &&
              reponseErrorSubmit.data.Error[0] &&
              reponseErrorSubmit.data.Error[0].Message
                ? reponseErrorSubmit.data.Error[0].Message
                : null;
            trackExceptionMonitor(
              `submitEnroll - Step3 - ${errMessage}`,
              reponseErrorSubmit,
              reponseEnroll,
            );
            if (reponseErrorSubmit.data && reponseErrorSubmit.data.Error) {
              const searchMatchError = reponseErrorSubmit.data.Error.find(
                (item) =>
                  item.Message.includes(
                    'Please contact our customer service department',
                  ),
              );

              const infomationAlreadyGenerated = reponseErrorSubmit.data.Error.find(
                (item) => item.LogId === 0,
              );

              if (searchMatchError) {
                errorIsMatch = true;
                setShowError(intl.CUSTOM_ERROR_MSG);
              } else if (
                infomationAlreadyGenerated &&
                infomationAlreadyGenerated.Message
              ) {
                const { Message } = infomationAlreadyGenerated;
                setShowError(Message.replace(/error:/gim, ''));
              } else {
                setShowError(intl.GENERIC_ERROR);
              }
            } else {
              setShowError(intl.GENERIC_ERROR);
            }
          }

          if (reponseEnroll && !errorIsMatch) {
            if (isPayPal) reponseEnroll.paypal = values.Cotizar.payment.paypalPaymentId;
            setReserve(reponseEnroll);
            actions.step.setStep(4);
            history.push(intl.LINK_FORM_COTIZAR);
          } else {
            let msgError = true;
            // Refuse if PayPal Payment
            if (
              values &&
              values.Cotizar &&
              values.Cotizar.payment &&
              values.Cotizar.payment.paypalPaymentId &&
              values.Cotizar.payment.paypalPaymentId !== null
            ) {
              // TODO get value from values
              const refusePayment = {
                paypalPaymentId: values.Cotizar.payment.paypalPaymentId,
              };
              const refuse = await CotizarService.step3RefusePayPalPayment(
                idiom,
                refusePayment,
              );
              // Clear paypal form data
              const cotizar = { ...stateForm };
              cotizar.payment.paypalPaymentId = null;
              cotizar.payment.payerID = null;
              updateForm('Cotizar', cotizar, 'none');

              if (refuse.data.refundID) {
                msgError = intl.REFUNDED_ERROR1;
                if (refuse) {
                  trackExceptionMonitor(
                    'submitEnroll - Step3 - refuse1',
                    {},
                    { refuse },
                  );
                }
              } else {
                msgError = intl.REFUNDED_ERROR2(
                  values.Cotizar.payment.paypalPaymentId,
                );
                if (refuse) {
                  trackExceptionMonitor(
                    'submitEnroll - Step3 - refuse2',
                    {},
                    { refuse },
                  );
                }
              }
            }
            setShowError(msgError);
          }
          setLoading(false);
          scrollTop();
        } else {
          if (aceptoLosTermos === undefined) setAceptoLosTermos(false);
        }
      })
      .catch((error) => {
        setShowError(intl.GENERIC_ERROR);
        setLoading(false);
        const errorObject = JSON.parse(JSON.stringify(error));
        trackExceptionMonitor(
          'submitEnroll - Step3 - general',
          error,
          errorObject,
        );
      });
  }, [
    aceptoLosTermos,
    actions,
    data.optionalBenefits,
    formType,
    history,
    idiom,
    idiomAPI,
    intl,
    recaptchaRef,
    recibirNoticias,
    schemas,
    scrollTop,
    setLoading,
    setReserve,
    stateForm,
    submitRefs,
    updateForm,
    utils,
    values,
  ]);

  const optionalBenefits =
    data.optionalBenefits &&
    data.optionalBenefits.map((benefit, index) => {
      if (benefit.detailToPlan.selected) {
        const cost = (
          benefit.detailToPlan.amount * benefit.detailToPlan.rate
        ).toFixed(2);
        const isCFAR = benefit.BenefitCode === 'CFA';
        return (
          <div
            className={styles.planeDetail}
            key={`${benefit.description}-${benefit.detailToPlan.amount}-${index}`}
          >
            <div className={styles.planeOptional}>
              <span>{benefit.description}</span>
              {!isCFAR && (
                <p>
                  <span>{`$${cost}`}</span>
                  <span className={styles.currency}>{data.currency}</span>
                </p>
              )}
            </div>
          </div>
        );
      }
      return undefined;
    });

  const tripInitialDate = Quote.DepartureDate
    ? Quote.DepartureDate
    : Quote.TripInitialDate;

  const tripEndDate = Quote.ReturnDate ? Quote.ReturnDate : Quote.TripEndDate;

  const fromTripDateFormated = dateFormated(tripInitialDate, idiom);
  const toTripDateFormated = dateFormated(tripEndDate, idiom);

  const totalDays = moment(tripEndDate).diff(moment(tripInitialDate), 'days');

  const formatNumber = (stringNumber) =>
    parseFloat(stringNumber.replace(',', ''));

  const finalPrice = () =>
    Number(
      data.promotion
        ? formatNumber(utils.getAmountWithBenefitsFormated()) - data.promotion
        : formatNumber(utils.getAmountWithBenefitsFormated()),
    ).toFixed(2);

  const amountFormated = utils.getAmountFormated();
  const amountFormatedWithBenefits =
    amountWithInterestInstallments || finalPrice();

  const destination = Quote.AmountCurrencyISOCode && Quote.DestinationCountryIsoCode
    ? getDestinationCountryFromFormIsoCode(
      Quote.AmountCurrencyISOCode,
      Quote.DestinationCountryIsoCode,
    )
    : '';

  const cuponChange = (event) => {
    setCupon(event.target.value);
  };

  const updatePromotionInState = (code, promotion) => {
    const form3 = utils.getFormDataStep3();
    form3.data.promotionalCode = code;
    form3.data.promotion = promotion;
    actions.formDataCotizar.setForm3(form3);
  };

  const addCupon = async (event) => {
    event.preventDefault();
    if (cupon) {
      setLoading(true);
      await submitPromotionalCode(
        data,
        idiom,
        cupon,
        setCupon,
        Quote,
        updatePromotionInState,
        setCuponError,
      );
      setLoading(false);
    }
  };

  const removeCupon = async () => {
    setLoading(true);
    updatePromotionInState(undefined, undefined);
    setCupon('');
    setCuponError('');
    setLoading(false);
  };
  const coutryOrigin = useCallback((currency) => {
    let resultCurrency = '';
    switch (currency) {
      case 'USD':
        resultCurrency = 'United States';
        break;

      default:
        break;
    }
    return resultCurrency;
  }, []);

  const getUnder75Years = useCallback(
    (ages) => ages.filter((age) => parseInt(age.value, 10) < 75),
    [],
  );

  const getOver75Years = (ages) =>
    ages.filter((age) => parseInt(age.value, 10) >= 75);

  useEffect(() => {
    const step3GTM = {
      event: 'enhanced-ecommerce',
      gaEventCategory: 'enhanced ecommerce',
      gaEventAction: 'checkout step',
      gaEventLabel: '3',
      gaEventValue: undefined,
      gaEventNonInteraction: true,
      ecommerce: {
        currencyCode: data.currency || '',
        checkout: {
          actionField: {
            step: 3,
            action: undefined,
          },
          products: [
            {
              name: data.name || '',
              id: data.name || '',
              price: amountFormatedWithBenefits || '',
              brand: 'AXA',
              category: 'seguro asistencia',
              variant: undefined,
              quantity: Quote.Ages.length || '',
              origin_country: coutryOrigin(data.currency) || '',
              destination_country: destination || '',
              destination_state: '',
              departure_date: fromTripDateFormated || '',
              return_date: toTripDateFormated || '',
              total_days: totalDays || '',
              under_75: getUnder75Years(Quote.Ages).length || 0,
              over_75: getOver75Years(Quote.Ages).length || 0,
            },
          ],
        },
      },
    };

    const tagManagerArgsStep3 = {
      dataLayer: { ...step3GTM },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgsStep3);
  }, [
    Quote.Ages,
    amountFormatedWithBenefits,
    coutryOrigin,
    data.currency,
    data.name,
    destination,
    fromTripDateFormated,
    getUnder75Years,
    toTripDateFormated,
    totalDays,
  ]);

  const onChangeIsOperatorNameVisible = () => {
    setIsOperatorNameVisible((pv) => {
      if (!pv === false) {
        setOperatorName(() => '');
      }
      return !pv;
    });
  };

  const onChangeOperatorName = (event) => {
    setOperatorName(() => event.target.value);
  };

  return (
    <div className={styles.cotizarFillForm} data-testid="CotizarFillFormStep3">
      <div className={styles.cotizarHeader}>
        <h1 data-testid="formTitle">{intl.TITLE_STEP3}</h1>
      </div>
      {showError && (
        <div className={styles.errorLabel}>
          {showError === true ? intl.GENERIC_ERROR : showError}
        </div>
      )}
      <Form
        formType={idiom}
        parentValues={values}
        updateParent={updateForm.bind(null, 'Cotizar')}
        incidentSubmitRef={submitRefs.Cotizar}
        recaptchaRef={recaptchaRef.Cotizar}
        formSubmitRefs={formSubmitRefs.Cotizar}
        step1={step1}
        step2={step2}
        step3={step3}
        setLoading={setLoading}
        recibirEmail={{
          recibirNoticias,
          setRecibirNoticias,
        }}
        aceptLosTermos={{
          aceptoLosTermos,
          setAceptoLosTermos,
        }}
        existsOptionalBenefitSelected={existsOptionalBenefitSelected}
        finalPrice={finalPrice()}
        setAmountWithInterestInstallments={setAmountWithInterestInstallments}
        submit={submit}
        stateForm={stateForm}
        schemas={schemas}
        amountFormatedWithBenefits={amountFormatedWithBenefits}
        formatNumber={formatNumber}
      >
        <div className={styles.detail}>
          <div className={styles.formDetail}>
            <span className={styles.boxTitle}>{intl.TEXT_RESUMEM_COMPRA}</span>
            <div className={styles.whiteBox}>
              <div className={styles.planeDetail}>
                <div className={styles.plane}>
                  <p className={styles.title}>{data.name}</p>
                  <p className={styles.price}>
                    ${amountFormated}
                    <span className={` ${styles.value} ${styles.currency}`}>
                      {data.currency}
                    </span>
                  </p>
                </div>
              </div>

              {existsOptionalBenefitSelected && optionalBenefits}
              {data.promotion && (
                <div className={styles.totalDetail}>
                  <p>
                    <span className={styles.title}>
                      {intl.DESCUENTO_APLICADO}
                    </span>
                  </p>
                  <div className={`${styles.valueRight}`}>
                    <p>
                      <span>{`$${data.promotion}`}</span>
                      <span className={styles.currency}>{data.currency}</span>
                    </p>
                  </div>
                </div>
              )}

              <div className={styles.arrowDots} />

              <div className={styles.totalDetail}>
                <p className={styles.total}>
                  <span className={styles.title}>{intl.TOTAL_APLICADO}</span>
                </p>
                <p
                  className={`${styles.valueFinal} ${styles.valueRight} ${styles.totalValue}`}
                >
                  ${finalPrice()}
                  <span className={` ${styles.value} ${styles.currency}`}>
                    {data.currency}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.cuponArea}>
              <span className={styles.boxTitle}>{intl.LABEL_CUPON_TITLE}</span>
              <div className={styles.form}>
                <div className={styles.inputArea}>
                  <Input
                    value={cupon}
                    name="cupon"
                    type="text"
                    onChange={cuponChange}
                  />
                  {cuponError === 'INVALID_CUPON' && (
                    <div className={styles.errorLabel}>
                      {intl.INVALID_CUPON}
                    </div>
                  )}
                </div>
                <div className={styles.buttonArea}>
                  <Button
                    blue
                    value={
                      data.promotion
                        ? intl.BTN_RETIRAR_CUPON
                        : intl.BTN_CANJEAR_CUPON
                    }
                    onClick={data.promotion ? removeCupon : addCupon}
                    dataTestid="button-cupon"
                  />
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div>
                <input
                  type="checkbox"
                  value={isOperatorNameVisible}
                  onChange={onChangeIsOperatorNameVisible}
                  className="mr-2"
                />
                <span className={styles.boxTitle}>
                  ¿Recibiste apoyo telefónico?
                </span>
              </div>
              {isOperatorNameVisible && (
                <div className={styles.inputArea}>
                  <Input
                    name="operatorName"
                    type="text"
                    label="Nombre del operador(a)"
                    value={operatorName}
                    onChange={onChangeOperatorName}
                  />
                </div>
              )}
            </div>

            <span className={styles.boxTitle}>{intl.TEXT_RESUMEM_VIAJE}</span>
            <div className={styles.whiteBox}>
              {Quote.AmountCurrencyISOCode && Quote.DestinationCountryIsoCode && (
                <div className={styles.viageDetail}>
                  <div className={styles.tripDetail}>
                    <span>{intl.LABEL_ORIGEN}</span>
                    <span>{Quote.AmountCurrencyISOCode}</span>
                  </div>
                  <div className={styles.tripDetail}>
                    <span>{intl.LABEL_DESTINO}</span>
                    <span>{destination}</span>
                  </div>
                </div>
              )}

              <div className={styles.arrowDots} />

              <div className={styles.viageDetail}>
                <div className={styles.tripDetail}>
                  <span>{intl.LABEL_FECHA_SALIDA}</span>
                  <span className={styles.from}>{fromTripDateFormated}</span>
                </div>
                <div className={styles.tripDetail}>
                  <span>{intl.LABEL_FECHA_REGRESO}</span>
                  <span className={styles.to}>{toTripDateFormated}</span>
                </div>
              </div>

              <div className={styles.arrowDots} />

              <div className={styles.viageDetail}>
                <div className={styles.tripDetail}>
                  <span>{intl.LABEL_PASAJEROS}</span>
                  <span>{Quote.numberTraleversInput}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withRouter(CotizarStep3);
