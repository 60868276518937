import React from 'react';

import styles from '../StaticPageContent.module.scss';
import {
  langKm, langKc, langMx,
} from '../../../location/urls';

const decimalListStyle = {
  listStyleType: 'decimal',
};

const privacyPolicyCo = [
  {
    id: '1',
    title: '',
    subtitle: '',
    content: (
      <div>
        <p>
          AXA ASISTENCIA COLOMBIA S.A., en adelante “AXA”, en cumplimiento de la
          Ley de Protección de Datos personales aplicable en Colombia actuando
          en calidad de responsable del tratamiento de sus datos personales, le
          informa que los datos personales solicitados serán utilizados para los
          siguientes fines:
        </p>
        <ul style={decimalListStyle}>
          <li>
            Adelantar los trámites y servicios que tiene a cargo, en ejercicio
            de sus funciones.
          </li>
          <li>Prestar los servicios de asistencia.</li>
          <li>Dar a conocer los servicios ofrecidos por AXA.</li>
          <li>Evaluar la calidad de los servicios de AXA</li>
          <li>
            Contacto a través de canales presenciales o no presenciales para la
            recepción de publicidad, obsequios e información sobre diferentes
            productos de asistencia.
          </li>
          <li>
            Realizar informes comerciales, estadísticas, encuestas y estudios de
            mercado.
          </li>
          <li>
            Transfieran sus datos personales a terceros con la finalidad de
            realizar actividades de telemarketing.
          </li>
          <li>
            Llevar a cabo campañas de carácter comercial o publicitario sobre
            los servicios que ofrezca AXA, o sus aliados y los cambios que
            implemente respecto del mismo.
          </li>
          <li>
            Enviar al correo físico, electrónico, celular o dispositivo móvil,
            vía mensaje de texto o a través de cualquier canal de comunicación
            información comercial o publicitaria de los servicios ofrecidos con
            el fin de informar y llevar a cabo campañas de carácter comercial
            y/o publicitario adelantadas por AXA, o sus aliados.
          </li>
          <li>
            Realizar el tratamiento de información personal, la cual sea
            necesaria para el cumplimiento de los deberes legales relacionados
            con el servicio de asistencia, pudiendo compartir su información
            personal con contratistas, sub contratistas, proveedores,
            proveedores tecnológicos, proveedores de salud, operadores
            logísticos, compañías financieras, compañías de franquicias,
            compañías de terceros que estén relacionados con la prestación de
            los servicios de asistencia aquí contratados, filiales del grupo AXA
            que se encuentren ubicadas en Colombia o en cualquier otro país en
            donde las filiales del grupo AXA tengan operación o con cualquier
            tercero que con ocasión a la ejecución del servicio de asistencia
            deba conocer mi información personal.
          </li>
          <li>
            Tratamiento de datos personas sensibles, siempre y cuando los mismos
            resulten indispensables para el desarrollo del servicio de
            asistencia entendiendo que la revelación de estos datos es
            facultativa.
          </li>
          <li>
            AXA teniendo en cuenta la regulación de la Ley 1581 de 2012, la cual
            entre otros categoriza los datos de carácter especial su tratamiento
            y prohibición, como lo son: (a) los datos sensibles entendidos como
            los que afectan la intimidad del Titular o cuyo uso indebido puede
            generar su discriminación, tales como aquellos que revelen el origen
            racial o étnico, la orientación política, las convicciones
            religiosas o filosóficas, la pertenencia a sindicatos,
            organizaciones sociales, de derechos humanos o que promueva
            intereses de cualquier partido político o que garanticen los
            derechos y garantías de partidos políticos de oposición, así como
            los datos relativos a la salud, a la vida sexual y los datos
            biométricos; y los (b) Datos personales de los niños, niñas y
            adolescentes se puede realizar el tratamiento de éstos siempre y
            cuando el fin que se persiga con dicho tratamiento responda al
            interés superior de los niños, niñas y adolescentes y se asegure sin
            excepción alguna el respeto a sus derechos prevalentes; así como
            procesar cobro de los servicios de asistencia, almacenamiento en las
            bases de datos de AXA y consulta de información financiera, ante
            entidades financieras, que se relacione exclusivamente con los pagos
            recurrentes de los servicios objeto de este acuerdo.
          </li>
        </ul>
        <p>
          La Ley 1581 del 2012 prohíbe el tratamiento de datos sensibles con
          excepción de algunos casos entre los cuales cuando el Titular otorga
          su consentimiento, es decir, el consentimiento del titular habilita al
          tratamiento de los datos personales de carácter especial.
        </p>
        <p>
          Los datos personales son recopilados para los fines expuestos
          previamente, y no se extenderá a otra finalidad que no haya sido la
          establecida de manera inequívoca como tal al momento de su
          recopilación, excluyendo los casos de actividades de valor histórico,
          estadístico o científico cuando se utilice un procedimiento de
          disociación o anonimización.
        </p>
        <p>
          Los datos personales generales y sensibles no serán utilizados para
          hacer estudio de mercado, minería de datos, segmentación de productos
          y/o perfilamiento de cliente.
        </p>
        <p>
          Los datos personales proporcionados no serán remitidos a terceros
          ajenos a AXA, salvo que se trate de un tercero encargado del
          tratamiento, conforme a lo indicado en la normativa aplicable, bajo
          condiciones de seguridad y confidencialidad de la información, o
          cuando por mandato de la Ley o resolución judicial, AXA se encuentre
          obligada a comunicar dicha información a entidades públicas,
          autoridades judiciales y/o policiales, y otros terceros legitimados.
        </p>
        <p>
          En cumplimiento de la normativa vigente, AXA ha adoptado las medidas
          técnicas de seguridad y confidencialidad apropiadas con el objetivo de
          evitar la alteración, pérdida, el tratamiento o accesos no autorizados
          que puedan afectar a la integridad, confidencialidad y disponibilidad
          de los datos personales.
        </p>
        <p>
          Como titular de información tiene derecho a conocer, actualizar y
          rectificar sus datos personales y, sólo en los casos en que sea
          procedente, a suprimirlos o revocar la autorización otorgada para su
          tratamiento.
        </p>
        <p>
          Si requiere mayor información para conocer nuestra política de
          tratamiento de datos personales y los cambios sustanciales que se
          produzcan en ella, consulte aquí
          {' '}
          <a href="https://www.axa-asistenciaviaje.com/documents/21611176/0/politicaProteccionTratamientoDatosPersonales+%281%29/b2b29e53-9227-628d-e460-0430b8d5f157">
            POLITICA_PROTECCION_TRATAMIENTO_DATOS_PERSONALES.pdf
          </a>
        </p>
        <p>
          Si desea presentar una consulta, reclamo o petición de información
          relacionada con la protección de datos personales por favor
          Contáctenos a través del correo electrónico
          {' '}
          <a href="mailto:novedades.local@axa-assistance.com.co">
            novedades.local@axa-assistance.com.co
          </a>
        </p>
      </div>
    ),
  },
];

const privacyPolicyMx = [
  {
    id: 1,
    title: '',
    subtitle: 'I. IDENTIDAD Y DOMICILIO DEL RESPONSABLE',
    content: (
      <div>
        <p>
          AXA Assistance México, S.A. de C.V., (AXA ASSISTANCE), con domicilio en Félix
          Cuevas 366, piso 6 Tlacoquemecatl del Valle, Benito Juárez, Ciudad de México,
          Código Postal 03200, es el responsable del uso y protección de sus datos
          personales, y al respecto le informa lo siguient:
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: '',
    subtitle: 'II. DATOS PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO',
    content: (
      <div>
        <p>
          <strong>DATOS PERSONALES:</strong>
        </p>
        <p>1) Datos de identificación, </p>
        <p>2) Datos de contacto, </p>
        <p>3) Financieros y/o patrimoniales, </p>
        <p>4) Datos laborales </p>
        <p>5) Datos académicos </p>
        <p>
          6) En caso de tratarse de Extranjeros: Datos de tránsito, migratorios,
          país de origen y domicilio de país de origen,
        </p>
        <p>
          7) Datos sobre procedimientos administrativos seguidos en forma de
          juicio y/o jurisdiccionales,
        </p>
        <p>
          8) Fotografía, imágenes captadas por cámaras de video vigilancia, en
          caso de acudir a alguna de nuestras sucursales o/y oficinas,
        </p>
        <p>9) Número de Póliza y Certificado, </p>
        <p>10) Datos de geolocalización. </p>
        <p>11) Datos Fiscales como Régimen y domicilio Fiscal. </p>
        <p>
          <strong>DATOS PERSONALES SENSIBLES:</strong>
        </p>
        <p>1) Datos de salud, </p>
        <p>2) Expediente clínico, </p>
        <p>3) Estudios e informes médicos, </p>
        <p>4) origen racial o étnico, creencias religiosas, </p>
        <p>5) Hábitos personales. </p>
        <p>
          Los Datos Personales y Datos Personales Sensibles, recabados o que se
          recaben y/o se generen para los fines de la relación jurídica que se
          tenga celebrada o se celebre serán utilizados únicamente para cumplir
          el objeto de dicha relación jurídica, en total apego con lo dispuesto
          en este Aviso de Privacidad.
        </p>
        <p>
          Los utilizaremos para las siguientes finalidades, que son necesarias
          para el servicio que usted solicita, y que AXA ASSISTANCE brinda.
        </p>
      </div>
    ),
  },
  {
    id: 3,
    title: '',
    subtitle: 'III. ¿CÓMO OBTENEMOS SUS DATOS PERSONALES?',
    content: (
      <div>
        <p>
          AXA ASSISTANCE podrá recabar sus datos de manera directa, por medio de
          nuestra oficina, por cualquier solicitud de contratación de
          asistencias y/o formatos institucionales, recabados por medios físicos
          y/o electrónicos, llamadas telefónicas, video llamadas, correo, página
          web o aplicaciones móviles (apps).
        </p>
        <p>
          También se podrán recabar de manera indirecta, mediante trasferencias
          que se realicen por conducto de personas físicas o morales
          autorizadas, que se hayan obtenido en virtud de haber sido recabados
          con motivo de la operación derivada del contrato de servicios, de la
          relación jurídica que se tenga, o que en su caso se vaya a celebrar.
        </p>
        <p>
          En caso de que los datos personales se refieran a personas físicas
          distintas al contratante, éste garantiza haber recabado y contar con
          el consentimiento previo de los mismos para la comunicación de sus
          datos y haberles informado con carácter previo a la transmisión de sus
          datos, de las finalidades del tratamiento, previstos en el presente
          Aviso de Privacidad.
        </p>
        <p>
          Los datos personales sensibles y financieros que se proporcionen,
          serán tratados y resguardados con un alto estándar de seguridad y
          estricta confidencialidad.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    title: '',
    subtitle: 'IV. FINALIDADES PRIMARIAS DEL TRATAMIENTO DE LOS DATOS',
    content: (
      <div>
        <p>
          Sus datos personales se tratarán para todos los fines vinculados con
          la relación jurídica/contractual que tengamos celebrada o en su caso
          se vaya a celebrar con Usted, a saber:
        </p>
        <p>
          <strong>a) CLIENTES:</strong>
        </p>
        <p>
          Para el caso de personas físicas o morales con quien esté por
          celebrarse o se tenga celebrada una relación contractual:
        </p>
        <ol>
          <li>`Para otorgar servicios de asistencia contratados (Asistencia Vial, Asistencia Legal,
            Asistencia Hogar, Asistencia Médica, Asistencia Funeraria,
            Concierge y Asistencia en Viajes)`
          </li>
          <li>`Para evaluar servicios, selección de riesgos, redacción y celebración de
            contratos.`
          </li>
          <li>`Para tramitar solicitudes de pago de siniestros, administración,
            mantenimiento y renovación de los servicios de asistencia.`
          </li>
          <li>`Para otorgar servicios o productos relacionados con, o mediante la descarga de
            aplicaciones tecnológicas ("Apps") en su (s) dispositivo (s) móvil (es)
            o equipo(s) de cómputo que desarrolle AXA ASSITANCE por sí o por terceros
            autorizados.`
          </li>
          <li>`Para llevar a cabo los fines relacionados con objeto social de AXA ASSISTANCE.</li>
          <li>`Para llevar a cabo el objeto de los contratos celebrados entre usted y AXA
            ASSISTANCE.`
          </li>
          <li>Para combatir el lavado de dinero y el financiamiento del terrorismo.</li>
          <li>Para realizar grabaciones de las llamadas de servicio para fines de calidad.</li>
        </ol>
        <p>
          <strong>b) PROVEEDORES O PRESTADORES DE BIENES Y/O SERVICIOS:</strong>
        </p>
        <p>
          Los datos personales que son recopilados de proveedores, prestadores
          de servicios e intermediarios:
        </p>
        <ol>
          <li>Para identificar y verificar la información del proveedor o prestador del servicio.
          </li>
          <li>`Mantener la seguridad y confidencialidad de los datos personales
            sensibles que el proveedor o prestador de bienes y/o servicios dé a conocer.`
          </li>
          <li>Elaborar expediente administrativo de control interno.</li>
          <li>Para Facturar pagos por la prestación del servicio contratado.</li>
          <li>'Para realizar pagos, sea a través de títulos de crédito o vía
            transferencia electrónica.'
          </li>
          <li>Para clasificar sus datos en la contabilidad de la empresa en los
            rubros de cuentas por pagar y cuentas por cobrar.
          </li>
          <li>Para combatir el lavado de dinero y el financiamiento del terrorismo.
          </li>
        </ol>
        <p>
          <strong>c) ENTIDADES DEL GRUPO AXA</strong>
        </p>
        <p>
          Alguna o la totalidad de las entidades morales que conforman Grupo AXA
          en cualquier parte del Mundo, y los Proveedores que le proveen algún
          bien o servicio en las siguientes materias:
        </p>
        <ol>
          <li>Para la ejecución de uno o más servicios de asistencia en viaje en
            el exterior
          </li>
          <li>Para realizar estudios estadísticos y actuariales</li>
          <li>Para combatir el lavado de dinero y el financiamiento del
            terrorismo.
          </li>
        </ol>
        <p>
          <strong>d) VISITANTES A NUESTRAS OFICINAS</strong>
        </p>
        <p>
          Cualquier persona Física actuando por sí misma y/o sus acompañantes o
          persona que le represente, así como a cualquiera que actúe en nombre
          de otra física o moral determinada o indeterminada.
        </p>
        <ol>
          <li>Para controlar la seguridad dentro de nuestras instalaciones, las
            instalaciones de AXA ASSISTANCE cuentan con bitácoras de registro de
            visitantes y cámaras de video vigilancia por lo que las imágenes y
            datos que se registran y captan son utilizadas para fines de
            identificación y seguridad.
          </li>
          <li>Las imágenes captadas y sonidos grabados serán utilizadas para su
            seguridad y de las personas que nos visitan, el tratamiento de estas
            grabaciones es de uso interno, y se conservarán para fines de
            seguridad, dichas grabaciones se conservarán por un tiempo
            determinado para su posterior borrado.
          </li>
        </ol>
      </div>
    ),
  },
  {
    id: 5,
    title: '',
    subtitle: 'V. FINALIDADES SECUNDARIAS DEL TRATAMIENTO',
    content: (
      <div>
        <p>
          Además de los anteriores, de manera adicional, utilizaremos su
          información personal para las siguientes finalidades que no son
          necesarias para el servicio solicitado, pero que nos permiten y
          facilitan brindarle una mejor atención:
        </p>
        <ol>
          <li>Para fines mercadotécnicos, publicitarios o de prospección
            comercial.
          </li>
          <li>Para la aplicación de encuestas y evaluaciones para mejorar
            la calidad de los productos y servicios que ofrecemos;
          </li>
          <li>Realización de estudios otorgados por los agentes, brokers y proveedores con los
            que AXA Partners tiene una relación jurídica.
          </li>
          <li>4. Para fines estadísticos.</li>
        </ol>
        <p>
          En caso de que usted no desee que sus datos personales sean tratados
          para estos fines secundarios, usted puede manifestar su negativa para
          que sus datos no sean tratados para los fines descritos en el párrafo
          que antecede a través del procedimiento detallado en la fracción VII
          del presente aviso.
        </p>
        <p>
          La negativa para el uso de sus datos personales para estas finalidades
          no podrá ser un motivo para que le neguemos los servicios y productos
          que solicita o contrata con nosotros.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    title: '',
    subtitle: 'VI. TRANSFERENCIAS',
    content: (
      <div>
        <p>
          Los datos personales a que se refiere este aviso podrán ser transferidos a:
        </p>
        <ul style={decimalListStyle}>
          <li>
            Empresas filiales del Grupo AXA, con la finalidad de dar cumplimiento
            al contrato que origino la relación jurídica entre usted y AXA ASSISTANCE.
          </li>
          <li>
            Autoridades mexicanas y extranjeras, con
            la finalidad de dar cumplimiento a obligaciones derivadas de leyes
            o tratados internacionales, obligaciones tributarias, así como para
            el cumplimiento y desahogo de notificaciones o requerimientos ordenados
            por autoridad municipal, estatal o federal o quien tenga la suficiente
            facultad para ello.
          </li>
          <li>
            Instituciones, organizaciones o entidades del sector asegurador,
            para fines de prevención de fraudes y selección de riesgos.
          </li>
          <li>
            Personas físicas o morales que se relacionen de manera directa o
            indirecta con AXA ASSISTANCE y el uso de su información le represente
            a Usted un beneficio en aquellos planes programas, contratos, convenios,
            o similares actos en los cuales usted se encuentre relacionado con la
            primera de manera directa o indirecta.
          </li>
        </ul>
        <p>
          En caso de realizar alguna transferencia de sus datos personales,
          en los que se requiera su consentimiento expreso, se lo informaremos
          a efecto de recabar el mismo.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    title: '',
    subtitle:
      'VII. MECANISMO DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE SUS DATOS PERSONALES.',
    content: (
      <div>
        <p>
          Usted tiene derecho a conocer qué datos personales
          tenemos de usted, para qué los utilizamos y las condiciones
          del uso que les damos (Acceso). Asimismo, es su derecho solicitar
          la corrección de su información personal en caso de que esté
          desactualizada, sea inexacta o incompleta (Rectificación);
          que la eliminemos de nuestros registros o bases de
          datos cuando considere que la misma no está siendo utilizada conforme
          a los principios, deberes y obligaciones previstas en
          la normativa (Cancelación); así como oponerse al uso de sus datos
          personales para fines específicos (Oposición). Estos
          derechos se conocen como derechos ARCO.
        </p>
        <p>
          Para cualquier asunto relacionado con este Aviso, el tratamiento de
          sus datos personales y sus derechos ARCO usted, en calidad de titular
          de sus datos personales, podrá presentar, por sí mismo o a través de
          su representante, la solicitud mediante el formulario que ponemos a su
          disposición o hacerlo mediante el correo electrónico: {' '}
          <a
            href="mailto:protecciondedatos@axa-assistance.com.mx"
            target="_blank"
            rel="noreferrer"
          >
            protecciondedatos@axa-assistance.com.mx.
          </a>
          &nbsp;ante la Dirección Jurídica de AXA quien se encargará de
          atenderla en días y horas hábiles.
        </p>

        <p>
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=zDhrOWWqK0m7Dj2U7SWpe6-uTHdpQSpCs6XnpRRwwGVUME1IOEkyVjQwNEhHNTJYT0xQVEsyMjRQWSQlQCN0PWcu&wdLOR=cD20913C8-3095-434D-B791-AFC16D78A2CF"
            target="_blank"
            rel="noreferrer"
          >
            Formulario para el ejercicio de derechos ARCO
          </a>
          :
        </p>
      </div>
    ),
  },
  {
    id: 8,
    title: '',
    subtitle:
      'VIII. LOS MECANISMOS Y PROCEDIMIENTOS PARA QUE, EN SU CASO, EL TITULAR PUEDA REVOCAR SU CONSENTIMIENTO AL TRATAMIENTO DE SUS DATOS PERSONALES.',
    content: (
      <div>
        <p>
          Usted puede revocar el consentimiento que, en su caso,
          nos haya otorgado para el tratamiento de sus datos personales.
          Sin embargo, es importante que tenga en cuenta que no en todos
          los casos podremos atender su solicitud o concluir el uso de forma
          inmediata, ya que es posible que por alguna obligación legal requiramos
          seguir tratando sus datos personales. Asimismo, usted deberá considerar que,
          para ciertos fines, la revocación de su consentimiento implicará que no le
          podamos seguir prestando el servicio que nos solicitó, o la conclusión de su
          relación con nosotros.
        </p>
        <p>
          Para revocar su consentimiento usted, en calidad de titular de sus
          datos personales, podrá presentar, por sí mismo o a través de su
          representante, , la solicitud mediante el formulario que ponemos a su
          disposición o hacerlo mediante el correo electrónico: {' '}
          <a
            href="mailto:protecciondedatos@axa-assistance.com.mx"
            target="_blank"
            rel="noreferrer"
          >
            protecciondedatos@axa-assistance.com.mx.
          </a>
          &nbsp;ante la Dirección Jurídica de AXA quien se encargará de
          atenderla en días y horas hábiles.
        </p>
        <p>
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=zDhrOWWqK0m7Dj2U7SWpe6-uTHdpQSpCs6XnpRRwwGVUME1IOEkyVjQwNEhHNTJYT0xQVEsyMjRQWSQlQCN0PWcu&wdLOR=cD20913C8-3095-434D-B791-AFC16D78A2CF"
            target="_blank"
            rel="noreferrer"
          >
            Formulario para el ejercicio de derechos ARCO
          </a>
          :
        </p>
      </div>
    ),
  },
  {
    id: 9,
    title: '',
    subtitle:
      'IX. OPCIONES Y MEDIOS QUE EL RESPONSABLE OFREZCA A LOS TITULARES PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.',
    content: (
      <div>
        <p>
          Con objeto de que usted pueda limitar el uso y divulgación
          de su información personal, le notificamos que existen
          los siguientes medios:
        </p>
        <ul style={decimalListStyle}>
          <li>
            Su inscripción en el Registro Público para Evitar
            Publicidad, que está a cargo de la Procuraduría Federal
            del Consumidor, con la finalidad de que sus datos
            personales no sean utilizados para recibir publicidad
            o promociones de empresas de bienes o servicios. Para más
            información sobre este registro, usted puede consultar el portal
            de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.
          </li>
        </ul>
        <p>
          Su registro en el listado de exclusión "LISTADO DE TITULARES CON
          DATOS PERSONALES LIMITADOS'', a fin de que sus datos personales no
          sean tratados para fines mercadotécnicos, publicitarios o de
          prospección comercial por nuestra parte. Para ello, usted, en
          calidad de titular de sus datos personales, podrá presentar, por sí
          mismo o a través de su representante, la solicitud mediante el que
          ponemos a su disposición
          formulario o hacerlo mediante el correo electrónico:{' '}
          <a
            href="mailto:protecciondedatos@axa-assistance.com.mx"
            target="_blank"
            rel="noreferrer"
          >
            protecciondedatos@axa-assistance.com.mx.
          </a>
          &nbsp;ante la Dirección Jurídica de AXA quien se encargará de atenderla en
          días y horas hábiles.
        </p>
        <p>
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=zDhrOWWqK0m7Dj2U7SWpe6-uTHdpQSpCs6XnpRRwwGVUME1IOEkyVjQwNEhHNTJYT0xQVEsyMjRQWSQlQCN0PWcu&wdLOR=cD20913C8-3095-434D-B791-AFC16D78A2CF"
            target="_blank"
            rel="noreferrer"
          >
            Formulario para el ejercicio de derechos ARCO
          </a>
          :
        </p>

      </div>
    ),
  },
  {
    id: 10,
    title: '',
    subtitle:
      'X. PROCEDIMIENTO Y MEDIOS POR EL CUAL SE COMUNICARÁ A LOS TITULARES LOS CAMBIOS EN AVISO DE PRIVACIDAD.',
    content: (
      <div>
        <p>
          El presente aviso de privacidad puede sufrir modificaciones,
          cambios o actualizaciones derivadas de nuevos requerimientos
          legales; de nuestras propias necesidades por los productos
          o servicios que ofrecemos; de nuestras prácticas de privacidad;
          de cambios en nuestro modelo de negocio, o por otras causas.
        </p>
        <p>
          Nos comprometemos a mantenerlo informado sobre los cambios que pueda
          sufrir el presente aviso de privacidad a través
          de la página &nbsp;
          <a href="https://www.axa-assistance.com.mx/">
            www.axa-assistance.com.mx
          </a>
          , o bien a través de comunicados colocados en nuestras
          oficinas, en los cuales se indicará si el aviso de privacidad
          ha sufrido cambios.
        </p>
      </div>
    ),
  },
  {
    id: 11,
    title: '',
    subtitle: 'XI. USO DE COOKIES Y WEB BEACONS:',
    content: (
      <div>
        <p>
          Nuestras páginas de Internet utilizan "cookies" y otra
          clase de tecnología por medio de las que resulta posible
          conocer su comportamiento como usuario navegante de
          Internet, con ello buscamos brindarle un mejor servicio
          y experiencia de usuario al navegar en nuestra página y
          con ello ofrecerle productos y servicios tomando en cuenta
          sus preferencias. Los datos personales que obtenemos de estas
          tecnologías son: horarios y tiempos de navegación en la red y en
          nuestra página de Internet, secciones accedidas
          y con ello, intereses detectados en las mismas.
        </p>
        <p>
          Estas tecnologías podrán deshabilitarse configurando su navegador
          para impedir su utilización por AXA Assistance y por terceros, en caso
          contrario, se entenderá que usted permite el uso de cookies y las
          finalidades de ello descritas en este Aviso de Privacidad
        </p>
      </div>
    ),
  },
  {
    id: 12,
    title: '',
    subtitle: 'XII. AUTORIDAD COMPETENTE',
    content: (
      <div>
        <p>
          Usted tiene derecho a plantear sus inquietudes sobre el tratamiento de sus datos
          personales al Instituto Nacional de Transparencia, Acceso a la Información y
          Protección de Datos Personales («INAI», cuya dirección es Insurgentes Sur No. 3211
          Col. Insurgentes Cuicuilco, Alcaldía Coyoacán, C.P. 04530. Su sitio web es accesible
          aquí:{' '}
          <a
            href="https://home.inai.org.mx/"
            target="_blank"
            rel="noreferrer"
          >
            INAI – Instituto Nacional de Transparencia, Acceso a la Información y Protección
            de Datos Personales
          </a>
          .
        </p>
      </div>
    ),
  },
];

export default {
  [langKm]: privacyPolicyMx,
  [langKc]: privacyPolicyCo,
  [langMx]: privacyPolicyMx,
};
