/* eslint-disable no-param-reassign */
import Prismic from 'prismic-javascript';

export const getQueryOptions = (headers, maxPageSize) => {
  const queryOptions = {};

  if (headers['Accept-Language']) {
    queryOptions.lang = headers['Accept-Language'];
  }

  queryOptions.page = '1';
  queryOptions.pageSize = maxPageSize ? 100 : 20;
  return queryOptions;
};

export const setPredicates = (headers, type) => {
  const predicates = [];
  predicates.push(Prismic.Predicates.at('document.type', type));

  if (headers.category) {
    predicates.push(
      Prismic.Predicates.at(
        'document.tags',
        headers.category.split(',').map((item) => item.trim()),
      ),
    );
  }

  return predicates;
};

export const setOrderings = (queryOptions = {}, type, fieldsWithOrder) => {
  queryOptions.orderings = '[';

  fieldsWithOrder.forEach((fieldOrder) => {
    const { field } = fieldOrder;
    const order = fieldOrder.orderings;

    if (order && order != 'asc') {
      queryOptions.orderings += `my.${type}.${field} ${order}, `;
    } else {
      queryOptions.orderings += `my.${type}.${field}, `;
    }
  });

  queryOptions.orderings += ']';
  return queryOptions;
};
