import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntlContext } from '../../intl';
import * as translations from './intl';

import styles from './CotizarFormStep1.module.scss';
import AsistenciaInformation from './AsistenciaInformation/AsistenciaInformation';
import { submitACotizar } from './submit';
import { checkErrorsOnForm } from './checkErrorsOnForm';
import { getRefs } from './getRefs';
import { LocationContext } from '../Location/Location';
import ButtonLink from '../ButtonLink/ButtonLink';
import Button from '../Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import { validationMissedSchema } from './AsistenciaInformation/EsInformation/validationSchema';
// import { id } from 'date-fns/esm/locale';
import {
  dataLayerTrack,
  dataLayerPageViewEventTrack,
} from '../../utils/GTM_helper';

function CotizarFormStep1({
  banner,
  formType,
  history,
  location,
  setLoading,
  withFaq,
}) {
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const intl = translate(translations);
  const [schemas] = useState({});
  const [values] = useState({});
  const { actions } = useContext(StateContext);
  const submitRefs = getRefs(useRef);
  const accordeonRefs = getRefs(useRef);
  const recaptchaRef = getRefs(useRef);
  const formSubmitRefs = getRefs(useRef);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    // dataLayerPageViewEventTrack(location.pathname, intl.PAGE_NAME_GTM);
  }, []);

  const step1GTM = {
    event: 'enhanced-ecommerce',
    gaEventCategory: 'enhanced ecommerce',
    gaEventAction: 'checkout step',
    gaEventLabel: '1',
    gaEventValue: undefined,
    gaEventNonInteraction: true,
    ecommerce: { checkout: { actionField: { step: 1, action: undefined } } },
  };

  const tagManagerArgsStep1 = {
    dataLayer: { ...step1GTM },
    dataLayerName: 'PageDataLayer',
  };

  let textBtn = intl.BTN_CONTINUE;
  const styleFormContent = styles.formContent;
  let classStyle;
  if (banner) {
    classStyle = styles.adjustInBanner;
    textBtn = intl.BTN_SUBMIT;
  } else classStyle = '';

  const submit = async () => {
    const areFormsOk = await checkErrorsOnForm(
      submitRefs,
      accordeonRefs,
      schemas,
      values,
      recaptchaRef,
    );
    // areFormsOk = true;
    if (areFormsOk) {
      const cotizarProps = {
        formType,
        values,
        setSubmitting: setLoading,
        intl,
        idiom,
        history,
        idiomForApi: idiomAPI,
        actions,
        setNotFound,
      };

      TagManager.dataLayer(tagManagerArgsStep1);

      const submitResult = await submitACotizar(cotizarProps);

      if (!submitResult) {
        setNotFound(true);
      } else {
        actions.step.setStep(2);
        history.push(intl.LINK_FORM_COTIZAR);
      }
    }
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const faqContent = (
    <div className={styles.faqContent}>
      <p className={styles.title}>
        <strong>{intl.TITLE_FAQ}</strong>
      </p>
      <p className={styles.question}>
        <strong>{intl.QUESTION_1}</strong>
      </p>
      <p
        className={styles.answer}
        dangerouslySetInnerHTML={{ __html: intl.ANSWER_1 }}
      />
      <p className={styles.question}>
        <strong>{intl.QUESTION_2}</strong>
      </p>
      <p className={styles.answer}>{intl.ANSWER_2}</p>
      <p className={styles.question}>
        <strong>{intl.QUESTION_3}</strong>
      </p>
      <p className={styles.answer}>{intl.ANSWER_3}</p>
      <ButtonLink
        blue
        value={intl.FAQ_BUTTON}
        weight
        link={intl.LINK_FAQS}
        size={14}
      />
    </div>
  );

  return (
    <div
      className={`${styles.cotizarFillForm} ${banner ? '' : styles.noMaxWidth}`}
      data-testid="CotizarFillForm"
    >
      <div className={`${styleFormContent} ${classStyle}`}>
        {notFound && (
          <div className={`row ${styles.errorArea}`}>
            <div className="col-sm-12 col-12">
              <div className="col-12">
                <label className={styles.errorLabel}>{intl.ERROR_LABEL}</label>
              </div>
            </div>
          </div>
        )}
        <AsistenciaInformation
          banner={banner}
          formType={formType}
          parentValues={values}
          updateParent={updateForm.bind(null, 'Cotizar')}
          incidentSubmitRef={submitRefs.Cotizar}
          recaptchaRef={recaptchaRef.Cotizar}
          formSubmitRefs={formSubmitRefs.Cotizar}
        />
        <div
          className={`${banner ? styles.footerBtnBanner : styles.footerBtn}`}
        >
          <Button
            dataTestid="btnSubmitForm1"
            homeBanner
            type="submit"
            onClick={submit}
            value={(
              <>
                <span>{textBtn}</span>
                {!banner && <FontAwesomeIcon icon="arrow-right" color="#fff" />}
              </>
            )}
            formSubmitRefs={formSubmitRefs.Cotizar}
          />
        </div>
      </div>
      {withFaq && faqContent}
    </div>
  );
}

export default withRouter(CotizarFormStep1);
