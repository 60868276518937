import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AdvertisementsList.module.scss';

function AdvertisementsList(props) {
  const { title, advertisements } = props;

  const list = advertisements.map((advertisement, key) => (
    <div
      className={`col-sm-12 col-md-6 col-lg-4 ${styles.item}`}
      key={`advertisementItem_${advertisement.id}_${key}`}
      data-testid="advertisementItem"
    >
      <div className={styles.imgArea}>
        {/* <Link to={advertisement.link || ''}>
          <img src={advertisement.icon_url} alt="advertisement_" />
        </Link> */}
        <img src={advertisement.icon_url} alt="advertisement_" />
      </div>
      <div className={styles.infoArea}>
        <span className={styles.title}>{advertisement.title}</span>
        <span className={styles.content}>{advertisement.short_content}</span>
      </div>
    </div>
  ));

  return (
    <div className={`row ${styles.wrapAdvertisements} row-noMargin`}>
      <div className={`col-12 ${styles.title}`}>
        <h4>{title}</h4>
      </div>
      <div className="col-12">
        <div className={`row justify-content-center ${styles.advertisements}`}>
          {list}
        </div>
      </div>
    </div>
  );
}

export default AdvertisementsList;
