export const ASISTENCIA = 'ASISTENCIA';
export const BLOG = 'BLOG';
export const CONTACT = 'CONTACT';
export const COTIZAR = 'COTIZAR';
export const FAQ = 'FAQ';
export const HOME = 'HOME';
export const NOSOTROS = 'NOSOTROS';
export const PLANES = 'PLANES';
export const PLANES_DETAIL = 'PLANES_DETAIL';
export const ALL = 'ALL';
export const PROMOTION = 'PROMOTION';
export const FOOTER = 'FOOTER';
export const MENU = 'MENU';
