import React from 'react';

import styles from '../../StaticPageContent.module.scss';

function StaticPageContentItem(props) {
  const {
    value: { title, subtitle, content },
    idiom,
  } = props;

  return (
    <div className={styles.item}>
      <div className={styles.title}>
        <h1>{title}</h1>
        <h4>{subtitle}</h4>
      </div>
      <div className={styles.description}>{content}</div>
    </div>
  );
}

export default StaticPageContentItem;
