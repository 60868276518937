import TagManager from 'react-gtm-module';
import { langFormater } from './langFormater';

export const EVENT_PAGE_VIEW = 'pageview';

const clenupEcommerce = () => {
  const cleanStepEcommerce = {
    dataLayer: { ecommerce: null },
    dataLayerName: 'PageDataLayer',
  };
  TagManager.dataLayer(cleanStepEcommerce);
};

export const dataLayerPageViewEventTrack = (
  pagePath,
  pageTitle,
  event = EVENT_PAGE_VIEW,
) => {
  if (!pagePath.includes('get-a-quote-now')) {
    clenupEcommerce();
  }
  const tagManagerArgs = {
    dataLayer: {
      event,
      pagePath,
      pageTitle,
    },
    dataLayerName: 'PageDataLayer',
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const dataLayerTrack = (
  intl,
  idiom,
  country,
  location,
  customTitle = '',
) => {
  const title = intl.PAGE_NAME_GTM || customTitle;

  const tagManagerArgs = {
    dataLayer: {
      site: 'AXA',
      pagename: title,
      language: langFormater(idiom),
      country: country.toUpperCase(),
      device: 'desktop',
    },
    dataLayerName: 'PageDataLayer',
  };
  TagManager.dataLayer(tagManagerArgs);

  dataLayerPageViewEventTrack(location.pathname, title);
};
