import axios from 'axios';

import benefits from './benefits';
import blogs from './blogs';
import planes from './planes';
import cotizar from './cotizar';
import banner from './banner';
import { config } from '../config';
import contact from './contact';
import phone from './phone';

const API = config.REACT_APP_URL_API_PRISMIC;

export const BenefitService = benefits(axios, API);
export const BlogService = blogs(axios, API);
export const PlanesService = planes(axios, API);
export const CotizarService = cotizar(axios);
export const BannerService = banner(axios, API);
export const ContactService = contact(axios);
export const PhoneService = phone(axios);
