import React, { useContext, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import styles from './Contactanos.module.scss';
import { LocationContext } from '../../components/Location/Location';
import Banner from '../../components/Banner/Banner';
import { CONTACT } from '../../utils/category';
import contactanosContent from './Contactanos_content';
import ContactanosList from './ContactanosList/ContactanosList';
import * as translations from './intl';
import { IntlContext } from '../../intl/index';
import { dataLayerTrack } from '../../utils/GTM_helper';
import { StateContext } from '../../components/StateContextParent/StateContextParent';

function Contactanos({ history, location }) {
  const { idiom, country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const content = contactanosContent[idiom];

  useEffect(() => {
    dataLayerTrack(intl, idiom, country, location);
  }, []);

  return (
    <div className={`${styles.contactanos}`}>
      <Banner category={CONTACT} />
      <ContactanosList idiom={idiom} list={content} />
    </div>
  );
}

export default withRouter(Contactanos);
