import React from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
// import { StateContext } from '../../App';

const siteKey = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY;

// TODO ALERT
function InvisibleRecaptcha(props) {
  const { recaptchaRef, onResolved, onError } = props;
  // const { actions } = React.useContext(StateContext);

  return (
    <Recaptcha
      ref={recaptchaRef}
      sitekey={siteKey}
      onResolved={onResolved}
      style={{ display: 'none' }}
      onError={() => {
        // actions.alert.showError('Recaptcha not loaded');
        onError();
      }}
    />
  );
}

export default InvisibleRecaptcha;
