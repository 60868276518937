export default (axios) => ({
  step1CotizarCreate: async (formType = 'es_kc', data) => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/createCotizar';
    return axios.post(url, dataSubmit);
  },

  step3CotizarEnroll: async (formType = 'es_kc', data) => {
    const dataSubmit = {
      data,
      formType,
    };

    const url = '/enrollCotizar';
    return axios.post(url, dataSubmit);
  },
  step3CotizarPromotionalCode: async (formType = 'es_co', data) => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/cotizarPromotionalCode';
    return axios.post(url, dataSubmit);
  },
  step3CotizarOptionalBenefit: async (formType = 'es_km', data) => {
    const url = '/cotizarOptionalBenefit';
    if (data.QuoteKAYAK) {
      data.QuoteKAYAK.ProductKAYAK = data.QuoteKAYAK.ProductKAYAK.map(
        prod => {
          if (prod.Product) {
            prod.Product.Document = prod.Product.Document.map(doc => ({
              ...doc,
              url: undefined,
            }));
          }
          return prod;
        },
      );
    }

    return axios.post(url, {
      data,
      formType,
    });
  },

  step3RefusePayPalPayment: async (formType = 'es_kc', data) => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/refusePayPalPayment';
    return axios.post(url, dataSubmit);
  },

  step3CotizarCoutasPayment: async (formType = 'es_kc', data) => {
    data.pdf_url = undefined;
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/cotizarCoutasPayment';
    return axios.post(url, dataSubmit);
  },

  validateBin: async (formType = 'es_kc', data) => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/validate/bin';
    return axios.post(url, dataSubmit);
  },

  cotizarBankEnroll: async (formType = 'es_kc', data, Enroll) => {
    const dataSubmit = {
      data,
      formType,
      Enroll,
    };
    const url = '/enrollBank';
    return axios.post(url, dataSubmit);
  },
});
