import Prismic from 'prismic-javascript';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getPhone: async (headers, category) => {
    headers.category = category;
    const result = await getDataFromPrismic(
      headers,
      'contact_phone_cards',
      Prismic.Predicates.gt('my.contact_phone_cards.enable', 0),
    );

    let phone = null;
    if (result && result.length > 0) {
      const { data } = result[0];
      phone = {
        id: data.id,
        phone: data.phone,
        content: data.content,
        icon_master: data.icon_master.url,
        icon_visa: data.icon_visa.url,
        icon_amex: data.icon_amex.url,
      };
    }
    return phone;
  },
});
