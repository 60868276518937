import React, { useState } from 'react';
import classNames from 'classnames';
import ExpandIcon from '../../../assets/img/plus-circle-blue.svg';
import UnExpandIcon from '../../../assets/img/less-circle-blue.svg';
import styles from './QuestionCollapse.module.scss';

function QuestionCollapse({ question }) {
  const [collapsed, setCollapsed] = useState(true);
  const [answerClass, setAnswerClass] = useState(classNames(styles.answer));

  const togglecollapsed = () => {
    setCollapsed(!collapsed);

    if (collapsed) {
      setAnswerClass(classNames(styles.answer, styles.in));
    } else {
      setAnswerClass(classNames(styles.answer));
    }
  };

  return (
    <div className={`col-12 ${styles.item}`}>
      <button
        type="button"
        className={styles.question}
        onClick={togglecollapsed}
      >
        <img className={styles.icon} src={collapsed ? ExpandIcon : UnExpandIcon} />
        {question.question}
      </button>
      <div
        className={answerClass}
        dangerouslySetInnerHTML={{ __html: question.answer }}
      />
    </div>
  );
}

export default QuestionCollapse;
