export const LABEL_NOMBRE = {
  es_kc: 'Nombre *',
  es_km: 'Nombre *',
  es_mx: 'Nombre *',
};

export const LABEL_APELIDO = {
  es_kc: 'Apellidos *',
  es_km: 'Apellidos *',
  es_mx: 'Apellidos *',
};

export const LABEL_EMAIL = {
  es_kc: 'Correo electrónico *',
  es_km: 'Correo electrónico *',
  es_mx: 'Correo electrónico *',
};

export const LABEL_EMAIL_CONFIRM = {
  es_kc: 'Confirmación correo electrónico *',
  es_km: 'Confirmación correo electrónico *',
  es_mx: 'Confirmación correo electrónico *',
};

export const LABEL_MOBILE = {
  es_kc: 'Celular *',
  es_km: 'Celular *',
  es_mx: 'Celular *',
};

export const LABEL_IDENTIFICATION = {
  es_kc: 'Celular *',
  es_km: 'Celular *',
  es_mx: 'Celular *',
};

export const PAYMENT_NOMBRE_REQUIRED = {
  es_kc: 'Debe rellenar el campo "Nombre Completo (Como aparece en tarjeta)"',
  es_km: 'Debe rellenar el campo "Nombre Completo (Como aparece en tarjeta)"',
  es_mx: 'Debe rellenar el campo "Nombre Completo (Como aparece en tarjeta)"',
};

export const POSTAL_CODE_REQUIRED = {
  es_kc: 'Debe rellenar el campo "Codigo Postal"',
  es_km: 'Debe rellenar el campo "Codigo Postal"',
  es_mx: 'Debe rellenar el campo "Codigo Postal"',
};

export const TARJETA_REQUIRED = {
  es_kc: 'Debe rellenar el campo "Número de Tarjeta"',
  es_km: 'Debe rellenar el campo "Número de Tarjeta"',
  es_mx: 'Debe rellenar el campo "Número de Tarjeta"',
};

export const TARJETA_MINLENGTH = {
  es_kc: 'El número de tarjeta debe tener al menos 10 dígitos',
  es_km: 'El número de tarjeta debe tener al menos 10 dígitos',
  es_mx: 'El número de tarjeta debe tener al menos 10 dígitos',
};

export const VENCIMENTO_DATE_REQUIRED = {
  es_kc: 'Debe rellenar el campo "Fecha de vencimiento"',
  es_km: 'Debe rellenar el campo "Fecha de vencimiento"',
  es_mx: 'Debe rellenar el campo "Fecha de vencimiento"',
};

export const CVC_REQUIRED = {
  es_kc: 'Debe rellenar el campo "CVC"',
  es_km: 'Debe rellenar el campo "CVC"',
  es_mx: 'Debe rellenar el campo "CVC"',
};

export const NOMBRE_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Nombre Completo"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Nombre Completo"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Nombre Completo"`,
};

export const APELIDO_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Apellidos"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Apellidos"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Apellidos"`,
};

export const EMAIL_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Correo electrónico"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Correo electrónico"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Correo electrónico"`,
};

export const EMAIL_CONFIRM_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Confirmación correo electrónico"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Confirmación correo electrónico"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Confirmación correo electrónico"`,
};

export const CELULAR_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Celular"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Celular"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Celular"`,
};

export const N_IDENTIFICATION_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "No. Identificación"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "No. Identificación"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "No. Identificación"`,
};

export const DATE_OF_BIRTH_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Fecha de nacimiento"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Fecha de nacimiento"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Fecha de nacimiento"`,
};

export const VALIDATE_EMAIL_VALID = {
  es_kc: 'El formato del Correo electrónico no es válido',
  es_km: 'El formato del Correo electrónico no es válido',
  es_mx: 'El formato del Correo electrónico no es válido',
};

export const EMAIL_NOT_VALID = {
  es_kc: 'Correo electrónico no válido',
  es_km: 'Correo electrónico no válido',
  es_mx: 'Correo electrónico no válido',
};

export const DIRECTION_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Dirección"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Dirección"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Dirección"`,
};

export const CIUDADE_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Ciudad"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Ciudad"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Ciudad"`,
};

export const ZIP_CODE_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Código postal"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Código postal"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Código postal"`,
};

export const STATE_REQUIRED = {
  es_kc: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Estado de residencia"`,
  es_km: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Estado de residencia"`,
  es_mx: (index = 1) => `Viajero ${index}: Debe rellenar el campo "Estado de residencia"`,
};

export const AGE_NOT_ALLOWED = {
  es_km: 'La edad debe ser inferior a 75',
  es_kc: 'La edad debe ser inferior a 75',
  es_mx: 'La edad debe ser inferior a 75',
};
