import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import styles from '../BlogsList.module.scss';

function BlogItem(props) {
  const {
    blog: { title, short_content, icon_url, uid, id, last_publication_date },
    intl,
  } = props;

  const history = useHistory();

  const redirectBlog = () => {
    history.push(`/blog/${uid}`);
  };

  return (
    <div className={`${styles.item}`} data-testid="blogItem">
      <div className={styles.imgArea}>
        <img src={icon_url} alt="blog_" />
      </div>
      <div className={styles.infoArea}>
        <h5 data-testid="title_blogItem" className={styles.btitle}>
          {title}
        </h5>
        <p data-testid="content_blogItem" className={styles.content}>
          {short_content}
        </p>
        <p className={styles.publishDate}>{last_publication_date}</p>
        {/* <Button
          blogsList
          value={intl.BLOG_BUTTON_TEXT}
          onClick={redirectBlog}
        /> */}
        <a
          href={`/blog/${uid}`}
          onClick={redirectBlog}
          className={styles.anchorbtn}
        >
          {intl.BLOG_BUTTON_TEXT}
        </a>
      </div>
    </div>
  );
}

export default BlogItem;
