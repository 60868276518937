import React, { useEffect, useState } from 'react';
import styles from './ModalOptionalBenefits.module.scss';
import checkBenefit from '../../../../assets/img/CheckCircle_iconWhite.svg';
import { trackExceptionMonitor } from '../../../../AppInsightsTracks';
import { submitOptionalBenefits } from './SubmitOptionalBenefit';
import { getIcon } from './matchBenefitIcon';
import { BenefitService } from '../../../../services/index';
import { listUSAMedical } from '../../../../components/CotizarFormStep2/rulesByCountry';

const GOLD = 'GOLD';
const GOLD_PLUS = 'Primary';

function ItemOptionalBenefit(props) {
  const {
    information,
    index,
    objProduct,
    step1,
    step2,
    step3,
    setLoading,
    formDataCotizar,
    idiom,
    changeProduct,
    updateAmount,
    idiomAPI,
    getBenefitsAddList,
    getBenefitsRemoveList,
  } = props;

  const [icon, setIcon] = useState({});

  const {
    description,
    tooltip,
    detailToPlan,
    DetailId,
    Id,
    BenefitCode,
  } = information;

  const { name } = step3.data;
  const { ZipCode } = step1.Quote;

  const isMX = idiom === 'es_mx' || idiom === 'es_km';

  const gold = objProduct.ProductCode === GOLD || objProduct.ProductCode === GOLD_PLUS;

  const {
    amount, detail, selected, rate,
  } = detailToPlan;
  const cost = amount && rate
    ? (amount * rate).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : 0;

  const notRemove = () => {
    if (
      name === 'Gold'
      && BenefitCode === 'PMU'
      && listUSAMedical.includes(ZipCode)
    ) {
      return true;
    }
    return false;
  };

  const addOptionalBenefit = async () => {
    setLoading(true);
    try {
      await submitOptionalBenefits(
        objProduct,
        step1.Quote,
        step2.data.Token,
        getBenefitsAddList(DetailId),
        idiom,
        () => formDataCotizar.setOptionalBenefits(index, true),
        updateAmount,
      );

      // removeAttention();
    } catch (error) {
      const errorObject = JSON.parse(JSON.stringify(error));
      trackExceptionMonitor()(
        'addOptionalBenefit - Step3 - catch',
        error,
        errorObject,
      );
    }
    setLoading(false);
  };
  const removeOptionalBenefit = async () => {
    if (notRemove()) {
      return;
    }
    setLoading(true);
    try {
      await submitOptionalBenefits(
        objProduct,
        step1.Quote,
        step2.data.Token,
        getBenefitsRemoveList(DetailId),
        idiom,
        () => formDataCotizar.setOptionalBenefits(index, false),
        updateAmount,
      );

      // removeAttention();
    } catch (error) {
      console.log('Error, ', error);
    }
    setLoading(false);
  };

  const getIconFromPrismic = async () => {
    try {
      const prismicIcon = await BenefitService.getBenefitByBenefitCode(
        idiomAPI,
        BenefitCode,
      );
      setIcon(prismicIcon);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIconFromPrismic();
  }, []);

  const checkGoldExceptionUS = async () => {
    if (
      objProduct.ProductCode === GOLD
      && listUSAMedical.includes(ZipCode)
      && BenefitCode === 'PMU'
    ) {
      setLoading(true);
      await changeProduct(GOLD, () => formDataCotizar.setOptionalBenefits(index, true));
      setLoading(false);
    }
  };
  useEffect(() => {
    checkGoldExceptionUS();
  }, []);

  return (
    <div
      className={`${styles.item} ${selected && styles.backgroundBlue}`}
      key={`${description}-${amount}-${DetailId}`}
      onClick={selected ? removeOptionalBenefit : addOptionalBenefit}
    >
      <div className={styles.apresentationArea}>
        <div className={styles.imgArea}>
          <img src={icon && icon.icon_url} alt="_" />
        </div>
        <div className={styles.infoArea}>
          <span className={styles.title}>
            <strong>{description}</strong>
          </span>
          <div className={styles.detail}>{detail}</div>
        </div>
      </div>
      <div className={styles.selectionArea}>
        {!selected && isMX && (
          <div className={styles.costArea}>
            <strong>{`$ ${cost} ${step3.data.currency}`}</strong>
          </div>
        )}
        {selected && (
          <div>
            <img src={checkBenefit} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemOptionalBenefit;
