import Prismic from 'prismic-javascript';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getAdvertisements: async (headers) => {
    const result = await getDataFromPrismic(
      headers,
      'advertisement',
      Prismic.Predicates.gt('my.advertisement.enable', 0),
    );

    const advertisements = result.map((advertisement) => ({
      id: advertisement.data.id,
      title: advertisement.data.title,
      short_content: advertisement.data.content,
      icon_url: advertisement.data.icon_url.url,
      link: advertisement.data.link,
    }));
    return advertisements;
  },
});
