import React from 'react';
import MaskedInput from 'react-text-mask';

import styles from './Input.module.scss';
import * as masks from './maks';
import iconTooltip from '../../assets/img/icon-tooltip-blue.svg';
import { safeGet } from '../../utils/object';
import { removeNonNumeric } from '../../utils/stringUtils';

function Input({
  bottomLabel,
  dataTestid,
  defaultValue,
  disabled,
  error,
  errorLeftAlign,
  errorNoMessage,
  formikProps,
  guide,
  label,
  labelFlex,
  mask,
  maskedMoney,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  removeBottonBarColor,
  showMask,
  textTooBig,
  tooltipText,
  touched,
  type,
  value,
  yellowError,
}) {
  if (formikProps) {
    onChange = formikProps.handleChange;
    onBlur = formikProps.handleBlur;
    error = safeGet(formikProps, `errors.${name}`);
    touched = safeGet(formikProps, `touched.${name}`);
    value = safeGet(formikProps, `values.${name}`);
  }
  let className = `form-control ${styles.Input}`;
  let showGuide = false;
  let showMaskValue = false;
  if (errorNoMessage && error && touched) className += ` ${styles.error}`;
  if (guide) showGuide = true;
  if (showMask) showMaskValue = true;

  let errorLabelClassName = `${styles.errorLabel} ${
    errorLeftAlign ? styles.errorLeft : ''
  }`;
  let labelClassName = `${styles.label} ${labelFlex ? styles.labelFlex : ''}`;
  if (textTooBig) labelClassName += `${styles.textTooBig}`;

  if (removeBottonBarColor) className += ` ${styles.notColorful}`;
  if (yellowError) errorLabelClassName += ` ${styles.yellowError}`;
  const formikError = error && '1';
  if (maskedMoney) {
    const onChangeOverride = onChange;
    onChange = e => {
      value = removeNonNumeric(e.target.value);

      if (value.length >= 3) {
        const decimal = value.substr(value.length - 2);
        const integer = value.substring(0, value.length - 2);
        if (integer.length > 8) {
          return;
        }
        value = `${integer}.${decimal}`;
        value = parseFloat(value).toFixed(2);
      }

      if (value.length === 1) {
        value = `0.0${value}`;
      }
      if (value.length === 2) {
        value = `0.${value}`;
      }
      if (parseFloat(value) === 0) {
        value = '';
      }
      e.target.value = value;
      onChangeOverride(e);
    };
  }
  const input = mask ? (
    <MaskedInput
      formik-error={formikError}
      mask={masks[mask]}
      autoComplete="off"
      className={className}
      type={type}
      placeholder={label}
      name={name}
      value={value}
      showMask={showMaskValue}
      guide={showGuide}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      data-testid={dataTestid}
    />
  ) : (
    <input
      formik-error={formikError}
      // mask={masks[props.mask]}
      autoComplete="new-password"
      className={className}
      type={type}
      placeholder={label}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      data-testid={dataTestid}
      maxLength={maxLength}
    />
  );

  const er = error && touched;
  return (
    <div
      className={` ${
        tooltipText ? `${styles.inputGroup} input-group mb-3` : ''
      } ${styles.Input}`}
    >
      {input}
      {tooltipText && (
        <div className="input-group-prepend">
          <span
            data-tooltip
            datatitle={tooltipText}
            className={` ${styles.inputGroupText} ${
              er ? styles.errorGroupText : ''
            } `}
            id="basic-addon1"
          >
            <img src={iconTooltip} alt="" />
          </span>
        </div>
      )}
      {error && touched && !errorNoMessage && (
        <label className={errorLabelClassName}>{error}</label>
      )}
      {bottomLabel && (
        <label
          className={styles.bottomLabel}
          data-testid={`${dataTestid}-bottomLabel`}
        >
          {bottomLabel}
        </label>
      )}
    </div>
  );
}

export default Input;
