export const LINK_PLANS = {
  es_kc: '/planes',
  es_km: '/mis-planes',
  es_mx: '/mis-planes',
};

export const LABEL_PLANS = {
  es_kc: 'Ver tabla de planes',
  es_km: 'Ver tabla de planes',
  es_mx: 'Ver tabla de planes',
};

export const LABEL_PURCHASE = {
  es_kc: 'Comprar',
  es_km: 'Comprar',
  es_mx: 'Comprar',
};

export const LABEL_BENEFITS_INCLUDED = {
  es_kc: 'Beneficios Incluidos',
  es_km: 'Beneficios Incluidos',
  es_mx: 'Beneficios Incluidos',
};

export const LABEL_YOUR_QUOTE = {
  es_kc: 'Tu Cotización',
  es_km: 'Tu Cotización',
  es_mx: 'Tu Cotización',
};

export const TITLE_SECTION_COMPLEMENTARY_BENEFITS = {
  es_kc: 'Beneficios Complementarios',
  es_km: 'Beneficios Complementarios',
  es_mx: 'Beneficios Complementarios',
};

export const TITLE_SECTION_OPTIONAL_BENEFITS = {
  es_kc: 'Beneficios Opcionales',
  es_km: 'Beneficios Opcionales',
  es_mx: 'Beneficios Opcionales',
};

export const TITLE_SECTION_SCHENGEN_ELIGIBLE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/pago',
  es_km: '/cotizar-asistencia/pago',
  es_mx: '/cotizar-asistencia/pago',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Cotizar Asistencia',
  es_km: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
  es_mx: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
};

export const PAGE_NAME_GTM_STEP = {
  es_kc: 'Cotizar Asistencia Step 2',
  es_km: 'Obtenga una cotización ahora Step 2',
  es_mx: 'Obtenga una cotización ahora Step 2',
};

export const CHOOSE_THE_BEST_PLAN_FOR_YOU = {
  es_kc: 'Elige el mejor plan para ti',
  es_km: 'Elige el mejor plan para ti',
  es_mx: 'Elige el mejor plan para ti',
};
