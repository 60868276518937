import { focusOnErrorElement } from '../../utils/elements';

const IGNORE_CAPTCHA = process.env.REACT_APP_IGNORE_CAPTCHA === 'on';

export const checkErrorsOnForm = async (
  submitRefs,
  accordeonRefs,
  schemas,
  values,
  recaptchaRef,
) => {
  // if (!IGNORE_CAPTCHA) recaptchaRef.Cotizar.current.execute();
  submitRefs.Cotizar.current.click();
  try {
    // await schemas.policy.validate(values.policy);
    await schemas.Cotizar.validate(values.Cotizar);
    // if (!IGNORE_CAPTCHA && !values.Cotizar.Quote.recaptcha) {
    //   return false;
    // }

    return true;
  } catch (error) {
    // accordeonRefs.policy.current.click();
    focusOnErrorElement(0);
    return false;
  }
};

export const checkFormErrors = async (schemas, values) => {
  try {
    await schemas.validate(values);

    return true;
  } catch (error) {
    // accordeonRefs.policy.current.click();
    focusOnErrorElement(0);
    return false;
  }
};
