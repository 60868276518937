export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/pago',
  es_km: '/cotizar-asistencia/pago',
  es_mx: '/cotizar-asistencia/pago',
};
export const BTN_SELECT = {
  es_kc: 'Seleccionar',
  es_km: 'Seleccionar',
  es_mx: 'Seleccionar',
};

export const BEST_CHOICE = {
  es_kc: 'Mejor Opción',
  es_km: 'Mejor Opción',
  es_mx: 'Mejor Opción',
};

export const SELECTED_PLAN = {
  es_kc: 'Plan seleccionado',
  es_km: 'Plan seleccionado',
  es_mx: 'Plan seleccionado',
};

export const MAIN_BENEFITS = {
  es_kc: 'Beneficios Principales',
  es_km: 'Beneficios principales',
  es_mx: 'Beneficios principales',
};

export const YELLOW_MAIN_BENEFITS = {
  es_kc: 'Para viajes durante todo el año, con un máximo de 60 días consecutivos por viaje.',
  es_km: 'Para viajes durante todo el año, con un máximo de 60 días consecutivos por viaje.',
  es_mx: 'Para viajes durante todo el año, con un máximo de 60 días consecutivos por viaje.',
};

export const SEE_MORE_BENEFITS = {
  es_kc: 'Ver más Beneficios',
  es_km: 'Ver más Beneficios',
  es_mx: 'Ver más Beneficios',
};

export const TITLE_SECTION_SCHENGEN_ELIGIBLE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const LABEL_PLANS = {
  es_kc: 'Ver tabla de planes',
  es_km: 'Ver tabla de planes',
  es_mx: 'Ver tabla de planes',
};
