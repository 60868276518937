import React, { useContext, useState } from 'react';

import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';
import { LocationContext } from '../../Location/Location';
import Button from '../../Button/Button';
import {
  filterOptionalBenefits,
  formatOptionalBenefits,
} from '../../CotizarFormStep2/formatBenefits';
import { StateContext } from '../../StateContextParent/StateContextParent';
import styles from './PlansBtnSelect.module.scss';
import ModalMultitrip from '../../CotizarFormStep2/ModalMultitrip/ModalMultitrip';

const formatProduct = product => ({
  Id: product.Id,
  name: product.Name,
  PEkey: product.PEkey,
  amount: product.Amount,
  currency: product.Currency,
  rate: product.Rate,
  pdf_url: product.Document ? product.Document[0].url : '',
  Channel: product.Channel,
  optionalBenefits: product.optionalBenefits,
});

const filterPlanMultitrip = dataList => {
  const planMultitrip = dataList.listPlanes.find(planes => planes.Name.includes('Multitrip'));
  return !!planMultitrip;
};

const getProductWithOptionalBenefits = (
  isBenefit,
  listOptional,
  index,
  product,
) => {
  const optionalBenefit = isBenefit
    && listOptional
    && listOptional.map(benefit => formatOptionalBenefits(product, benefit, index));

  const filterOptionalObj = filterOptionalBenefits(optionalBenefit);
  product.optionalBenefits = filterOptionalObj;

  return product;
};

const step2GTM = {
  event: 'enhanced-ecommerce',
  gaEventCategory: 'enhanced ecommerce',
  gaEventAction: 'checkout step',
  gaEventLabel: '2',
  gaEventValue: undefined,
  gaEventNonInteraction: true,
  ecommerce: { checkout: { actionField: { step: 2, action: undefined } } },
};

const tagManagerArgsStep2 = {
  dataLayer: { ...step2GTM },
  dataLayerName: 'PageDataLayer',
};

function PlansBtnSelect({
  recommended,
  optionalBenefits,
  product,
  productIndex,
  history,
  intl,
  dataList,
}) {
  const { idiom, country } = useContext(LocationContext);
  const { actions } = useContext(StateContext);
  const { Name, Amount, Currency } = product;
  const isBenefit = !!Amount;

  const selectPlan = async () => {
    const productWithOptionalBenefits = getProductWithOptionalBenefits(
      isBenefit,
      optionalBenefits,
      productIndex,
      product,
    );

    const formatProductToSendForm3 = formatProduct(productWithOptionalBenefits);

    await actions.formDataCotizar.setForm3({
      data: formatProductToSendForm3,
      formatedStep2Info: { productIndex, dataList },
    });
    TagManager.dataLayer(tagManagerArgsStep2);
    actions.step.setStep(3);
    history.push(intl.LINK_FORM_COTIZAR);
  };

  const getIndexMultitrip = () => dataList.listPlanes.findIndex(planes => planes.Name === 'Multitrip');

  const selectCurrentOrMultitripPlan = async plan => {
    const checkPlan = plan === 'Multitrip';
    const index = checkPlan ? getIndexMultitrip() : productIndex;
    const getProduct = checkPlan ? dataList.listPlanes[index] : product;
    const checkIsBenefit = !!getProduct.Amount;
    const productiWithOptional = getProductWithOptionalBenefits(
      checkIsBenefit,
      optionalBenefits,
      index,
      getProduct,
    );
    const formatProductToSend = formatProduct(productiWithOptional);

    await actions.formDataCotizar.setForm3({
      data: formatProductToSend,
      formatedStep2Info: { productIndex: index, dataList },
    });
    actions.step.setStep(3);
    history.push(intl.LINK_FORM_COTIZAR);
  };

  return (
    <div className={styles.planBtnSelect}>
      {recommended ? (
        <Button
          onClick={selectPlan}
          css={styles.btnSelect}
          blue
          value={intl.BTN_SELECT}
          link=""
        />
      ) : (
        <Button
          onClick={selectPlan}
          css={`
              ${styles.btnSelect} ${styles.btnNotRecommended}
            `}
          transparent
          value={intl.BTN_SELECT}
          link=""
        />
      )}
    </div>
  );
}

export default withRouter(PlansBtnSelect);
