import { CotizarService } from '../../../../services/index';
import { formatQuoteToApi } from '../../../../components/CotizarFormStep1/submit/quote';

export const submitPromotionalCode = async (
  ProductSelected,
  idiom,
  cupon,
  setCupon,
  Quote,
  updatePromotionInState,
  setCuponError,
) => {
  const quote = { ...Quote };
  quote.PromotionalCode = cupon;
  const objFormat = formatQuoteToApi({
    formType: idiom,
    values: { Cotizar: { Quote: { ...quote } } },
  });
  let response = null;
  try {
    response = await CotizarService.step3CotizarPromotionalCode(
      idiom,
      objFormat,
    );
    const filterProduct = response.data.QuoteKAYAK.ProductKAYAK.filter(
      product => product.Product.Id === ProductSelected.Id,
    );
    if (ProductSelected.amount !== filterProduct[0].Product.Amount) {
      const diffPromotion = ProductSelected.amount - filterProduct[0].Product.Amount;
      const formatdiffPromotion = Number(diffPromotion.toFixed(2));
      updatePromotionInState(cupon, formatdiffPromotion);
    } else {
      setCupon('');
      setCuponError('INVALID_CUPON');
    }
  } catch (error) {
    console.log(error);
  }
};
