import moment from 'moment';
import { getCurrencyByFormType } from '../../../../../../../utils/currencyISOCode';
import {
  formatThousandsWithoutDecimal,
  formatWithoutDecimal,
} from '../../../../../../../utils/numberUtils';

export const formatEs = (
  product,
  quote,
  token,
  benefitsDetailId,
  idiom,
  indexPerson,
  newDate,
  newAge,
) => {
  const Quote = { ...quote };
  const Product = { ...product.Product };

  // format Quote
  Quote.TripInitialDate = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
  Quote.TripEndDate = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  Quote.IncludesEurope = null;
  Quote.Age = quote.Ages.map((age, index) => {
    if (index === indexPerson) {
      quote.Ages[index].value = newAge;
      return Number(newAge);
    }
    quote.Ages[index].value = age.value;
    return Number(age.value);
  });
  delete Quote.Ages;
  Quote.Sort = 2;
  Quote.Product = null;
  Quote.AmountCurrencyISOCode = getCurrencyByFormType(idiom);
  Quote.Annual = false;
  Quote.Top = 10;
  Quote.PromotionalCode = null;
  delete Quote.numberTravelersInput;

  // format Product
  Product.paymentCashEnabled = null;
  Product.amountValueFormatter = formatThousandsWithoutDecimal(
    product.Product.Amount,
  );
  Product.amountValue = formatWithoutDecimal(product.Product.Amount);
  Product.Channel.paymentCash = true;
  Product.Benefit = null;
  Product.PromotionAmountOnLocalCurrency = '0.00';
  Product.PromotionAmountOnRequestedCurrency = '0.00';
  Product.channel = { ...Product.Channel };

  const objComplete = {
    Login: null,
    Quote: null,
    Enroll: null,
    Reserve: null,
    Country: null,
    StateFilter: null,
    State: null,
    PolicyFilter: null,
    EmailRequest: null,
    CancellationRequest: null,
    Token: token,
    Error: null,
    Status: 0,
    Action: 15,
    QuoteKAYAK: {
      Quote,
      ProductKAYAK: [
        {
          Product,
          ProductCode: null,
          BenefitKAYAK: null,
          Document: null,
        },
      ],
      OptionalBenefitIds: [...benefitsDetailId],
    },
    EnrollKAYAK: null,
  };

  return objComplete;
};
