import moment from 'moment';
import { getCurrencyByFormType } from '../../../../../utils/currencyISOCode';
import {
  formatThousandsWithoutDecimal,
  formatWithoutDecimal,
} from '../../../../../utils/numberUtils';
import { formatDateOfBirthByAge } from '../../../../../utils/date';
import { langMx, langKm } from '../../../../../location/urls';

const formatEs = (product, quote, token, benefitsDetailId, idiom) => {
  const Quote = { ...quote };
  const Product = { ...product.Product };

  // format Quote
  Quote.TripInitialDate = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
  Quote.TripEndDate = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  Quote.IncludesEurope = null;
  Quote.Age = Quote.Ages.map(age => Number(age.value));
  delete Quote.Ages;
  Quote.Sort = 2;
  Quote.Product = null;
  Quote.AmountCurrencyISOCode = getCurrencyByFormType(idiom);
  Quote.Top = 100;
  delete Quote.numberTravelersInput;
  const tripType = Quote.TripType;
  const typeOfPlan = Quote.TypeOfPlan;
  const email = Quote.Email;
  delete Quote.TripType;
  delete Quote.TypeOfPlan;
  delete Quote.Email;
  // format Product
  Product.paymentCashEnabled = null;
  Product.amountValueFormatter = formatThousandsWithoutDecimal(
    product.Product.Amount,
  );
  Product.amountValue = formatWithoutDecimal(product.Product.Amount);
  Product.Channel.paymentCash = true;
  Product.Benefit = null;
  Product.PromotionAmountOnLocalCurrency = '0.00';
  Product.PromotionAmountOnRequestedCurrency = '0.00';
  Product.channel = { ...Product.Channel };

  const objComplete = {
    Login: null,
    Quote: null,
    Enroll: null,
    Reserve: null,
    Country: null,
    StateFilter: null,
    State: null,
    PolicyFilter: null,
    EmailRequest: null,
    CancellationRequest: null,
    Token: token,
    Error: null,
    Status: 0,
    Action: process.env.REACT_APP_CREATE_COTIZAR_ACTION,
    QuoteKAYAK: {
      Quote,
      ExpirationName: tripType,
      IsFamily: typeOfPlan === 'Familiar' ? 'true' : 'false',
      Email: email,
      ProductKAYAK: [
        {
          Product,
          ProductCode: null,
          BenefitKAYAK: null,
          Document: null,
        },
      ],
      OptionalBenefitIds: [...benefitsDetailId],
    },
    EnrollKAYAK: null,
  };

  return objComplete;
};

export const format = (product, quote, token, benefitsDetailId, idiom) => (idiom === langMx
|| idiom === langKm
  ? formatEs(product, quote, token, benefitsDetailId, idiom)
  : undefined);
