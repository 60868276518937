import { langKc } from '../location/urls';

export const langFormater = (idiom) => {
  let result = '';
  switch (idiom) {
    case langKc:
      result = 'es_CO';
      break;

    default:
      result = 'es_MX';
      break;
  }
  return result;
};
