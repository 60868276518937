import moment from 'moment';
import {
  COMIENZO_TARJETA_VISA,
  VISA_0,
  MASTERCARD_1,
  COMIENZO_TARJETA_MASTERCARD,
  COMIENZO_TARJETA_AMEX_1,
  COMIENZO_TARJETA_AMEX_2,
  AMEX_2,
} from '../constantsSubmit';
import { PAYPAL } from '../../constants';

export const formatCredCardToAPI = (props) => {
  const { Cotizar } = props.values;
  const { payment } = Cotizar;
  const {
    cvc,
    date,
    nombre,
    number,
    tarjeta,
    paypalPaymentId,
    installments,
  } = payment;

  // if targeta installments = 1
  // if paypal installments = 0
  const object = {
    CardType: 0,
    Number: null,
    CVC: null,
    CardHolder: null,
    ExpMonth: null,
    ExpYear: null,
    Installments: 0,
    Encrypted: false,
  };

  if (paypalPaymentId !== null && tarjeta === PAYPAL) {
    return { ...object };
  }

  const startYear = '20';
  const dateSplit = date.split('/');
  const month = dateSplit[0];
  const year = dateSplit[1];
  const yearFormated = startYear + year;

  object.CardType = getCardtype(number);
  object.Number = number;
  object.CVC = cvc;
  object.CardHolder = nombre;
  object.ExpMonth = month;
  object.ExpYear = yearFormated;
  object.Installments = parseInt(installments, 10);
  object.Encrypted = false;

  return { ...object };
};

export const formatCredCardToAPIUS = (props) => {
  const { Cotizar } = props.values;
  const { payment } = Cotizar;
  const {
    full_name, number, date, cvc, tarjeta, paypalPaymentId,
  } = payment;

  // if targeta installments = 1
  // if paypal installments = 0
  const object = {
    CardType: 0,
    Number: null,
    CVC: null,
    CardHolder: null,
    ExpMonth: null,
    ExpYear: null,
    Installments: 0,
    Encrypted: false,
  };

  if (paypalPaymentId !== null && tarjeta === PAYPAL) {
    return { ...object };
  }

  const startYear = '20';
  const dateSplit = date.split('/');
  const month = dateSplit[0];
  const year = dateSplit[1];
  const yearFormated = startYear + year;

  object.CardType = getCardtype(number);
  object.Number = number;
  object.CVC = cvc;
  object.CardHolder = full_name;
  object.ExpMonth = month;
  object.ExpYear = yearFormated;
  object.Installments = 1;
  object.Encrypted = false;

  return { ...object };
};

export const getCardtype = (number) => {
  let cardType = 0;

  if (number.startsWith(COMIENZO_TARJETA_VISA)) {
    cardType = VISA_0;
  } else if (number.startsWith(COMIENZO_TARJETA_MASTERCARD)) {
    cardType = MASTERCARD_1;
  } else if (
    number.startsWith(COMIENZO_TARJETA_AMEX_1)
    || number.startsWith(COMIENZO_TARJETA_AMEX_2)
  ) {
    cardType = AMEX_2;
  } else {
    cardType = 0;
  }

  return cardType;
};
