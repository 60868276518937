import moment from 'moment';
import { formatMPHToApi } from './format/mph';
import { formatPassengerToAPI } from './format/passenger';
import { formatCredCardToAPI, formatCredCardToAPIUS } from './format/credcard';
import {
  CREDIT_2,
  ORDER_REF,
  BUYING_REASON,
  EMPTY_STRING,
} from './constantsSubmit';
import { getCurrencyByFormType } from '../../../../utils/currencyISOCode';
import { getCountryIsoCodeByFormType } from '../../../../utils/countryISOCode';
import { formatEmailRequest } from './format/emailRequest';
import { langKc } from '../../../../location/urls';
import { PAYPAL } from '../constants';

const formatDataEs = (props, Quote, data) => {
  const {
    formType, recibirNoticias, values, optionalBenefits, operatorName,
  } = props;
  const {
    AmountCurrencyISOCode,
    DestinationCountryIsoCode,
    TripEndDate,
    TripInitialDate,
  } = Quote;
  const { payment } = values.Cotizar;
  const ProductId = data.Id;
  const { PEkey } = data;
  const { recaptcha } = values.Cotizar.recaptcha;
  const MPH = formatMPHToApi(props, Quote);
  const Passenger = formatPassengerToAPI(props, Quote);
  const CreditCard = formatCredCardToAPI(props);
  const EmailRequest = formatEmailRequest(props);
  const PaymentType = CREDIT_2;
  const OrderRef = ORDER_REF;
  const SendPolicyByMail = true;
  const BuyingReason = BUYING_REASON;
  const StateOfResidence = AmountCurrencyISOCode;
  const amountCurrencyISOCode = getCurrencyByFormType(formType);
  const StartDate = moment(TripInitialDate).format('YYYY-MM-DD');
  const EndDate = moment(TripEndDate).format('YYYY-MM-DD');
  const CountryOfResidenceIsoCode = getCountryIsoCodeByFormType(formType);
  const ReceiveNewsletter = recibirNoticias;
  const AgencyproducerID = EMPTY_STRING;

  const optionalBenefitsSelected = optionalBenefits.filter(
    benefit => benefit.detailToPlan.selected && benefit.detailToPlan.selected === true,
  );
  const OptionalBenefitIds = optionalBenefitsSelected.length > 0
    ? optionalBenefitsSelected.map(benefit => String(benefit.DetailId))
    : null;

  let PayPalPaymentId = null;
  const PromotionalCode = data.promotionalCode ? data.promotionalCode : null;
  let SelectedPaymentGateway = 1;
  // coment from Liferay - Para colombia cambiamos el 1 por 5
  if (payment.paypalPaymentId !== null && payment.tarjeta === PAYPAL) {
    SelectedPaymentGateway = 7;
    PayPalPaymentId = payment.paypalPaymentId;
  } else if (formType === langKc) {
    SelectedPaymentGateway = 5;
  }

  const Enroll = {
    ProductId,
    PEkey,
    MPH,
    Passenger,
    CreditCard,
    PaymentType,
    CountryOfResidenceIsoCode,
    StateOfResidence,
    DestinationCountryIsoCode,
    AmountCurrencyISOCode: amountCurrencyISOCode,
    BuyingReason,
    StartDate,
    EndDate,
    SendPolicyByMail,
    OrderRef,
    EmailRequest,
    SelectedPaymentGateway,
    PromotionalCode,
    ReceiveNewsletter,
    AgencyproducerID,
    PayPalPaymentId,
    OptionalBenefitIds,
    operatorName,
  };
  return { Enroll, recaptcha };
};

const formatDataEn = (props, Quote, data) => {
  const { formType, values, recibirNoticias } = props;
  const { Cotizar } = values;
  const {
    DepartureDate,
    ReturnDate,
    DepositDate,
    ZipCode,
    TotalTripCost,
    MinorTrip,
  } = Quote;

  const { recaptcha } = values.Cotizar.recaptcha;
  const { payment } = values.Cotizar;
  let PayPalPaymentId = null;

  const Enroll = {
    ProductId: data.Id,
    MPH: null,
    Passenger: null,
    CreditCard: formatCredCardToAPIUS(props),
    PaymentType: CREDIT_2,
    CountryOfResidenceIsoCode: getCountryIsoCodeByFormType(formType),
    StateOfResidence: '',
    DestinationCountryIsoCode: Cotizar.trip_destination,
    AmountCurrencyIsoCode: getCurrencyByFormType(formType),
    BuyingReason: null,
    StartDate: moment(DepartureDate).format('YYYY-MM-DD'),
    EndDate: moment(ReturnDate).format('YYYY-MM-DD'),
    SendPolicyByMail: true,
    OrderRef: null,
    EmailRequest: {
      SendPDFpolicy: true,
      SendEachPolicyInSeparateEmail: false,
      SendEmailTo: 1,
      ReserveId: null,
      ServiceId: null,
    },
    SelectedPaymentGateway: null,
    PromotionalCode: null,
    ReceiveNewsletter: null,
  };
  const mph = Cotizar.travellers[0];
  const { newAddress } = Cotizar;

  const addressPostal = {
    Address: {
      Street: mph.address,
      Number: null,
      Complement: null,
      Neighborhood: null,
      City: mph.city,
      State: ZipCode,
      Country: 'United States',
      ZipCode: mph.zip_code,
      InteriorNumber: null,
    },
    AddressType: '1',
  };
  const addressFiscal = {
    Address: newAddress.paymentAddressSameOfPolicyHolder
      ? addressPostal.Address
      : {
        Street: newAddress.address,
        Number: null,
        Complement: null,
        Neighborhood: null,
        City: newAddress.city,
        State: newAddress.state_of_residence,
        Country: 'United States',
        ZipCode: newAddress.zip_code,
        InteriorNumber: null,
      },
    AddressType: '0',
  };
  const MPHUS = {
    MPH: {
      LastName: mph.last_name,
      MotherLastName: null,
      FiscalIdentityCode: null,
      Name: mph.first_name,
      MiddleName: mph.middle_name,
      IdentificationType: 'CI',
      Identification: 'MPHIdentity',
      Nationality: null,
      Profession: null,
      DateOfBirth: moment(mph.birth_date).format('MM/DD/YYYY'),
      Gender: 0,
      Telephone: mph.phone_number,
      Mobile: mph.phone_number,
      Email: mph.email,
      Address: null,
      NombreRazonSocial: null,
    },
    AddressFP: [addressPostal, addressFiscal],
  };

  const PassengerUS = Cotizar.travellers.map((traveller, index) => ({
    Passenger: {
      LastName: traveller.last_name,
      MotherLastName: null,
      Name: traveller.first_name,
      MiddleName: traveller.middle_name,
      IdentificationType: 'CI',
      Identification: 'MPHIdentity',
      FiscalIdentityCode: null,
      Nationality: null,
      Profession: null,
      DateOfBirth: moment(traveller.birth_date).format('MM/DD/YYYY'),
      Address: { ...addressPostal.Address },
      Gender: 0,
      Kinship: null,
      PoliticallyExposed: null,
      ExercisedRelevantPublicOffice: null,
      EmergencyContactName: null,
      Telephone: null,
      Mobile: null,
      EmergencyContactTelephone: null,
      IsMPH: index === 0,
    },
    Title: '',
    IsTraveler: !(index === 0 && traveller.minorTrip),
  }));

  const TripDetail = {
    TripAmount: TotalTripCost,
    DestinationState: null,
    StartDepositDate: moment(DepositDate).format('YYYY-MM-DD'),
  };

  const TripInformation = {
    Airline: '',
    CruiseLine: '',
    TourOperator: '',
    CarRental: '',
  };

  if (payment.paypalPaymentId !== null && payment.tarjeta === PAYPAL) {
    Enroll.SelectedPaymentGateway = 7;
    PayPalPaymentId = payment.paypalPaymentId;
  }

  const optionalBenefitsSelected = data.optionalBenefits.filter(
    benefit => benefit.detailToPlan.selected === true,
  );
  const OptionalBenefitIds = optionalBenefitsSelected.length > 0
    ? optionalBenefitsSelected.map(benefit => benefit.DetailId)
    : null;

  return {
    EnrollData: {
      Enroll,
      MPHUS,
      PassengerUS,
      JustToMainPolicyHolder: false,
      TripDetail,
      TripInformation,
      OptionalBenefitIds,
      MPHTitle: 'Mr',
      IsMPHTraveler: !MinorTrip,
      TravelAgencyID: '',
      ReceiveNewsletter: recibirNoticias,
      PayPalPaymentId,
    },
    recaptcha,
  };
};

export const formatData = props => {
  const { formType, utils } = props;
  const { Quote } = utils.getFormDataStep1();
  const { data } = utils.getFormDataStep3();
  return formatDataEs(props, Quote, data);
};
