export const TEXT_TITLE_POLICY = {
  es_kc: 'Declaraciones de privacidad y seguridad',
  es_km: 'AVISO DE PRIVACIDAD AXA ASSISTANCE MEXICO S.A. DE C.V.',
  es_mx: 'AVISO DE PRIVACIDAD AXA ASSISTANCE MEXICO S.A. DE C.V.',
};
export const PAGE_NAME_GTM = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};
