export const TITLE_STEP1 = {
  es_kc: 'Cotizar Asistencia',
  es_km: 'Cotizar Asistencia',
  es_mx: 'Cotizar Asistencia',
};

export const TITLE_STEP2 = {
  es_kc: 'Seleccione Su Plan',
  es_km: 'Seleccione Su Plan',
  es_mx: 'Seleccione Su Plan',
};

export const TITLE_STEP3 = {
  es_kc: 'Pago',
  es_km: 'Pago',
  es_mx: 'Pago',
};

export const BTN_EDIT_TRIP_DETAILS = {
  es_kc: 'DATOS DE VIAJE',
  es_km: 'DATOS DE VIAJE',
  es_mx: 'DATOS DE VIAJE',
};

export const BTN_EDIT_PLAN = {
  es_kc: 'EDITAR PLAN',
  es_km: 'EDITAR PLAN',
  es_mx: 'EDITAR PLAN',
};
