import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import styles from './NavOption.module.scss';

function NavOption(props) {
  const {
    value,
    link,
    icon,
    dataTestId,
    size = 11,
    id,
    inlineStyle,
    setNavMobile,
  } = props;

  const style = inlineStyle ? { ...inlineStyle } : {};

  const getStyle = () => {
    style.fontSize = size;

    return style;
  };

  const imgIcon = icon ? (
    <img className={styles.imgIcon} src={icon} alt="icon" />
  ) : (
    <></>
  );

  return (
    <li
      className={`nav-item nav-link ${styles.navOption}`}
      style={getStyle()}
      key={id}
      onClick={setNavMobile ? () => setNavMobile(false) : null}
    >
      {link ? (
        <a className={styles.navItem} data-testid={dataTestId} href={link}>
          {imgIcon}
          {value}
        </a>
      ) : (
        <p data-testid={dataTestId}>
          {imgIcon}
          {value}
        </p>
      )}
    </li>
  );
}

export default NavOption;
