import React, { useContext, useState } from 'react';
import RadioButton from '../../../../RadioButton/RadioButton';
import styles from './TripType.module.scss';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';

function TripType(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    banner,
  } = props;

  return (
    <div className={`col-12 ${styles.tripType}`}>
      <div className="form-check form-check-inline">
        <RadioButton
          name="Quote.TripType"
          value={intl.TRIP_VALUE_SINGLE}
          checked={intl.TRIP_VALUE_SINGLE === values.Quote.TripType}
          text={intl.TRIP_NAME_SINGLE}
          onChange={handleChange}
        />
      </div>
      <div className="form-check form-check-inline">
        <RadioButton
          name="Quote.TripType"
          value={intl.TRIP_VALUE_MULTI}
          text={intl.TRIP_NAME_MULTI}
          checked={intl.TRIP_VALUE_MULTI === values.Quote.TripType}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default TripType;
