export const EMPTY_STRING = '';
export const MPH_DEFAULT_FISCAL_IDENTITY_CODE = '0000000000';
export const RFC = 'RFC';
export const CPF = 'CPF';
export const DNI = 'DNI';
export const MPH_DEFAULT_NATIONALITY_COLOMBIA = 'Colombia';
export const MPH_DEFAULT_NATIONALITY_ARGENTINA = 'ARGENTINA';
export const MPH_DEFAULT_NATIONALITY_MEXICANA = 'Mexicana';
export const DEFAULT_COUNTRY_MEXICO = 'México';
export const MALE = 0;
export const FEMALE = 1;
export const CAMPO_STATIC = '';
export const CAMPO_STATIC2 = '--';
export const PASSENGER_DEFAULT_EMERGENCY_CONTACT_TELEPHONE = '0000000000';

export const COMIENZO_TARJETA_VISA = '4';
export const COMIENZO_TARJETA_MASTERCARD = '5';
export const COMIENZO_TARJETA_AMEX_1 = '34';
export const COMIENZO_TARJETA_AMEX_2 = '37';
export const COMIENZO_TARJETA_DISCOVER = '6';

export const VISA_0 = 0;
export const MASTERCARD_1 = 1;
export const AMEX_2 = 2;
export const DINERS_CLUB_3 = 3;
export const ELO_4 = 4;

export const CASH_0 = 0;
export const DEBIT_1 = 1;
export const CREDIT_2 = 2;
export const ORDER_REF = 'OrderRef';

export const BUYING_REASON = 'Compra';

export const SEND_EMAIL_TO_BILLING_0 = 0;
export const SEND_EMAIL_TO_MPH_1 = 1;
export const SEND_EMAIL_TO_PASSENGER_2 = 2;
