export const TITLE_STEP3 = {
  es_kc: 'Información de los Viajeros',
  es_km: 'Información de los viajeros',
  es_mx: 'Información de los viajeros',
};

export const TITLE_VIAJE = {
  es_kc: 'INFO DEL VIAJE',
  es_km: 'INFO DEL VIAJE',
  es_mx: 'INFO DEL VIAJE',
};

export const TITLE_PLAN = {
  es_kc: 'PLAN',
  es_km: 'PLAN',
  es_mx: 'PLAN',
};

export const COUNT_VIAJEIROS = {
  es_kc: (count = '') => `${count} Viajero`,
  es_km: (count = '') => `${count} Viajero`,
  es_mx: (count = '') => `${count} Viajero`,
};

export const BTN_RETIRAR_CUPON = {
  es_kc: 'Retirar cupón',
  es_km: 'Retirar cupón',
  es_mx: 'Retirar cupón',
};

export const BTN_CANJEAR_CUPON = {
  es_kc: 'Canjear',
  es_km: 'Canjear',
  es_mx: 'Canjear',
};

export const LABEL_CUPON_TITLE = {
  es_kc: 'Ingresar cupón de descuento',
  es_km: 'Ingresar cupón de descuento',
  es_mx: 'Ingresar cupón de descuento',
};

export const TOTAL_POLIZA = {
  es_kc: 'Total póliza',
  es_km: 'Total póliza',
  es_mx: 'Total póliza',
};

export const DESCUENTO_APLICADO = {
  es_kc: 'Descuento aplicado',
  es_km: 'Descuento aplicado',
  es_mx: 'Descuento aplicado',
};

export const TOTAL_APLICADO = {
  es_kc: 'TOTAL A PAGAR',
  es_km: 'Total a pagar',
  es_mx: 'Total a pagar',
};

export const TOTAL_TRIP = {
  es_kc: 'Total Trip Cost',
  es_km: 'Total Trip Cost',
  es_mx: 'Total Trip Cost',
};

export const YOUR_AGE = {
  es_kc: 'Your Age',
  es_km: 'Your Age',
  es_mx: 'Your Age',
};

export const TRAVELER_AGE = {
  es_kc: (index) => `Traveler #${index} Age`,
  es_km: (index) => `Traveler #${index} Age`,
  es_mx: (index) => `Traveler #${index} Age`,
};

export const REFUNDED_ERROR1 = {
  es_kc: 'Error al procesar Su pago ha sido reembolsado',
  es_km: 'Error al procesar Su pago ha sido reembolsado',
  es_mx: 'Error al procesar Su pago ha sido reembolsado',
};

export const REFUNDED_ERROR2 = {
  es_kc: (value = '') => `Error al procesar Su pago no puede ser reembolsado. Póngase en contacto con nuestra central e informe la identificación de pago: ${value}`,
  es_km: (value = '') => `Error al procesar Su pago no puede ser reembolsado. Póngase en contacto con nuestra central e informe la identificación de pago: ${value}`,
  es_mx: (value = '') => `Error al procesar Su pago no puede ser reembolsado. Póngase en contacto con nuestra central e informe la identificación de pago: ${value}`,
};

export const GENERIC_ERROR = {
  es_kc: 'Error al procesar',
  es_km: 'Error al procesar',
  es_mx: 'Error al procesar',
};

export const CUSTOM_ERROR_MSG = {
  es_kc: 'Error al procesar',
  es_km: 'Error al procesar',
  es_mx: 'Error al procesar',
};

export const LABEL_OPTIONAL_BENEFITS_SELECTED = {
  es_kc: '',
  es_km: 'Este precio incluye los siguientes beneficios añadidos:',
  es_mx: 'Este precio incluye los siguientes beneficios añadidos:',
};

export const OPTIONAL_BENEFITS = {
  es_kc: '',
  es_km: 'Beneficios adicionales',
  es_mx: 'Beneficios adicionales',
};

export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/gracias',
  es_km: '/cotizar-asistencia/gracias',
  es_mx: '/cotizar-asistencia/gracias',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Cotizar Asistencia',
  es_km: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
  es_mx: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
};

export const PAGE_NAME_GTM_STEP = {
  es_kc: 'Cotizar Asistencia Step 3',
  es_km: 'Obtenga una cotización ahora Step 3',
  es_mx: 'Obtenga una cotización ahora Step 3',
};

export const INVALID_CUPON = {
  es_kc: 'Cupón de descuento no válido.',
  es_km: 'Cupón de descuento no válido.',
  es_mx: 'Cupón de descuento no válido.',
};

export const LABEL_ORIGEN = {
  es_kc: 'Origen:',
  es_km: 'Origen:',
  es_mx: 'Origen:',
};

export const LABEL_DESTINO = {
  es_kc: 'Destino:',
  es_km: 'Destino:',
  es_mx: 'Destino:',
};

export const LABEL_FECHA_SALIDA = {
  es_kc: 'Fecha de Salida:',
  es_km: 'Fecha de salida:',
  es_mx: 'Fecha de salida:',
};

export const LABEL_FECHA_REGRESO = {
  es_kc: 'Fecha de Regreso:',
  es_km: 'Fecha de regreso:',
  es_mx: 'Fecha de regreso:',
};

export const LABEL_PASAJEROS = {
  es_kc: 'Pasajeros:',
  es_km: 'Pasajeros:',
  es_mx: 'Pasajeros:',
};

export const TEXT_RESUMEM_COMPRA = {
  es_kc: 'Resumen de Compra',
  es_km: 'Resumen de compra',
  es_mx: 'Resumen de compra',
};

export const TEXT_RESUMEM_VIAJE = {
  es_kc: 'Resumen de Viaje',
  es_km: 'Resumen de viaje',
  es_mx: 'Resumen de viaje',
};
