import React from 'react';

import { Link } from 'react-router-dom';
import styles from '../../Footer.module.scss';

function LeftOptions(props) {
  const { textJSON } = props;

  return (
    <div className={`d-none d-md-block col-3 ${styles.leftInfo}`}>
      <br />
      <div className={styles.wrapImg}>
        <img src={textJSON.logo} alt="logo" className={styles.img} />
      </div>
      {textJSON.text.map((textItem) => {
        const {
          defaultLink, pdf, link, to, text, id,
        } = textItem;
        if (link) {
          return (
            <Link data-testid="leftItem" key={id} to={to}>
              {link}
            </Link>
          );
        }
        if (defaultLink) {
          return (
            <a
              data-testid="leftItem"
              key={id}
              className={styles.defaultLink}
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              {defaultLink}
            </a>
          );
        }
        return (
          <p data-testid="leftItem" key={id}>
            {text}
          </p>
        );
      })}
    </div>
  );
}

export default LeftOptions;
