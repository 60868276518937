import React from 'react';
import { langKm, langKc, langMx } from '../../location/urls';

const fontRed = {
  color: '#FF1721',
};
const fontBlue = {
  color: '#00008F',
};

const fontBold = {
  fontWeight: 'bold',
};
const textDecorationUnderline = {
  textDecoration: 'underline',
};
const mxQuestions = [
  {
    question:
      '¿A quién debo contactar en caso de requerir información sobre la Asistencia?',
    answer: 'Para solicitar cualquier información, comuníquese a la línea de atención al teléfono: (55) 4744 – 4723',
  },
  {
    question:
      '¿Qué debo hacer en caso de requerir el servicio de Asistencia?',
    answer:
      'En caso de que exista una emergencia es indispensable reportar el evento a AXA PARTNERS, comuníquese lo antes posible a la línea de atención de servicios: (55) 4744 - 4723.',
  },
  {
    question: '¿A partir de cuando es válida la Asistencia?',
    answer:
      'La Asistencia cubre a partir de la fecha de inicio del viaje y hasta la fecha de regreso. Ambas fechas previamente establecidas en el certificado de viaje una vez adquirida la Asistencia de viaje.',
  },
  {
    question: '¿Cuál es la duración máxima de la Asistencia?',
    answer: 'Para planes anuales, la duración máxima es de 365 días, con viajes de hasta 60 días consecutivos. Para planes Single Trip, la duración depende del tiempo contratado, con una duración máxima de 180 días consecutivos.',
  },
  {
    question: '¿Quién puede adquirir la Asistencia en viaje?',
    answer:
      'Los planes pueden ser contratados por mexicanos y extranjeros, únicamente dentro de la República Mexicana.',
  },
  {
    question: '¿Qué destino debería elegir si viajo a diferentes países?',
    answer:
      'Se debe tomar en cuenta el destino más lejano al que vayas a viajar (ejemplo: si vas a viajar a Europa y los EE.UU., deberás seleccionar Europa).',
  },
  {
    question:
      '¿Hay países excluidos de la cobertura de la Asistencia en Viaje?',
    answer:
      'Actualmente, los países excluidos son Afganistán, Bielorrusia, Cuba, Irán, Corea del Norte, Rusia, Siria, Venezuela, la Península de Crimea y Sudán o cualquier otro país o región sujeta a sanciones comerciales o económicas internacionales. Todos los demás países están incluidos en nuestros bloques de destinos.',
  },
  {
    question: '¿Cómo es el pago de la Asistencia?',
    answer:
      'Aceptamos pagos con tarjetas de crédito y débito. (VISA y Mastercard).',
  },
  {
    question: '¿Cómo solicitar facturar?',
    answer:
      `En caso de requerir factura, debe enviar la solicitud con su constancia de situación fiscal, su número de certificado y su correo electrónico a:
      <a href="mailto:facturaasistencia@axa-assistance.com.mx">facturaasistencia@axa-assistance.com.mx</a>.`,
  },
];

const coQuestions = [
  {
    question: '¿Por qué contratar una asistencia al viajero de Axa Assistance?',
    answer: `
      <ul>
        <li>Experiencia de asistencia al viajero en todo el mundo</li>
        <li>Usted recibirá ayuda inmediata las 24 hs/365 días</li>
        <li>Ayuda más eficiente y rápida</li>
        <li>Red de cobertura médica en cualquier lugar del mundo</li>
        <li>Servicio de Urgencia en todo momento</li>
        <li>Asistencia médica por enfermedad y accidente</li>
        <li>Emergencias de medicamentos y odontología, etc.</li>
      </ul>`,
  },
  {
    question: '¿Cómo utilizar la asistencia al viajero?',
    answer: `
      <div>
        <p>
          Ante cualquier emergencia / evento/ imprevisto que pudiera tener,
          deberá siempre comunicarse con nuestra central operativa. (El número
          se encuentra en el certificado/voucher que le fue enviado/entregado,
          donde constan las condiciones de contratación y los alcances del
          producto adquirido) Una vez recibida la llamada, la central coordinara
          la asistencia según corresponda, luego le informaremos donde acercarse
          para ser atendido, una visita médica a domicilio y hasta el envió de
          una ambulancia. Dos factores muy importantes para definir la modalidad
          de la prestación, además de la patología que el pasajero tenga, son la
          estructura sanitaria del país de destino y la cultura del mismo. Que
          debe tener UD a mano:
        </p>
        <ul>
          <li>
            Su credencial de identificación o el número de su voucher o
            certificado (el cual le ha sido entregado conjuntamente con la
            documentación correspondiente)
          </li>
          <li>
            Nombre, teléfono y dirección del hotel o locación donde se encuentra
          </li>
          <li>Documento de identidad</li>
        </ul>
      </div>`,
  },
  {
    question:
      '¿Es obligatorio viajar con un servicio de Asistencia al Viajero?',
    answer: `
      <ul>
        <li>
          Si le gusta estar seguro y protegido en todo momento y en cualquier
          lugar, no dudes en contratar un servicio de asistencia al viajero.
        </li>
        <li>
          Evite gastos innecesarios, en cualquier momento puede ocurrir un
          imprevisto, este preparado.
        </li>
        <li>
          Los costos de la asistencia médica en el extranjero son muy superiores
          a los valores de los productos que ofrece Axa Assistance.
        </li>
        <li>
          Cada vez más hay países que exigen el ingreso con asistencia al
          viajero: Comunidad Europea, Venezuela, Cuba.
        </li>
      </ul>`,
  },
  {
    question:
      '¿Qué debo hacer si mi problema fue urgente y no pude comunicarme con AXA Assistance?',
    answer: `
      <ul>
        <li>
          En este caso, acérquese al servicio médico de urgencia más próximo al
          lugar donde se encuentre.
        </li>
        <li>
          En una segunda instancia, dentro de las 48hs siguientes, usted o
          cualquier persona que lo acompañe deberá comunicarse con la Central de
          Asistencias de Axa.
        </li>
      </ul>`,
  },
  {
    question: '¿Cómo debo hacer para procesar un rembolso?',
    answer:
      'Para presentar un reembolso debe traer todos los comprobantes originales que posea de los gastos que realizó en destino, junto con la copia del Voucher. Una vez presentada toda la documentación se comunicará la resolución aproximadamente en un lapso de 20 días. Posee 30 días para la presentación a partir de la fecha de llegada al país.',
  },
];

export default {
  [langKc]: coQuestions,
  [langKm]: mxQuestions,
  [langMx]: mxQuestions,
};
