import React, { useMemo, useCallback } from 'react';
import Logo1 from '../Logos/appetizer.png';
import Logo2 from '../Logos/NewsandMagazine.png';
import Logo3 from '../Logos/WiFi.jpg';
import Logo4 from '../Logos/Livingroom.png';
import Section from './Section';
// import TandCpdf from '../../../assets/documents/T&CSAXAAirportComfort.pdf';

function TextSections() {
  const logoToFigure = useCallback(
    logoList => (
      <div className="d-flex flex-row justify-content-around">
        {logoList.map(
          ({icon, caption }, index) => (
            <figure className="figure w-lg-25 w-md-10" key={index}>
              <img className="figure-img" src={icon} alt={caption} />
              <figcaption className="figure-caption w-60 text-wrap">
                {caption}
              </figcaption>
            </figure>
          ),
        )}
      </div>
    ),
    [],
  );

  const contentData = useMemo(() => {
    const figures1 = logoToFigure([
      { icon: Logo1, caption: 'Aperitivos y refrescos de cortesía' },
      { icon: Logo2, caption: 'Periódicos y revistas de cortesía' },
    ]);
    const figures2 = logoToFigure([
      {
        icon: Logo3,
        caption: 'Wi-Fi gratuito y puntos de recarga de dispositivos',
      },
      { icon: Logo4, caption: 'Salas de conferencias y negocios' },
    ]);

    const section1 = {
      title: 'AXA Airport Comfort',
      content: (
        <p>
          En caso que suceda un retraso de tu vuelo, gracias a
          AXA Airport Comfort, tendrás acceso gratuito a una las 1,000 salas
          VIP de los aeropuertos (en más de 500 aeropuertos distribuidos en
          más de 100 países). Previamente, tendrás que haber registrado tu vuelo en la página.
        </p>
      ),
    };
    const section2 = {
      title: '¿Quién puede usar este servicio?',
      background: 'primary',
      color: 'white',
      content: (
        <p className="text-white">
          Este servicio exclusivo es un beneficio gratuito disponible para los
          clientes que han adquirido un plan Individual (una persona)
          o bien un plan Familiar (hasta 5 personas).
        </p>
      ),
    };
    const section3 = {
      title: '¿Cuáles son las salas VIP del aeropuerto y dónde se encuentran?',
      content: (
        <div>
          <a
            className="btn btn-outline-primary"
            href="https://loungefinder.loungekey.com/"
            role="button"
          >
            LoungeFinder
          </a>
          <p>
            Podrás disfrutar de un lugar tranquilo y cómodo para relajarte,
            ya será un que viaje de negocio o de placer. La mayoría de las salas ofrecen:
          </p>
          <div className="d-flex flex-column">
            {figures1}
            {figures2}
          </div>
        </div>
      ),
    };
    const section4 = {
      title: '¿Cómo funciona?',
      content: (
        <ul>
          <li>
            <p>
              1- El registro de vuelo deberá efectuarse dos horas de salida del vuelo,
              a más tardar.
            </p>
          </li>
          <li>
            <p>
              2- Deberás registrar cualquier vuelo adicional de ida y vuelta por separado.
            </p>
          </li>
          <li>
            <p>
              3- Recibirás un correo electrónico de confirmación de tu registro.
            </p>
          </li>
          <li>
            <p>
              4- En caso de que sea identificado un retraso en un vuelo registrado (90 minutos),
              será enviado un correo electrónico con tus cupones gratuitos de acceso
              a la sala del aeropuerto*.
            </p>
          </li>
          <li>
            <p>
              5-Este servicio no se proporciona si el retraso notificado no
              cumple con los requisitos de elegibilidad**.
            </p>
          </li>
          <li>
            <p>
              6- Servicio es proporcionado por Collinson Group, para más información acceda
              a https://loungefinder.loungekey.com/pass/conditions-of-use . <br />
              * Un vuelo se define como un único vuelo regular de una compañía aérea
              con un único número de vuelo, excluido vuelo chárter. <br />
              **El retraso debe ser anunciado oficialmente por la aerolínea y ser
              comunicado a nuestro proveedor de servicios de datos sobre
              el estado de los vuelos.
            </p>
            <a
              className="btn btn-outline-primary"
              href="https://prismic-io.s3.amazonaws.com/b2c-banorte/12463d67-170e-4808-b6e8-7042a9b46632_T%26CS+AXA+Airport+Comfort.pdf"
              role="button"
              target="blank"
            >Términos y Condiciones
            </a>
          </li>
        </ul>
      ),
    };

    return [section1, section2, section3, section4];
  }, [logoToFigure]);

  return (
    <div className="col col-lg-8 col-md-6 col-sm-8">
      {contentData.map(({ content, ...props }, index) => (
        <Section key={index} {...props}>{content}</Section>
      ))}
    </div>
  );
}

export default TextSections;
