export const NUMBER_PERSON = {
  es_kc: 'Número de viajeros *',
  es_mx: 'Número de viajeros *',
  es_km: 'Número de viajeros *',
};

export const AGE_PERSON = {
  es_kc: 'Edad de los viajeros *',
  es_mx: 'Edad de los viajeros *',
  es_km: 'Edad de los viajeros *',
};

export const LABEL_AGE = {
  es_kc: 'Edad',
  es_mx: 'Edad',
  es_km: 'Edad',
};
