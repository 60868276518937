import React from 'react';
import StaticPageContentItem from './StaticPageContentItem/StaticPageContentItem';

function StaticPageContentList(props) {
  const { list, idiom } = props;
  const mappedTexts = list.map(value => (
    <StaticPageContentItem key={value.id} value={value} idiom={idiom} />
  ));
  return <div>{mappedTexts}</div>;
}

export default StaticPageContentList;
