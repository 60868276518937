import React from 'react';

import styles from './AsistenciaContent.module.scss';
import centralAlarma from '../../../assets/img/asistencia/asistencia-co-central-alarma.png';
import atencionMedica from '../../../assets/img/asistencia/asistencia-co-atencion-medica.png';
import certificado from '../../../assets/img/asistencia/asistencia-co-certificado.png';
import emergencia from '../../../assets/img/asistencia/asistencia-co-emergencia.png';
import tips from '../../../assets/img/asistencia/asistencia-co-tips.png';

function AsistenciaCo(props) {
  return (
    <div
      className={`${styles.asistentContent}`}
      data-testid="asistenciaContentCo"
    >
      <div className={styles.list}>
        <div className={`col-md-4 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <img src={centralAlarma} alt="asistencia_" />
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>Central de Alarma</h4>
            <p className={styles.content}>
              Llamar de forma inmediata a la central de alarma que opera las
              24/7
              <br />
              En Estados Unidos Toll Free Tel:
              <br />
              1 888 323 17 34
              <br />
              En Colombia (por cobrar) Tel:
              <br />
              (57)(1) 644 61 99
              <br />
              Resto del mundo (por cobrar):
              <br />
              1 866 681 90 23
            </p>
          </div>
        </div>

        <div className={`col-md-4 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <img src={atencionMedica} alt="asistencia_" />
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>
              En la línea de atención médica te solicitarán
            </h4>
            <p className={styles.content}>
              &#8226; Número de identificación
              <br />
              &#8226; Nombre completo
              <br />
              &#8226; Ubicación exacta del paciente
              <br />
              &#8226; Teléfono del paciente
              <br />
              &#8226; Edad y datos de residencia
            </p>
          </div>
        </div>

        <div className={`col-md-4 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <img src={certificado} alt="asistencia_" />
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>
              ¿Qué información debe aparecer en mi certificado?
            </h4>
            <p className={styles.content}>
              &#8226; Nombre completo
              <br />
              &#8226; Número de documento
              <br />
              &#8226; Número consecutivo
              <br />
              &#8226; Coberturas de asistencia
              <br />
              &#8226; Fechas de viaje
              <br />
              &#8226; Destino
              <br />
              &#8226; Teléfonos de emergencia
              <br />
              &#8226; Fecha de expedición
            </p>
          </div>
        </div>

        <div className={`col-md-4 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <img src={emergencia} alt="asistencia_" />
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>¿Qué hacer en caso de emergencia?</h4>
            <p className={styles.content}>
              Si tienes una emergencia compleja , donde no es posible
              comunicarte porque está en peligro tu vida debes dirigirte al
              centro hospitalario más cercano, y lo más pronto posible deben
              comunicarse a la central de alarma, antes de 72 horas, ya sea la
              clínica, un familiar o tú, y brindar toda la información del
              suceso y del lugar donde te encuentras.
            </p>
          </div>
        </div>

        <div className={`col-md-4 col-sm-12 ${styles.item}`}>
          <div className={styles.imgArea}>
            <img src={tips} alt="asistencia_" />
          </div>
          <div className={styles.infoArea}>
            <h4 className={styles.title}>Tips Importantes</h4>
            <p className={styles.content}>
              En caso de presentar algún tipo de emergencia, lo mas importante
              es comunicarse con la central de alarma
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AsistenciaCo;
