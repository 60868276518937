import planes from './planes';
import banner from './banner';
import blog from './blog';
import benefits from './benefits';
import advertisement from './advertisement';
import phone from './phone';
import state from './state';

export const PlanesPrismicService = planes();
export const BannerPrismicService = banner();
export const BlogPrismicService = blog();
export const BenefitsPrismicService = benefits();
export const AdvertisementPrismicService = advertisement();
export const PhonePrismicService = phone();
export const StatePrismicService = state();
