import React from 'react';
import styles from './LoadingCircle.module.scss';
import iconCircle from '../../assets/img/loading.gif';

function LoadingCircle(props) {
  const { dataTestId = 'loadingCircle', message } = props;
  const msg = message || '';
  return (
    <div className={styles.wrapLoading}>
      <div className={styles.loadingCircle} data-testid={dataTestId}>
        <img src={iconCircle} alt="_" />
        <h4 className={styles.loadingMsg}>{msg}</h4>
      </div>
    </div>
  );
}

export default LoadingCircle;
