import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import styles from './BenefitsList.module.scss';
import BenefitsItem from './BenefitsItem/BenefitsItem';
import Button from '../../../components/Button/Button';
import { LocationContext } from '../../../components/Location/Location';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const getNumberSlideToShow = (list) => {
  if (!list) return 0;
  return list.length > 3 ? 3 : list.length;
};

function Arrow({ direction, onClick }) {
  const classArrow = direction === 'left' ? styles.btnLeft : styles.btnRight;
  const icon = direction === 'left' ? faChevronLeft : faChevronRight;
  return (
    <FontAwesomeIcon
      className={`${styles.btnArrow} ${classArrow}`}
      onClick={onClick}
      icon={icon}
      color="#009ae5"
    />
  );
}

function BenefitsList(props) {
  const { benefits, title } = props;
  const { idiom } = useContext(LocationContext);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const intl = translate(translations);
  const history = useHistory();
  const list = benefits.map((benefit, id) => (
    <BenefitsItem key={`benefitItem_${id}`} benefit={benefit} />
  ));
  const redirectMoreBenefits = () => {
    history.push(intl.MORE_BENEFITS_LINKS);
  };
  const settings = {
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 300,
    slidesToShow: getNumberSlideToShow(benefits),
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          speed: 300,
          slidesToScroll: 1,
          slidesToShow: 3,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          speed: 300,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
    ],
  };
  return (
    <div className={` ${styles.wrapBenefits}`}>
      <div className="col-12">
        <h4
          data-testid="titleBenefits"
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div className={`col-12 ${styles.benefits}`}>
        <div className={` justify-content-center ${styles.list}`}>
          <Slider {...settings}>{list}</Slider>
        </div>
        <div className={styles.wrapButton}>
          <a
            href="/mis-planes"
            onClick={redirectMoreBenefits}
            className={styles.aMoreBenefits}
          >
            {intl.BUTTON_MORE_BENEFITS}
            <FontAwesomeIcon
              icon="long-arrow-alt-right"
              color="#010090"
              style={{ marginLeft: '10%' }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BenefitsList;
