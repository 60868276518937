import React, { useContext, useState, useEffect } from 'react';
import styles from './ModalOptionalBenefits.module.scss';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Button from '../../../../components/Button/Button';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { LocationContext } from '../../../../components/Location/Location';
import Modal from '../../../../components/Modal/Modal';
import formatProduct from './formatProduct';
import Plans from '../../../../components/Plans/Plans';

import ItemOptionalBenefit from './ItemOptionalBenefit';

const getProduct = step2 => (step2.data.QuoteKAYAK && step2.data.QuoteKAYAK.ProductKAYAK
  ? step2.data.QuoteKAYAK.ProductKAYAK
  : undefined);

const renderSelectedProduct = formatedStep2Info => {
  if (!formatedStep2Info) {
    return <></>;
  }
  const { productIndex, dataList } = formatedStep2Info;
  const plan = dataList.listPlanes[productIndex];
  return (
    <Plans
      plan={plan}
      descriptionBenefits={dataList.descriptionBenefits}
      index={productIndex}
      optionalBenefits={dataList.optionalBenefits}
      selected
    />
  );
};

function ModalOptionalBenefits(props) {
  const {
    step1, step2, step3, setLoading,
  } = props;
  const {
    optionalBenefits, amount, amountWithBenefits, name,
  } = step3.data;
  const [attention, setAttention] = useState(true);
  const { translate } = useContext(IntlContext);
  const { actions, utils } = useContext(StateContext);
  const { idiom, idiomAPI } = useContext(LocationContext);
  const { formDataCotizar } = actions;
  const intl = translate(translations);
  const objProduct = getProduct(step2).find(
    product => product.Product.Id === step3.data.Id,
  );

  const removeAttention = () => {
    setAttention(false);
    window.scrollTo(0, 0);
  };

  const getBenefitsAddList = DetailId => {
    const ids = [];
    optionalBenefits.forEach(benefit => {
      if (benefit.detailToPlan.selected || benefit.DetailId === DetailId) {
        ids.push(benefit.DetailId);
      }
    });
    return ids;
  };

  const getBenefitsRemoveList = DetailId => {
    const ids = [];
    optionalBenefits.forEach(benefit => {
      if (benefit.detailToPlan.selected && benefit.DetailId !== DetailId) {
        ids.push(benefit.DetailId);
      }
    });
    return ids;
  };

  const updateAmount = (newAmount, existsOptionalBenefitsSelected) => {
    const form3 = utils.getFormDataStep3();
    if (existsOptionalBenefitsSelected) {
      form3.data.amountWithBenefits = newAmount;
    } else {
      form3.data.amount = newAmount;
      form3.data.amountWithBenefits = undefined;
    }
    actions.formDataCotizar.setForm3(form3);
  };

  const changeProduct = async (productCode, setLogicBenefit) => {
    await formatProduct(
      utils.getFormDataStep2().data,
      utils.getFormDataStep1().Quote,
      productCode,
      utils.getFormDataStep3(),
      'mx',
      idiom,
      setLogicBenefit,
      updateAmount,
      actions,
    );
  };

  const contentOptionalBenefits = optionalBenefits.map((information, index) => (
    <ItemOptionalBenefit
      information={information}
      index={index}
      objProduct={objProduct}
      formDataCotizar={formDataCotizar}
      idiom={idiom}
      idiomAPI={idiomAPI}
      changeProduct={changeProduct}
      getBenefitsAddList={getBenefitsAddList}
      getBenefitsRemoveList={getBenefitsRemoveList}
      updateAmount={updateAmount}
      {...props}
      key={index}
    />
  ));

  return (
    <Modal background active={attention}>
      <div className={styles.modal}>
        <div className={styles.modalTitle} key="modalTitle">
          <strong>{intl.TITLE_MODAL}</strong>
          {/* {attention && ( */}
          <span className={styles.spanClose} onClick={removeAttention}>
            X
          </span>
          {/* )} */}
        </div>
        <div className={styles.modalBody}>
          <div className={styles.bodyLeft}>
            {renderSelectedProduct(step3.formatedStep2Info)}
          </div>
          <div className={styles.bodyRight}>{contentOptionalBenefits}</div>
        </div>
        {/* {attention && ( */}
        <div className={styles.footer}>
          <h3
            className={styles.plan}
            dangerouslySetInnerHTML={{ __html: intl.PLAN_BENEFITS(name) }}
          />
          <h3 className={styles.cost}>
            <strong>
              <span className={styles.symbol}>$</span>
              {amountWithBenefits
                ? amountWithBenefits.toFixed(2)
                : amount.toFixed(2)}
            </strong>
          </h3>
          <Button
            value={intl.BTN_CONTINUE}
            whiteBlue
            onClick={removeAttention}
          />
        </div>
        {/* )} */}
      </div>
    </Modal>
  );
}

export default ModalOptionalBenefits;
