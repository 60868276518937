import React, { useContext } from 'react';
import styles from './ModalChangeAge.module.scss';
import { IntlContext } from '../../../../../../../intl/index';
import * as translations from './intl';
import Modal from '../../../../../../../components/Modal/Modal';
import Button from '../../../../../../../components/Button/Button';

function ModalChangeAge(props) {
  const { onConfirm, newValue } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  return (
    <Modal active>
      <div className={styles.modalChangeAge}>

        <div className={styles.modalTitle} key="modalTitle">
          <strong>{intl.MODAL_TITLE}</strong>
          {/* {attention && ( */}
          <span className={styles.spanClose} onClick={onConfirm}>
            X
          </span>
          {/* )} */}
        </div>

        <div className={styles.message}>
          <div className={styles.contentMessage}>
            <span>{intl.ADJUSTED_BASED_ON}</span>
            <br />
            <span className={styles.item}>{intl.BIRTH_NOTE_MATCH_AGE}</span>
            <br />
            <span>{intl.TEXT_NEW_VALUE(newValue)}</span>
          </div>
        </div>
        <div className={styles.footer}>
          <Button blue value={intl.BTN_EDIT_INFO} onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  );
}

export default ModalChangeAge;
