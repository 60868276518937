import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './PromotionsBanner.module.scss';
import Button from '../Button/Button';

function PromotionsBanner({ banner }) {
  const [visibleBanner, setVisibleBanner] = useState(true);

  const closeBanner = () => {
    setVisibleBanner(false);
  };

  return (
    <>
      {visibleBanner && (
        <div className={styles.promotions_banner_container}>
          <img
            data-testid="promotions"
            className={styles.imgBanner}
            src={banner.url}
            alt="logo_"
          />
          <div className={styles.buttonClose}>
            <Button iconButton onClick={closeBanner}>
              <FontAwesomeIcon
                icon={faTimesCircle}
                color="#7384a3"
                className="arrowIcon"
                size="2x"
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default PromotionsBanner;
