import {
  langKc, langMx, prefixMx, langKm, prefixCo,
} from '../location/urls';

export const getCountryIsoCodeByFormType = formType => {
  let isoCode;
  switch (formType) {
    case langKc:
      isoCode = prefixCo.toUpperCase();
      break;

    case langMx:
      isoCode = prefixMx.toUpperCase();
      break;

    case langKm:
      isoCode = prefixMx.toUpperCase();
      break;

    default:
      isoCode = prefixMx.toUpperCase();
      break;
  }

  return isoCode;
};
