import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Faq.module.scss';
import QuestionsList from './QuestionsList/QuestionsList';
import { LocationContext } from '../../components/Location/Location';
import { IntlContext } from '../../intl';
import faqQuestions from './Faq_Text';
import {
  langKm, langKc, langMx,
} from '../../location/urls';
import * as translations from './intl';
import Banner from '../../components/Banner/Banner';
import { FAQ } from '../../utils/category';
import { dataLayerTrack } from '../../utils/GTM_helper';

function Faq() {
  const { idiom, country } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const location = useLocation();

  const kcQuestions = faqQuestions[langKc];
  const mxQuestions = faqQuestions[langMx];

  useEffect(() => {
    dataLayerTrack(intl, idiom, country, location);
    console.log('questions', mxQuestions);
  }, []);

  return (
    <div className={`${styles.faq}`} data-testid="pageFaq">
      <Banner datatestid="bannerFaq" category={FAQ} />
      <div className={`${styles.wrapQuestions}`}>
        <div className={styles.title}>
          <h1>{intl.WRAP_QUESTIONS_TITLE}</h1>
        </div>
        {idiom === langMx || idiom === langKm ? <QuestionsList questions={mxQuestions} /> : <></>}
        {idiom === langKc ? <QuestionsList questions={kcQuestions} /> : <></>}
      </div>
    </div>
  );
}

export default Faq;
