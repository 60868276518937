import React from 'react';

import styles from '../Contactanos.module.scss';
import ContactanosItem from './ContactanosItem/ContactanosItem';

function ContactanosList(props) {
  const { list, idiom } = props;

  const mappedItens = list.map((value, index) => (
    <ContactanosItem
      idiom={idiom}
      key="contactanosList-{id}"
      value={value}
    />
  ));

  return <div className={styles.content}>{mappedItens}</div>;
}

export default ContactanosList;
