import Prismic from 'prismic-javascript';
import moment from 'moment';
import { getDataFromPrismic } from './PrismicUtils';

export default () => ({
  getStateDocument: async (headers, isoCode) => {
    const result = await getDataFromPrismic(
      headers,
      'state_document',
      Prismic.Predicates.at('my.state_document.id', isoCode),
    );

    const lang = headers['Accept-Language'].substring(0, 2);

    moment.locale(lang);

    const fullDetail = result[0].data;
    const blogDetail = {
      uid: result[0].uid,
      isoCode: fullDetail.id,
      title: fullDetail.title,
      pdf_url: fullDetail.pdf_url.url,
    };

    return blogDetail;
  },
});
