import React from 'react';
import EsDataForm from './EsDataForm/EsDataForm';

function NotSet() {
  return <h1>Error Page</h1>;
}

const compoment = {
  es_kc: EsDataForm,
  es_km: EsDataForm,
  es_mx: EsDataForm,
};

function Form({ formType, ...props }) {
  const Component = compoment[formType] || NotSet;
  return <Component {...props} />;
}

export default Form;
