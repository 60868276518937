import React from 'react';

import styles from './NosotrosContent.module.scss';

function NosotrosMx(props) {
  return (
    <div className={styles.content} data-testid="nosotrosContentMx">
      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>¿Quiénes</span>
          <br />
          <span className={styles.blue}>somos?</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          Somos una compañía global de origen francés que abrió sus puertas en
          México en el año de 1981 para brindar servicios de asistencia en casos
          de emergencia y de apoyo a nuestros clientes. Con presencia en 56
          países, empleamos a 157.000 personas y contamos con una extensa red de
          distribuidores y socios de negocios. Toda la estructura es de 102
          millones de clientes - los individuos, las familias y las empresas de
          todos los tamaños.
          {' '}
          <br />
          <br />
          AXA Assistance es el área encargada de ofrecer las mejores coberturas
          de asistencia de viajes para brindar tranquilidad a través de un
          servicio de calidad, que le facilitará a nuestros beneficiarios apoyo
          a nivel nacional e internacional para resolver diversos percances
          durante su viaje en temas de salud, equipaje entre otros.
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Antes, durante y después</span>
          <br />
          <span className={styles.blue}>de cada viaje</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          Para AXA Assistance, asistencia de viaje significa más que una
          respuesta de emergencia; Brindamos apoyo continuo antes, durante y
          después del viaje. De antemano, proporcionamos información esencial
          que ayuda a nuestros clientes a organizar su viaje (requisitos
          administrativos, prerrogativas médicas, información de seguridad,
          etc.). Siempre que sea necesario, en cualquier momento durante su
          estancia, nuestros clientes pueden confiar en nuestra red médica
          internacional. Y si su salud se deteriora, organizaremos una operación
          de repatriación médica y aseguraremos todos los servicios de
          seguimiento médico y atención domiciliaria necesarios.
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Ofrecemos</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <ul>
            <li>Asistencia médica por enfermedad y/o accidente</li>
            <li>Asistencia médica por enfermedades pre-existentes</li>
            <li>Análisis, Rayos-X, examinaciones especiales</li>
            <li>Hospitalización</li>
            <li>Intervención quirúrgica</li>
            <li>Terapia Intensiva y unidad coronaria</li>
            <li>Médico a domicilio</li>
            <li>Emergencias dentales</li>
            <li>Gastos de Hotel por Convalecencia</li>
            <li>Traslado médico para el Beneficiario</li>
            <li>
              Boleto de viaje redondo y gastos de Hospedaje para un familiar
            </li>
            <li>Regreso anticipado por muerte de un familiar directo</li>
            <li>Gastos por cambio de vuelo por enfermedad y/o accidente</li>
            <li>Repatriación en caso de fallecimiento</li>
            <li>Gastos legales para beneficiario</li>
            <li>Localización de equipaje</li>
          </ul>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Nuestros</span>
          <br />
          <span className={styles.blue}>valores</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            <strong>INTEGRIDAD</strong>
            Actuar con rectitud y de acuerdo a los más altos indicadores y
            estándares de calidad del mercado, con la finalidad de obtener los
            mejores resultados tanto para nuestros beneficiarios como para la
            compañía.
          </p>

          <p>
            <strong>CORAJE</strong>
            Tomar las mejores decisiones con el mayor esfuerzo, estableciendo
            prioridades orientadas a nuestro ideal, nuestra visión y valores,
            conscientes de la realidad, los beneficios y problemas actuales, sin
            descuidar los intereses de nuestros beneficiarios.
          </p>

          <p>
            <strong>INNOVACIÓN</strong>
            Creamos soluciones innovadoras basadas en las nuevas tecnologías
            digitales y plataformas de negocios para satisfacer las necesidades
            específicas que surgen todos los días. Podemos desarrollar sistemas
            centrados en su proyecto, así como utilizar nuestras plataformas
            regionales.
          </p>

          <p>
            <strong>ONE AXA</strong>
            Trabajar en equipo con el mismo objetivo para obtener los mejores
            resultados buscando siempre innovar y brindar el mejor servicio
            posible.
          </p>

          <p>
            <strong>EL CLIENTE ES PRIMERO</strong>
            Siempre buscar mejorar nuestras coberturas para obtener la total
            satisfacción y lealtad de nuestros clientes, con el máximo de
            calidad posible para la obtención de resultados superando
            expectativas.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Responsabilidad</span>
          <br />
          <span className={styles.blue}>social</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            La estrategia de AXA es colocar la Responsabilidad Social
            Corporativa (RSC) tanto en el centro de su negocio como en sus
            interacciones diarias con sus partes interesadas. Es a través de la
            adopción de un comportamiento responsable, así como a través de
            productos y servicios sostenibles de valor agregado, que el Grupo
            puede participar más efectivamente en el progreso social, ambiental
            y económico.
          </p>

          <p>
            Para nosotros, la RSC no es un "programa", es una manera de ser y de
            hacer nuestro trabajo para nuestros Colaboradores, Accionistas,
            Proveedores, Comunidad, Medio ambiente y Clientes.
          </p>

          <p>
            Nuestro Comité de Responsabilidad Social tiene el propósito de hacer
            de AXA Partners una empresa con conciencia, compromiso y cultura
            responsable, generando bienestar en nuestro entorno.
          </p>

          <p>
            AXA Partners México es reconocida por diversos organismos como una
            Empresa Socialmente Responsable e Inclusiva.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Nuestra</span>
          <br />
          <span className={styles.blue}>misión</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            Cada día es diferente, esta es la razón por la que en AXA ayudamos a
            nuestros clientes a hacer frente a los problemas de la vida, los
            grandes y los pequeños, apoyándolos a la hora de asumir proyectos y
            prepararse para el futuro con mayor tranquilidad.
          </p>
        </div>
      </div>

      <div className={styles.space} />

      <div className={`row ${styles.row}`}>
        <div className={`col-12 col-md-5 ${styles.left}`}>
          <span className={styles.red}>Nuestra</span>
          <br />
          <span className={styles.blue}>visión</span>
        </div>
        <div className={`col-12 col-md-7 ${styles.right}`}>
          <p>
            Nuestra ambición es convertirnos en la compañía preferida de nuestro
            sector para todos nuestros grupos de interés, incluyendo a nuestros
            clientes, empleados, accionistas, distribuidores, proveedores y la
            sociedad a la que pertenecemos. Para alcanzar nuestro objetivo, nos
            comprometemos a reinventar las asistencias para verdaderamente
            merecer la confianza depositada en nosotros. Una relación de
            confianza sólida y duradera es esencial y no puede imponerse; debe
            ser cimentada a través de pruebas tangibles día tras día.
          </p>

          <p>
            Estamos disponibles las 24 horas del día, 7 días a la semana con
            profesionales capacitados para ayudar con cualquier cosa que
            necesites!
          </p>
        </div>
      </div>
    </div>
  );
}

export default NosotrosMx;
