import React, { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../../Location/Location';
import image from '../../../../assets/img/collinson-sd-pp-logos.png';
import styles from '../../Footer.module.scss';

function RightOptions({ ismobile, phone, textJSON }) {
  const { idiom } = useContext(LocationContext);
  const [phoneMobile, setPhoneMobile] = useState(null);

  const [cards, setcards] = useState([]);

  useEffect(() => {
    if (phone) {
      setPhoneMobile(`tel:${phone.phone.replace(/\s/g, '')}`);
      const arrayCards = [];
      if (phone.icon_master) {
        arrayCards.push({ src: phone.icon_master });
      }
      if (phone.icon_visa) {
        arrayCards.push({ src: phone.icon_visa });
      }
      if (phone.icon_amex) {
        arrayCards.push({ src: phone.icon_amex });
      }
      setcards(arrayCards);
    }
  }, [idiom]);

  return (
    <div className={styles.rightInfo}>
      <div className={styles.phoneText}>
        <div data-testid="phoneText">
          <p className={`phoneTitle ${styles.title}`}>{textJSON.text.title}</p>
          {phone && ismobile && (
            <p className={`phoneNumber ${styles.number}`}>
              <a href={phoneMobile}>{phone.phone}</a>
            </p>
          )}
          {phone && !ismobile && (
            <p className={`phoneNumber ${styles.number}`}>{phone.phone}</p>
          )}
        </div>
      </div>
      <div className={styles.icons}>
        {cards.map((logo, key) => (
          <div key={key} data-testid="logoItem" className={styles.wrapImg}>
            <img src={logo.src} alt="_logo" />
          </div>
        ))}
      </div>
      <img src={image} alt="hahaha" />
    </div>
  );
}

export default RightOptions;
