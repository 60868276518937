import React from 'react';

function Section({
  title, background = '', color = 'primary', ...props
}) {
  return (
    <div className={`card bg-${background} m-2`}>
      <div className="card-body">
        <h5 className={`card-title font-weight-bold text-${color}`}>{title}</h5>
        {props.children}
      </div>
    </div>
  );
}

export default Section;
