import React, { useContext, useState, useEffect } from 'react';
import { round } from 'lodash';
import styles from './Plans.module.scss';
import { getAmountFormat } from '../../utils/currencyISOCode';
import { LocationContext } from '../Location/Location';
import PlansBtnSelect from './PlansBtnSelect/PlansBtnSelect';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { BenefitService } from '../../services';
import plusCircle from '../../assets/img/plus-circle-blue.svg';
import lessCircle from '../../assets/img/less-circle-blue.svg';

const multiTripProducts = process.env.REACT_APP_MEX_MULTITRIP_PRODUCT_ID.split(',');
const renderIconToBenefit = async (benefitList, idiomAPI) => {
  // eslint-disable-next-line no-restricted-syntax
  for await (const benefit of benefitList) {
    try {
      const icon = await BenefitService.getBenefitByBenefitCode(
        idiomAPI,
        benefit.BenefitCode,
      );
      if (icon && icon.icon_url) {
        benefit.icon_url = icon.icon_url;
      }
    } catch (error) {
      console.warn(error);
    }
  }
};

const renderListBenefits = async (
  setListBenefits,
  benefits,
  indexPlan,
  idiomAPI,
) => {
  let getProductBenefits = benefits.filter((list) => list.content[indexPlan].detail !== '- -');
  const getFourBenefits = getProductBenefits.slice(0, 4);
  getProductBenefits = null;
  await renderIconToBenefit(getFourBenefits, idiomAPI);
  const newListBenefits = getFourBenefits.map((list, index) => (
    <li key={`Benefits-${index}`}>
      <div className={styles.boxTitleBenefit}>
        <div className={styles.planTitleBenefit}>
          <h2>{list.description}</h2>
        </div>
        <div className={styles.planSubtitleBenefit}>
          <h3>{list.content[indexPlan].detail}</h3>
        </div>
      </div>
    </li>
  ));
  setListBenefits(newListBenefits);
};

function Plans(props) {
  const {
    discount = true,
    plan,
    name,
    complementaryBenefits,
    optionalBenefits,
    descriptionBenefits,
    index,
    dataList,
    selected,
    setShowModal = null,
    showModal = null,
  } = props;

  const { idiom, country, idiomAPI } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [benefits, setBenefits] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const {
    Recommended, Name, Amount, AmountWithoutPromotion, Document, Id, Currency,
  } = plan;
  const borderBlue = Recommended || selected ? styles.borderBlue : '';
  const AmountPromotion = AmountWithoutPromotion !== Amount;

  let productDiscount = 0;

  if (AmountPromotion) {
    productDiscount = round(((AmountWithoutPromotion - Amount) / AmountWithoutPromotion) * 100);
  }

  const symbolAmount = '$';
  const urlDocument = Document && Document.length > 0 && Document[0] && Document[0].url !== '';

  const checkMobile = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    renderListBenefits(setBenefits, descriptionBenefits, index, idiomAPI);
  }, [descriptionBenefits]);
  const showModalBenefits = () => {
    setShowModal({ ...showModal, show: true, index });
  };

  return (
    <>
      <div className={`${styles.boxPlans} ${borderBlue}`}>
        {(Recommended || selected) && (
          <div className={styles.planBestChoice}>
            <span>
              {' '}
              {selected ? intl.SELECTED_PLAN : intl.BEST_CHOICE}
            </span>
          </div>
        )}
        {AmountPromotion && productDiscount > 0 &&
          (<span className={styles.promoOffer}>Promo {productDiscount}% Off</span>)}
        <div className={styles.planName}>
          <h1>{Name}</h1>
        </div>
        {(multiTripProducts.includes(JSON.stringify(Id))) && (
          <div className={styles.planTagYellowBestchoice}>
            {!(selected && !showMore && isMobile) && (
              <span>{intl.YELLOW_MAIN_BENEFITS}</span>
            )}
          </div>
        )}
        {(Recommended) && (
          <div className={styles.planTagBestchoice}>
            {!(selected && !showMore && isMobile) && (
              <span>{intl.MAIN_BENEFITS}</span>
            )}
          </div>
        )}
        {(!Recommended) && (
          <div className={styles.planTag}>
            {!(selected && !showMore && isMobile) && (
              <span>{intl.MAIN_BENEFITS}</span>
            )}
          </div>
        )}
        <ul className={styles.planListBenefit}>
          {selected && !showMore && isMobile ? benefits[0] : benefits}
        </ul>
        {!selected && (
          <>
            <div className={styles.planValueBenefit}>
              {AmountPromotion && (
                <span className={styles.planValue}>
                  {symbolAmount}
                  {getAmountFormat(AmountWithoutPromotion)}
                </span>
              )}
              <strong className={styles.planBoxFinalValue}>
                <span className={styles.currencyValue}>{Currency}</span>
                <span className={styles.planSymbolValue}>{symbolAmount}</span>
                <span className={styles.planFinalValue}>
                  {AmountPromotion
                    ? getAmountFormat(Amount)
                    : getAmountFormat(AmountWithoutPromotion)}
                </span>
              </strong>
            </div>
            <PlansBtnSelect
              product={plan}
              productIndex={index}
              recommended={Recommended}
              optionalBenefits={optionalBenefits}
              intl={intl}
              dataList={dataList}
            />
            <div className={styles.planMoreBenefits}>
              <span onClick={showModalBenefits}>{intl.SEE_MORE_BENEFITS}</span>
            </div>
          </>
        )}
      </div>
      {selected && (
        <button className={styles.plus} onClick={() => setShowMore(!showMore)}>
          <img src={showMore ? lessCircle : plusCircle} alt="+" />
        </button>
      )}
    </>
  );
}

export default Plans;
