import React from 'react';
import HeaderItem from './HeaderItem/HeaderItem';
import styles from '../Header.module.scss';

function HeaderList({ headerMenu, navMobile, setNavMobile }) {
  const headerMenuFilter =
    headerMenu && headerMenu.length > 0
      ? headerMenu.filter((option) => !option.hidden)
      : [];

  const headerListOptions = headerMenuFilter.map((option, index) => (
    <HeaderItem
      key={`headerItem_${index}`}
      option={option}
      setNavMobile={setNavMobile}
    />
  ));

  return (
    <div
      className={`collapse navbar-collapse justify-content-end ${
        navMobile ? styles.open : styles.close
      } ${styles.wrapOptions}`}
    >
      <ul className={`${styles.contactanosHeaderOption} navbar-nav`}>
        {headerListOptions}
      </ul>
    </div>
  );
}

export default HeaderList;
