export const NotFoundError = (value, id, customMessage = false) => {
  const objectResult = {};
  objectResult.error = 'not_found';
  objectResult.status_code = 404;
  if (customMessage) {
    objectResult.error_description = value;
  } else if (id) {
    objectResult.error_description = `No ${value} found with id: ${id}`;
  } else {
    objectResult.error_description = `No ${value} found`;
  }
  return { ...objectResult };
};
