export const QUESTION_1 = {
  es_kc:
    '¿A quién contactar en caso de necesidad de información acerca del plan de asistencia?',
  es_km:
    '¿A quién contactar en caso de necesidad de información acerca de la póliza?',
  es_mx:
    '¿A quién contactar en caso de necesidad de información acerca de la póliza?',
};

export const ANSWER_1 = {
  es_kc:
    'Si deseas obtener información adicional comunícate a nuestra línea de Atención al Cliente: +57 (1) 646 28 28 – Ext 1143103 o bien envíanos un correo a: <a href="mailto:rocio.salinas@axa-assistance.com.co">Rocio.salinas@axa-assistance.com.co</a>',
  es_km:
    'Si deseas obtener información adicional comunícate a nuestra línea de Servicio al Cliente: 01800 681 5696 | +52 55 4211 1951 o bien envíanos un correo a: <a href="mailto:asistenciaenviaje@axa-assistance.com.mx">asistenciaenviaje@axa-assistance.com.mx</a>',
  es_mx:
    'Si deseas obtener información adicional comunícate a nuestra línea de Servicio al Cliente: 01800 681 5696 | +52 55 4211 1951 o bien envíanos un correo a: <a href="mailto:asistenciaenviaje@axa-assistance.com.mx">asistenciaenviaje@axa-assistance.com.mx</a>',
};

export const QUESTION_2 = {
  es_kc: '¿Cómo es el pago del plan de asistencia?',
  es_km: '¿Hay países excluidos de la cobertura de la Asistencia en Viaje?',
  es_mx: '¿Hay países excluidos de la cobertura de la Asistencia en Viaje?',
};

export const ANSWER_2 = {
  es_kc:
    'Puedes realizar el pago desde Aquí con tu tarjeta de crédito o puedes visitarnos en nuestras oficinas.',
  es_km: 'Los únicos países excluidos son Siria y Cuba. Todos los demás países están incluidos en nuestros bloques de destinos.',
  es_mx:
    'Los únicos países excluidos son Siria y Cuba. Todos los demás países están incluidos en nuestros bloques de destinos.',
};

export const QUESTION_3 = {
  es_kc: '¿Puedo realizar algún cambio en las fechas de viaje?',
  es_km: '¿Cómo es el pago de la póliza?',
  es_mx: '¿Cómo es el pago de la póliza?',
};

export const ANSWER_3 = {
  es_kc:
    'Sí, debes comunicarte con nuestra línea de atención al cliente para informar el cambio de fechas, es importante recordar que solo puedes hacerlo antes de que inicie vigencia el producto de asistencia.',
  es_km: 'Aceptamos transferencias bancarias y pagos con tarjetas de crédito.',
  es_mx: 'Aceptamos transferencias bancarias y pagos con tarjetas de crédito.',
};

export const FAQ_BUTTON = {
  es_kc: 'Ir al Faq',
  es_km: 'Ir al Faq',
  es_mx: 'Ir al Faq',
};

export const TITLE_FAQ = {
  es_kc: 'FAQ',
  es_km: 'FAQ',
  es_mx: 'FAQ',
};

export const LINK_FAQS = {
  es_kc: '/faqs',
  es_km: '/faq',
  es_mx: '/faq',
};

export const BTN_CONTINUE = {
  es_kc: 'Editar Cotización',
  es_km: 'Editar Cotización',
  es_mx: 'Editar Cotización',
};

export const BTN_SUBMIT = {
  es_kc: 'Cotizar',
  es_km: 'Cotizar',
  es_mx: 'Cotizar',
};

export const LINK_FORM_COTIZAR = {
  es_kc: '/cotizar-asistencia/planes',
  es_km: '/cotizar-asistencia/planes',
  es_mx: '/cotizar-asistencia/planes',
};

export const ERROR_LABEL = {
  es_kc: 'La búsqueda no arrojó resultados.',
  es_km: 'La búsqueda no arrojó resultados.',
  es_mx: 'La búsqueda no arrojó resultados.',
};

export const PAGE_NAME_GTM = {
  es_kc: 'Cotizar Asistencia',
  es_km: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
  es_mx: 'Obtenga una cotización ahora | Seguro de viaje | AXA Assist',
};

export const CLOSE = {
  es_kc: 'Cerrar',
  es_km: 'Cerrar',
  es_mx: 'Cerrar',
};
