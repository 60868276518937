export const BTN_TEXT_QUOTE = {
  es_kc: 'COTIZAR',
  es_km: 'COTIZAR',
  es_mx: 'COTIZAR',
};

export const READ_MORE = {
  es_kc: 'Leer más',
  es_km: 'Leer más',
  es_mx: 'Leer más',
};

export const PAGE_NAME_GTM = {
  es_kc: '',
  es_km: 'Blog de seguros de viaje | AXA Assistance Argentina',
  es_mx: 'Blog de seguros de viaje | AXA Assistance Mexico',
};
