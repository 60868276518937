import { BenefitsPrismicService } from './prismic';

export default (axios, api) => ({
  getBenefitsToHome: async (idiom = 'es-km') => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };

    const result = await BenefitsPrismicService.getBenefits(headers);
    return result;
  },

  getBenefitByBenefitCode: async (idiom = 'es-km', benefitCode) => {
    const headers = {
      'Accept-Language': 'es-km',
      'Content-type': 'application/json',
    };
    const result = await BenefitsPrismicService.getBenefitByBenefitCode(
      headers,
      benefitCode,
    );
    return result;
  },
});
