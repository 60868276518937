import React, { useState } from 'react';

import styles from '../PaymentForm.module.scss';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';

function PaymentFactura(props) {
  const { values, intl } = props;
  const [showFactura, setShowFactura] = useState(values.payment.isFatura);

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className={styles.checkAreaFactura}>
              <div className={styles.checkboxFactura}>
                <CheckBox
                  type="checkbox"
                  value={values.payment.isFatura}
                  onClick={() => {
                    values.payment.isFatura = !values.payment.isFatura;
                    setShowFactura(values.payment.isFatura);
                  }}
                />
              </div>
              <p className={styles.description}>
                {intl.LABEL_OPTIONAL_CONTENT}
              </p>
            </div>
          </div>
        </div>
        {showFactura && (
          <div className={`row ${styles.infoFactura}`}>
            <div
              className="col-12"
              dangerouslySetInnerHTML={{
                __html: intl.INFO_FACTURA,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentFactura;
