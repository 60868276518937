import { CARD } from '../../constants';

export const formInitialValues = (idiom, quote) => {
  const viajeiros = [];
  for (let index = 0; index < quote.numberTraleversInput; index++) {
    const element = {
      nombre: '',
      apelido: '',
      email: '',
      confirm_email: '',
      mobile: '',
      numer_identification: '',
      date_of_birth: '',
      age: quote.Ages[index].value,
      index,
      address: '',
      city: '',
      zip_code: '',
      state_of_residence: '',
    };
    viajeiros.push(element);
  }

  const payment = {
    nombre: '',
    postalCode: '',
    number: '',
    date: '',
    cvc: '',
    tarjeta: CARD,
    isFatura: false,
    paypalPaymentId: null,
    payerID: null,
    installments: 1,
  };

  const recaptcha = {
    recaptcha: '',
  };

  return { viajeiros, payment, recaptcha };
};
