import React, { useContext } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import styles from './TripArea.module.scss';
import Select from '../../../../Select/Select';

function TripArea(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, banner } = props;

  return (
    <div className={`row ${styles.rentalPeriodlArea}`}>
      <div className="col-xl-6 col-sm-6 col-12">
        <label className={styles.labelTitle}>{intl.COUNTRY_ORIGIN}</label>
        <Select
          dataTestid="selectCountryResidence"
          name="Quote.AmountCurrencyISOCode"
          label={intl.LABEL_NAME}
          disabled
          errorNoMessage={banner}
        >
          <option>{values.Quote.AmountCurrencyISOCode}</option>
        </Select>
      </div>
      <div className="col-xl-6 col-sm-6 col-12">
        <label className={styles.labelTitle}>{intl.COUNTRY_DESTINATION}</label>
        <Select
          dataTestid="selectCountryDestiny"
          label={intl.LABEL_SELECT}
          name="Quote.DestinationCountryIsoCode"
          dangerouslySetInnerHTML={{ __html: values.Quote.TripType == 'Annual' ? intl.NATIONALITY_OPTIONS_MULTI : intl.NATIONALITY_OPTIONS_SINGLE }}
          formikProps={props}
          errorNoMessage={banner}
        />
      </div>
    </div>
  );
}

export default TripArea;
