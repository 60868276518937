export const TEXT_TITLE_BENEFIT = {
  es_kc: 'Beneficio',
  es_km: 'Beneficio',
  es_mx: 'Beneficio',
};

export const TEXT_TITLE_COVERAGE = {
  es_kc: 'Cobertura',
  es_km: 'Cobertura',
  es_mx: 'Cobertura',
};
export const TEXT_TITLE_PLANE_PLTNM = {
  es_kc: '',
  es_km: 'Platinum',
  es_mx: 'Platinum',
};
export const TEXT_TITLE_PLANE_GLD = {
  es_kc: '',
  es_km: 'Gold',
  es_mx: 'Gold',
};
export const TEXT_TITLE_PLANE_SILV = {
  es_kc: '',
  es_km: 'Silver',
  es_mx: 'Silver',
};
export const TEXT_TITLE_PLANE_MULTI = {
  es_kc: '',
  es_km: 'Multiviage Anual',
  es_mx: 'Multiviage Anual',
};
export const TEXT_TITLE_PLANE_BLCK = {
  es_kc: '',
  es_km: 'Black',
  es_mx: 'Black',
};

export const TEXT_COMPARE = {
  es_kc: 'Consulta el detalle de nuestras coberturas y Términos & Condiciones',
  es_km: 'Consulta el detalle de nuestras coberturas y Términos & Condiciones',
  es_mx: 'Consulta el detalle de nuestras coberturas y Términos & Condiciones',
};
