import React, { useState, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import styles from './Footer.module.scss';
import { LocationContext } from '../Location/Location';
import FooterOptions from './Footer_Text';
import CenterOptions from './Options/CenterOptions/CenterOptions';
import LeftOptions from './Options/LeftOptions/LeftOptions';
import RightOptions from './Options/RightOptions/RightOptions';
import TextDataObject from './TextDataObject.json';
import { PhoneService } from '../../services';
import { FOOTER } from '../../utils/category';

const renderPhoneAndImages = async (setPhone, idiomAPI) => {
  try {
    const phone = await PhoneService.getPhone(idiomAPI, FOOTER);
    if (phone) {
      setPhone(phone);
    }
  } catch (error) {
    console.log('Error', error);
  }
};

function Footer(props) {
  const { location } = props;
  const [textData, setTextData] = useState(TextDataObject);
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { left, center, right, down } = textData;
  const [ismobile, setIsmobile] = useState(isMobile);
  const [specificPage, setSpecificPage] = useState(false);

  const [phone, setPhone] = useState();

  useEffect(() => {
    const isEspecificPage = location && location.pathname === '/clubmedterms';
    setSpecificPage(isEspecificPage);
    renderPhoneAndImages(setPhone, idiomAPI);
  }, [idiom, location]);

  const BottomInfo = (
    <div className={styles.bottomInfo}>
      {!specificPage && (
        <p data-testid="textDown" className={styles.text}>
          {phone && phone.content}
        </p>
      )}
    </div>
  );

  useEffect(() => {
    setTextData(FooterOptions[idiom]);
  });

  return (
    <footer className={`container-fluid ${styles.footer}`}>
      <div className={`row ${styles.footerUp}`}>
        <LeftOptions textJSON={left} />
        {!specificPage && <CenterOptions />}
        {phone && !specificPage && (
          <RightOptions textJSON={right} phone={phone} ismobile={ismobile} />
        )}

        <div className={`col-12 ${styles.footerDown}`}>{BottomInfo}</div>
      </div>
    </footer>
  );
}

export default withRouter(Footer);
