import React from 'react';

const mxContent = [
  {
    id: 1,
    title: '50% de descuento',
    content: (
      <div>
        <p>
          Empieza a planear tu viaje y disfruta tus vacaciones o tu intercambio
          aprovechando esta súper promoción de 50% de descuento en la
          contratación de tu póliza de asistencia. Te brindaremos a ti y a tu
          familia la mejor protección ante cualquier imprevisto sin importar en
          donde te encuentres*, con atención personalizada las 24 horas del día.
          *Promoción ya aplicada a los productos mostrados.
          <strong>
            Únicamente válida en productos de estudiante, Gold y Platinum
          </strong>
        </p>
      </div>
    ),
    image:
      'https://images.prismic.io/b2c-axa/f70f7f1c-4c9a-4b67-b6af-b3e94243686f_newtest.png',
  },
  {
    id: 2,
    title: 'Pagos a meses sin intereses con Paypal',
    content: (
      <p>
        ¿La cuesta de enero te tiene muy gastado? ¡No te preocupes! En compras
        mayores a $500 MXN en cualquiera de nuestros productos, te ofrecemos el
        beneficio de diferir tus pagos a meses sin intereses pagando con PAYPAL.
        Aprovecha esta promoción para seguir viajando con la tranquilidad de
        estar asegurado ante cualquier tipo de incidente que pueda minimizar tu
        experiencia.
      </p>
    ),
    image:
      'https://images.prismic.io/b2c-axa/e81a3fe6-1acb-46bf-8cf6-6a278b6188b0_newtest2.png',
  },
  {
    id: 3,
    title: 'Regalos exclusivos',
    content: (
      <p>
        ¿Sabías que puedes recibir regalos exclusivos por ser cliente AXA?
        ¡mucho ojo a nuestras promociones! No te quedes con las manos vacías,
        contrata tu asistencia con la mayor cobertura y al mejor precio y
        podrías ganar desde baterías externas hasta participaciones en sorteos
        para ganar boletos de avión redondos a las playas más increíbles de
        México.
      </p>
    ),
    image:
      'https://images.prismic.io/b2c-axa/c4062ce5-93f7-406a-b54b-30d2611770b6_newtest3.png',
  },
];

export default mxContent;
