import React, { useEffect, useState, useContext } from 'react';

import scriptLoader from 'react-async-script-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';
import styles from './CotizarAsistencia.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import banner from '../../assets/img/cabecera.png';
import { LocationContext } from '../../components/Location/Location';
import CotizarFormStep1 from '../../components/CotizarFormStep1/CotizarFormStep1';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import CotizarStep3 from './CotizarStep3/CotizarStep3';
import CotizarFormStep2 from '../../components/CotizarFormStep2/CotizarFormStep2';
import { step as stepCotizarAction } from '../../actions';
import Steps from '../../components/Steps/Steps';
import Button from '../../components/Button/Button';
import CotizarStep4 from '../../components/CotizarStep4/CotizarStep4';
import ModalOptionalBenefits from './CotizarStep3/Modal/ModalOptionalBenefits';

function CotizarAsistencia() {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const { translate, getCountryPrefix } = useContext(IntlContext);
  const intl = translate(translations);
  const { idiom, idiomAPI } = useContext(LocationContext);
  const { utils, actions } = useContext(StateContext);
  const [reserve, setReserve] = useState(null);

  const step1 = utils.getStep() === 1;
  const step2 = utils.getStep() === 2;
  const step3 = utils.getStep() === 3;
  const step4 = utils.getStep() === 4;
  let infoStep1;
  let infoStep2;
  let infoStep3;
  if (step3) {
    infoStep1 = utils.getFormDataStep1();
    infoStep2 = utils.getFormDataStep2();
    infoStep3 = utils.getFormDataStep3();
  }

  useEffect(() => {
    setLoading(true);
    setStep(utils.getStep());
    setLoading(false);
  }, [idiom, utils.getStep()]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const title = [
    ,
    intl.TITLE_STEP1,
    intl.TITLE_STEP2,
    intl.TITLE_STEP3,
    intl.TITLE_STEP3,
  ];

  const returnPreviousStep = () => {
    actions.step.setStep(step - 1);
  };

  const labelPreviousStep = () => (step2 ? intl.BTN_EDIT_TRIP_DETAILS : intl.BTN_EDIT_PLAN);

  const greatForm = step2 ? styles.greater : '';
  let className = `${greatForm}`;
  className += ` ${styles.form}`;
  return (
    <div className={styles.cotizar}>
      {step3 && infoStep3.data.optionalBenefits.length > 0 && (
        <ModalOptionalBenefits
          step1={infoStep1}
          step2={infoStep2}
          step3={infoStep3}
          setLoading={setLoading}
        />
      )}
      <div className={styles.wrapBanner}>
        <div className={styles.cotizarAsistencia}>
          {loading && <LoadingCircle />}
          <div className={className}>
            {step1 && <Redirect to="/" />}
            {step2 && <CotizarFormStep2 setLoading={setLoading} />}
            {step3 && (
              <CotizarStep3
                formType={idiom}
                setLoading={setLoading}
                setReserve={setReserve}
                step1={infoStep1}
                step2={infoStep2}
                step3={infoStep3}
              />
            )}
            {step4 && (
              <CotizarStep4
                formType={idiom}
                setLoading={setLoading}
                reserve={reserve}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CotizarAsistencia;
