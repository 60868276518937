import React from 'react';

import styles from './Button.module.scss';

function Button({
  blogsList,
  blue,
  children,
  css,
  danger,
  dataTestid,
  disabled,
  formSubmitRefs,
  homeBanner,
  iconButton,
  itemList,
  moreBenefits,
  onClick,
  orange,
  red,
  success,
  transparent,
  type,
  value,
  white,
  whiteBlue,
  whiteRed,
  yellow,
  step1Banner,
  hoursBanner,
}) {
  let className = `${styles.btn} ${css}`;
  if (danger === true) className += ` ${styles.btnDanger}`;
  if (success === true) className += ` ${styles.btnSuccess}`;
  if (itemList === true) className += ` ${styles.btnItemList}`;
  if (white === true) className += ` ${styles.btnWhite}`;
  if (yellow === true) className += ` ${styles.btnYellow}`;
  if (blue === true) className += ` ${styles.btnBlue}`;
  if (red === true) className += ` ${styles.btnRed}`;
  if (orange === true) className += ` ${styles.btnOrange}`;
  if (transparent === true) className += ` ${styles.btnTransparent}`;
  if (whiteBlue === true) className += ` ${styles.btnWhiteBlue}`;
  if (whiteRed === true) className += ` ${styles.whiteRed}`;
  if (moreBenefits === true) className += ` ${styles.btnMoreBenefits}`;
  if (blogsList === true) className += ` ${styles.btnBlogsList}`;
  if (iconButton === true) className += ` ${styles.btnIconButton}`;
  if (homeBanner === true) className += ` ${styles.btnHomeBanner}`;
  if (step1Banner === true) className += ` ${styles.btnStep1Banner}`;
  if (hoursBanner === true) className += ` ${styles.btnHoursBanner}`;

  return (
    <button
      className={className}
      disabled={disabled}
      type={type}
      onClick={onClick}
      data-testid={dataTestid}
      ref={formSubmitRefs}
    >
      {value}
      {children && children}
    </button>
  );
}

export default Button;
