import React, { useState, useEffect } from 'react';

import { getCookie, setCookie } from '../../utils/cookies';
import {
  prefixKm,
  prefixKc,
  prefixMx,
  langKm,
  langKc,
  langMx,
} from '../../location/urls';

import { config } from '../../config';

export const LocationContext = React.createContext({
  idiom: '',
  idiomAPI: '',
  country: '',
});

const initialLocalion = (_setCountry, _setIdiom, _setIdiomAPI) => {
  let country = getCookie('country');
  let idiom = getCookie('idiom');
  const domainName = document.location.hostname;
  const pathName = document.location.pathname;

  if (!country || !idiom) {
    switch (domainName) {
      case config.REACT_APP_URL_MX:
        country = prefixMx;
        idiom = langMx;
        break;
      case config.REACT_APP_URL_KM:
        country = prefixKm;
        idiom = langKm;
        break;
      case config.REACT_APP_URL_KC:
        country = prefixKc;
        idiom = langKc;
        break;
      default:
        country = prefixKm;
        idiom = langKm;
        break;
    }
  }
  setCookie('country', country);
  setCookie('idiom', idiom);
  _setCountry(country);
  _setIdiom(idiom);
  _setIdiomAPI(idiom.replace('_', '-'));
};

function Location({ children }) {
  const [idiom, _setIdiom] = useState('');
  const [country, _setCountry] = useState('');
  const [idiomAPI, _setIdiomAPI] = useState('');

  useEffect(() => {
    initialLocalion(_setCountry, _setIdiom, _setIdiomAPI);
  }, []); //eslint-disable-line

  return (
    country
    && idiom && (
      <LocationContext.Provider
        value={{
          country,
          idiom,
          idiomAPI,
        }}
      >
        {children}
      </LocationContext.Provider>
    )
  );
}

export default Location;
