import { formatEs } from './formatData';
import { CotizarService } from '../../../../../../../services';

const getProducts = data => (data.QuoteKAYAK && data.QuoteKAYAK.ProductKAYAK
  ? data.QuoteKAYAK.ProductKAYAK
  : undefined);

export const submitBirthDate = async (
  product,
  quote,
  token,
  benefitsDetailId,
  idiom,
  updateAmount,
  indexPerson,
  newDate,
  newAge,
  changePolicyHolder,
) => {
  const objComplete = formatEs(
    product,
    quote,
    token,
    benefitsDetailId,
    idiom,
    indexPerson,
    newDate,
    newAge,
  );
  const idProduct = product.Product.Id;
  const existsOptionalBenefit = benefitsDetailId.length > 0;
  let productWithoutOptionalBenefits = null;
  try {
    const response = await CotizarService.step3CotizarOptionalBenefit(
      idiom,
      objComplete,
    );

    if (existsOptionalBenefit) {
      const objWithoutOptionalBenefits = formatEs(
        product,
        quote,
        token,
        [],
        idiom,
        indexPerson,
        newDate,
        newAge,
        changePolicyHolder,
      );

      const responseWithoutBenefits = await CotizarService.step3CotizarOptionalBenefit(
        idiom,
        objWithoutOptionalBenefits,
      );
      productWithoutOptionalBenefits = getProducts(
        responseWithoutBenefits.data,
      ).find(({ Product }) => Product.Id === idProduct);
    }

    const productUpdated = getProducts(response.data).find(
      ({ Product }) => Product.Id === idProduct,
    );
    if (productWithoutOptionalBenefits) {
      updateAmount(
        productUpdated.Product.Amount,
        productWithoutOptionalBenefits.Product.Amount,
      );
    } else {
      updateAmount(productUpdated.Product.Amount);
    }
  } catch (error) {
    console.log(error);
  }
};
