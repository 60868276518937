import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Slider from 'react-slick';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import LoadingCircle from '../../components/LoadingCircle/LoadingCircle';
import { PlanesService } from '../../services';
import PlanesItem from './PlanesItem/PlanesItem';
import styles from './Planes.module.scss';
import { PLANES } from '../../utils/category';
import Banner from '../../components/Banner/Banner';
import { LocationContext } from '../../components/Location/Location';
import { dataLayerTrack } from '../../utils/GTM_helper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const getNumberSlideToShow = list => {
  if (!list) return 0;
  return list.length > 3 ? 3 : list.length;
};

function Arrow({ direction, onClick }) {
  const classArrow = direction === 'left' ? styles.btnLeft : styles.btnRight;
  const icon = direction === 'left' ? faChevronLeft : faChevronRight;
  return <FontAwesomeIcon className={`${styles.btnArrow} ${classArrow}`} onClick={onClick} icon={icon} color="#009ae5" />;
}

function Planes() {
  const [loading, setLoading] = useState(true);
  const [planes, setPlanes] = useState([]);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const response = await Promise.resolve(
          PlanesService.getPlanesList(idiomAPI),
        );
        setPlanes(response);
      } catch (err) {
        setPlanes([]);
      } finally {
        setLoading(false);
      }
    };
    if (idiom) loadData();
  }, [idiom]);

  useEffect(() => {
    dataLayerTrack(intl, idiom, country, location);
  }, []);

  const mappedPlanes = planes.map((currentValue) => (
    <PlanesItem
      country={country}
      key={currentValue.id}
      value={currentValue}
    />
  ));

  const settings = {
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 300,
    slidesToShow: getNumberSlideToShow(mappedPlanes),
    slidesToScroll: 1,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          speed: 300,
          slidesToScroll: 1,
          slidesToShow: 3,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          speed: 300,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          nextArrow: <Arrow direction="right" />,
          prevArrow: <Arrow direction="left" />,
        },
      },
    ],
  };

  if (loading) {
    return (
      <h1 className={styles.loading}>
        <LoadingCircle />
      </h1>
    );
  }

  return (
    <div className={`${styles.planes}`} data-testid="pagePlanes">
      <Banner datatestid="bannerPlanes" category={PLANES} />
      <div className={styles.planesHeader}>
        <h4>{intl.TEXT_TITLE}</h4>
        <p dangerouslySetInnerHTML={{ __html: intl.TEXT_DESCRIPTION }} />
      </div>
      <div className={`col-12 ${styles.content}`}>
        {planes.length > 0 && <Slider {...settings}>{mappedPlanes}</Slider>}
      </div>
    </div>
  );
}

export default Planes;
