import bannerKC from '../../assets/img/faq/banner.jpg';

export const BANNER_IMG = {
  es_kc: bannerKC,
  es_km: bannerKC,
  es_mx: bannerKC,
};

export const BANNER_TITLE = {
  es_kc: 'Preguntas Frecuentes',
  es_km: 'Preguntas Frecuentes',
  es_mx: 'Preguntas Frecuentes',
};

export const BANNER_CONTENT = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const WRAP_QUESTIONS_TITLE = {
  es_kc: 'Preguntas Frecuentes',
  es_km: 'Preguntas Frecuentes',
  es_mx: 'Preguntas Frecuentes',
};

export const PAGE_NAME_GTM = {
  es_kc: '',
  es_km: 'Seguro de viaje | Preguntas frecuentes | AXA Assistance Mex',
  es_mx: 'Seguro de viaje | Preguntas frecuentes | AXA Assistance Mex',
};
