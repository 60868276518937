import React, { useState, useContext, useEffect } from 'react';

import PaypalExpressBtn from 'react-paypal-express-checkout';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import Button from '../Button/Button';
import styles from './PayPalPaymentButtonV1.module.scss';
import { getClientId, getSecretId } from '../../utils/paypal';

function PayPalPaymentButtonV1(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [paypalButton, setPaypalButton] = useState();
  const {
    data,
    setLoading,
    updateParent,
    formSubmitRefs,
    stateForm,
    schemas,
    idiom,
    aceptLosTermos,
  } = props;
  const paypalClientId = getClientId(idiom);
  const paypalSecretId = getSecretId(idiom);

  const {
    amount, promotion, amountWithBenefits, currency,
  } = data;
  const env = process.env.REACT_APP_PAYPAL_TYPE_ENV;
  const realAmount = amountWithBenefits || amount;
  const total = promotion ? realAmount - promotion : realAmount;
  const client = {
    sandbox: paypalClientId,
    production: paypalClientId,
  };

  const onSuccess = (payment) => {
    setLoading(true);
    const cotizar = { ...stateForm };
    cotizar.payment.paypalPaymentId = payment.paymentID;
    cotizar.payment.payerID = payment.payerID;
    updateParent(cotizar, 'none');
    formSubmitRefs.current.click();
    setLoading(false);
  };
  const onCancel = (d) => {};
  const onError = (err) => {};

  const showPayPalbutton = async () => {
    try {
      if (
        aceptLosTermos.aceptoLosTermos === undefined
        || !aceptLosTermos.aceptoLosTermos
      ) {
        aceptLosTermos.setAceptoLosTermos(true);
      }
      await schemas.Cotizar.validate(stateForm);
      setPaypalButton(true);
      return true;
    } catch (error) {
      setPaypalButton(false);
      return false;
    }
  };

  const checkPayment = async () => {
    const t = await showPayPalbutton();
    return t;
  };

  useEffect(() => {
    showPayPalbutton();
  }, [checkPayment]);

  if (!paypalButton) {
    return <Button red value={intl.BTN_ALL_INFO} />;
  }

  return (
    <PaypalExpressBtn
      env={env}
      client={client}
      currency={currency}
      total={total}
      onError={onError}
      onSuccess={onSuccess}
      onCancel={onCancel}
      paymentOptions={{ intent: 'SALE' }}
      style={{
        size: 'responsive',
        color: 'silver',
        shape: 'rect',
        label: 'checkout',
      }}
    />
  );
}

export default PayPalPaymentButtonV1;
