import React, { memo } from 'react';

const ACCESS_TOKEN = process.env.REACT_APP_WIDGET_TOKEN;
const CLIENT_ID = process.env.REACT_APP_WIDGET_CLIENT_ID;
const LANG_CODE = process.env.REACT_APP_WIDGET_LANG;
const PROPOSITION = process.env.REACT_APP_WIDGET_PROPOSITION;
const BASE_URL = process.env.REACT_APP_WIDGET_URL;
const WIDGET_LINK = `${BASE_URL}?c=${CLIENT_ID}&t=${ACCESS_TOKEN}&p=${PROPOSITION}&lang=${LANG_CODE}`;

function SmartDelayWidget() {
  const iframeStyles = {
    width: '320px',
    height: '810px',
    border: 'none',
    display: 'block',
    overflow: 'hidden',
    position: 'sticky',
    top: '100px',
  };
  return (
    <div className="col bg-white br-1 col-lg-4 m-2 min-vh-50">
      { BASE_URL !== '' && BASE_URL.length > 10 && (
        <iframe
          id="iframe"
          title="Ifrme Title"
          style={iframeStyles}
          allowTransparency="true"
          scrolling="no"
          src={WIDGET_LINK}
        />
      )}
    </div>

  );
}

export default memo(SmartDelayWidget);
