export const removeNonNumeric = (txt) => {
  if (txt) return txt.replace(/\D/g, '');
  return '';
};

export const boolToYesOrNo = (value) => (value ? 'yes' : 'no');

export const applyDateMask = (value) => {
  const numbers = removeNonNumeric(value);
  const a = numbers.replace(/(\d{2})(\d{1})/, '$1/$2');
  const b = a.replace(/(\d{2})\/(\d{2})(\d{1})/, '$1/$2/$3');
  return b;
};

export const swapDayWithMonth = (value) => value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');

// *****-****-**-191042813
export const maskCertificateNumber = (value) => `*****-****-**-${value.substr(-9)}`;

export const formatLinkWithIdiomAndCountry = (link, idiom, country) => {
  if (!link) return '';
  const linkFormated = link
    .replace('{{country_code}}', country)
    .replace('{{lang}}', idiom);
  return linkFormated;
};

export const validateCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  let result = true;
  [9, 10].forEach((j) => {
    let soma = 0;
    let r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        soma += parseInt(e, 10) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r !== cpf.substring(j, j + 1)) result = false;
  });
  return result;
};

export const removeRequiredCharLabel = (data) => (data && data.slice(-1) === '*'
  ? data.substring(0, data.length - 1)
  : data);

export const removeAllSpecialcharacters = (string) => string.replace(/[^a-zA-Z0-9]/g, '');
