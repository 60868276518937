import { format } from './formatData';
import { CotizarService } from '../../../../../services';
import { trackExceptionMonitor } from '../../../../../AppInsightsTracks';

const getProducts = data => (data.QuoteKAYAK && data.QuoteKAYAK.ProductKAYAK
  ? data.QuoteKAYAK.ProductKAYAK
  : undefined);

export const submitOptionalBenefits = async (
  product,
  quote,
  token,
  benefitsDetailId,
  idiom,
  confirmAction,
  updateAmount,
) => {
  const objComplete = format(product, quote, token, benefitsDetailId, idiom);
  const idProduct = product.Product.Id;
  try {
    const response = await CotizarService.step3CotizarOptionalBenefit(
      idiom,
      objComplete,
    );
    const productUpdated = getProducts(response.data).find(
      ({ Product }) => Product.Id === idProduct,
    );
    updateAmount(productUpdated.Product.Amount, benefitsDetailId.length > 0);
    if (confirmAction) {
      await confirmAction();
    }
  } catch (error) {
    const errorObject = JSON.parse(JSON.stringify(error));
    trackExceptionMonitor('submitOptionalBenefits - Step3', error, errorObject);
  }
};
