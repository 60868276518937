import { HOME, ALL } from '../utils/category';
import { BlogPrismicService } from './prismic';

export default (axios, api) => ({
  getBlogList: async (idiom = 'es-km', category = ALL) => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };
    const result = await BlogPrismicService.getBlogs(headers, category);
    return result;
  },

  getBlogDetail: async (idiom = 'es-km', codeSeo) => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };
    const result = await BlogPrismicService.getBlogById(headers, codeSeo);
    return result;
  },
});
