import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import styles from './HeaderStep.module.scss';
import { IntlContext } from '../../intl/index';
import * as translations from './intl';
import { StateContext } from '../StateContextParent/StateContextParent';

function HeaderStep(props) {
  const { customIntl, step: currentStep, history } = props;
  const { translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const intl = translate(translations);

  const checkStep = (step) => {
    if (step === currentStep) {
      return styles.stepSelected;
    } if (step < currentStep) {
      return styles.stepDone;
    }
    return styles.stepBlocked;
  };

  const checkBar = (bar) => {
    if (bar < currentStep) {
      return styles.barDone;
    }
    return styles.barBlocked;
  };

  const clickStep2 = () => {
    actions.step.setStep(2);
    history.push(intl.LINK_FORM_COTIZAR);
  };

  return (
    <div className={styles.stepContainer}>
      <div className={`${styles.step} ${checkStep(1)}`}>
        <span className={styles.dot}>1</span>
        {!customIntl ? intl.LABEL_TRIP_1 : customIntl.CUSTOM_TXT_STEP_1}
      </div>

      <hr className={`${styles.bar} ${checkBar(1)}`} />

      {!customIntl ? (
        <div className={`${styles.step} ${checkStep(2)}`} onClick={clickStep2}>
          <span className={styles.dot}>2</span>
          <span className={styles.clickable}>{intl.LABEL_PLAN_2}</span>
        </div>
      ) : (
        <div className={`${styles.step} ${checkStep(2)}`}>
          <span className={styles.dot}>2</span>
          <span>{customIntl.CUSTOM_TXT_STEP_2}</span>
        </div>
      )}

      <hr className={`${styles.bar} ${checkBar(2)}`} />

      <div className={`${styles.step} ${checkStep(3)}`}>
        <span className={styles.dot}>3</span>
        {!customIntl ? intl.LABEL_TRAVELERS_3 : customIntl.CUSTOM_TXT_STEP_3}
      </div>

      {!customIntl && (
        <>
          <hr className={`${styles.bar} ${checkBar(3)}`} />

          <div className={`${styles.step} ${checkStep(4)}`}>
            <span className={styles.dot}>4</span>
            {intl.LABEL_PROTEGIDO_4}
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(HeaderStep);
