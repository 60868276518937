import {
  langKc, langKm, langMx,
} from '../../../../location/urls';

export const formInitialValues = (idiom, form1) => {
  let country;

  switch (idiom) {
    case langKc:
      country = 'Colombia';
      break;

    case langKm:
      country = 'Mexico';
      break;

    case langMx:
      country = 'Mexico';
      break;

    default:
      country = 'Colombia';
      break;
  }

  let Quote = {
    AmountCurrencyISOCode: country,
    DestinationCountryIsoCode: '',
    TripInitialDate: '',
    TripEndDate: '',
    Ages: [
      {
        value: '',
      },
    ],
    IncludesEurope: false,
    numberTraleversInput: 1,
    recaptcha: '',
  };

  if (form1 && form1.Quote) {
    Quote = form1.Quote;
    Quote.TripInitialDate = new Date(form1.Quote.TripInitialDate);
    Quote.TripEndDate = new Date(form1.Quote.TripEndDate);
  }

  return { Quote };
};
