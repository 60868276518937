export const LABEL_NOMBRE = {
  es_kc: 'Nombre Completo (Como aparece en tarjeta) *',
  es_km: 'Nombre Completo (Como aparece en tarjeta) *',
  es_mx: 'Nombre Completo (Como aparece en tarjeta) *',
};

export const POSTAL_CODE = {
  es_kc: 'Codigo Postal *',
  es_km: 'Codigo Postal *',
  es_mx: 'Codigo Postal *',
};

export const NUMBER_TARJETA = {
  es_kc: 'Número de Tarjeta *',
  es_km: 'Número de Tarjeta *',
  es_mx: 'Número de Tarjeta *',
};

export const DATE = {
  es_kc: 'Vencimiento *',
  es_km: 'Vencimiento *',
  es_mx: 'Vencimiento *',
};

export const CVC = {
  es_kc: 'CVC *',
  es_km: 'CVC *',
  es_mx: 'CVC *',
};

export const PAGO = {
  es_kc: 'Pago',
  es_km: 'Pago',
  es_mx: 'Pago',
};

export const PAGO_SEGURO = {
  es_kc: 'PAGO SEGURO',
  es_km: 'PAGO SEGURO',
  es_mx: 'PAGO SEGURO',
};

export const LABEL_OPTIONAL_CONTENT = {
  es_mx: 'Solicitar Factura',
  es_km: 'Solicitar Factura',
};

export const INFO_FACTURA = {
  es_mx:
    '<p>Por favor envíe los siguientes datos:</p><ul><li>RFC</li><li>Nombre completo</li><li>Uso de CFDI</li><li>Número de certificado de póliza</li><li>Correo electrónico</li></ul><br/>Al correo:<br/>facturaasistencia@axa-assistance.com.mx<br/><p>Recuerde que el tiempo límite para procesar su solicitud de facturación es de 30 días.</p>',
  es_km:
    '<p>Por favor envíe los siguientes datos:</p><ul><li>RFC</li><li>Nombre completo</li><li>Uso de CFDI</li><li>Número de certificado de póliza</li><li>Correo electrónico</li></ul><br/>Al correo:<br/>facturaasistencia@axa-assistance.com.mx<br/><p>Recuerde que el tiempo límite para procesar su solicitud de facturación es de 30 días.</p>',
};

export const TEXT_PAYPAL_TERMS = {
  es_kc: '',
  es_km:
    'Si deseas continuar con este método de pago deberás aceptar los Términos y Condiciones de AXA y dar click en "Completar pago". Automáticamente serás redirigido de manera segura a la página de PAYPAL para finalizar tu compra.',
  es_mx:
    'Si deseas continuar con este método de pago deberás aceptar los Términos y Condiciones de AXA y dar click en "Completar pago". Automáticamente serás redirigido de manera segura a la página de PAYPAL para finalizar tu compra.',
};

export const CARD_TYPE = {
  es_kc: '',
  es_km: '',
  es_mx: '',
};

export const NUMERO_PAGOS = {
  es_kc: '',
  es_km: 'Número de cuotas (solo tarjetas de crédito) *',
  es_mx: 'Número de cuotas (solo tarjetas de crédito) *',
};

export const CONTENT_CHECK1 = {
  es_kc: 'Deseo recibir <span>noticias y promociones</span> exclusivas',
  es_km: 'Deseo recibir <span>noticias y promociones</span> exclusivas',
  es_mx: 'Deseo recibir <span>noticias y promociones</span> exclusivas',
};

export const CONTENT_CHECK2 = {
  es_kc: pfd_url => `Acepto los <span><a href=${pfd_url} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span> establecidos para la compra.`,
  es_km: pfd_url => `Acepto los <span><a href=${pfd_url} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span> establecidos para la compra.`,
  es_mx: pfd_url => `Acepto los <span><a href=${pfd_url} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span> establecidos para la compra.`,
};

export const VALIDATE_CHECKBOX = {
  es_kc: 'Debe aceptar los Términos y Condiciones para poder continuar',
  es_km: 'Debe aceptar los Términos y Condiciones para poder continuar',
  es_mx: 'Debe aceptar los Términos y Condiciones para poder continuar',
};

export const COMPLETAR_PAGO = {
  es_kc: (value = '', currency = '') => 'Completar pago',
  es_km: (value = '', currency = '') => 'Completar pago',
  es_mx: (value = '', currency = '') => 'Completar pago',
};

export const TOTAL_APLICADO = {
  es_kc: 'TOTAL A PAGAR',
  es_km: 'Total a Pagar',
  es_mx: 'Total a Pagar',
};
