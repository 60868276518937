import moment from 'moment';

const formatPartner = 'DD/MM/YYYY';
const formatEn = 'MM/DD/YYYY';

export const dateFormated = (date, idiom) => moment(date).format(formatPartner);

export const formatDateOfBirthByAge = age => moment(new Date())
  .add(age * -1, 'year')
  .format(formatEn);

export const getNumberOfAgeByDate = (date, format = 'MM-DD-YYYY') => moment()
  .diff(moment(date, format), 'years', false)
  .toString();
