import React, { useState, useContext } from 'react';
import moment from 'moment';
import styles from './PersonArea.module.scss';
import * as translations from './intl';
import { LocationContext } from '../../../../../../components/Location/Location';
import { IntlContext } from '../../../../../../intl';
import Input from '../../../../../../components/Input/Input';
import Calendar from '../../../../../../components/Calendar/Calendar';
import { langKc } from '../../../../../../location/urls';
import { getNumberOfAgeByDate } from '../../../../../../utils/date';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { submitBirthDate } from './submitNewDate/index';
import ModalChangeAge from '../../EnDataForm/PersonArea/ModalChangeAge/ModalChangeAge';
import Select from '../../../../../../components/Select/Select';

function PersonArea(props) {
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    _touched,
    _errors,
    index,
    step1,
    step2,
    step3,
    setLoading,
    existsOptionalBenefitSelected,
  } = props;
  const { optionalBenefits } = step3.data;
  const { idiom } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const [showModalChangeAge, setShowModalChangeAge] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const intl = translate(translations);
  const isCO = idiom === 'es_kc';

  const getProduct = ({ data }) => (data.QuoteKAYAK && data.QuoteKAYAK.ProductKAYAK
    ? data.QuoteKAYAK.ProductKAYAK
    : undefined);

  const updateAmount = (amount, amountWithoutBenefits = null) => {
    const form3 = step3;
    if (existsOptionalBenefitSelected) {
      form3.data.amountWithBenefits = amount;
      form3.data.amount = amountWithoutBenefits;
      actions.formDataCotizar.setForm3(form3);
    } else {
      form3.data.amountWithBenefits = undefined;
      form3.data.amount = amount;
      actions.formDataCotizar.setForm3(form3);
    }
  };

  const getBenefitsSelected = () => {
    const ids = [];
    optionalBenefits.forEach(benefit => {
      if (benefit.detailToPlan.selected) {
        ids.push(benefit.DetailId);
      }
    });
    return ids;
  };

  const changeBirthDate = async e => {
    const { target } = e;

    if (target && target.name && target.value) {
      const { value } = e.target;
      const { name } = e.target;
      const { Ages, MinorTrip, MPH } = step1.Quote;

      const isValidDate = moment(value, 'DD-MM-YYYY').isValid();
      let initialAge;
      let changePolicyHolder = false;
      let indexPerson = parseInt(
        name.replace('viajeiros[', '').replace('].birth_date', ''),
        10,
      );
      if (MinorTrip) {
        if (indexPerson === 0) {
          initialAge = MPH;
          changePolicyHolder = true;
        } else {
          indexPerson -= 1;
          initialAge = Ages && Ages[indexPerson] && Ages[indexPerson].value;
        }
      } else {
        initialAge = Ages && Ages[indexPerson] && Ages[indexPerson].value;
      }

      const newAge = getNumberOfAgeByDate(value, 'DD-MM-YYYY');
      if (isValidDate && newAge <= 74) {
        const objProduct = getProduct(step2).find(
          product => product.Product.Id === step3.data.Id,
        );
        const newDate = value;
        setLoading(true);
        try {
          await submitBirthDate(
            objProduct,
            step1.Quote,
            step2.data.Token,
            getBenefitsSelected(),
            idiom,
            updateAmount,
            indexPerson,
            newDate,
            newAge,
            changePolicyHolder,
          );

          if (MinorTrip) {
            if (changePolicyHolder) {
              setFieldValue('viajeiros[0].age', step1.Quote.MPH);
            } else {
              setFieldValue(
                `viajeiros[${indexPerson + 1}].age`,
                step1.Quote.Ages[indexPerson].value,
              );
            }
          } else {
            setFieldValue(
              `viajeiros[${indexPerson}].age`,
              step1.Quote.Ages[indexPerson].value,
            );
          }

          setNewValue(
            existsOptionalBenefitSelected
              ? step3.data.amountWithBenefits
              : step3.data.amount,
          );
          if (initialAge !== newAge) {
            setShowModalChangeAge(true);
          }
        } catch (error) {
          console.warn(error);
        }
        setLoading(false);
      }
    }
  };

  const indexValue = index + 1;
  return (
    <>
      {showModalChangeAge && (
        <ModalChangeAge
          onConfirm={() => setShowModalChangeAge(false)}
          newValue={newValue}
        />
      )}
      <div className={styles.travelerIndex}>
        <label className={styles.labelTitle}>
          <strong>{intl.VIAJERO_COUNT(indexValue)}</strong>
        </label>
      </div>
      <div className={`row ${styles.PersonArea}`}>
        <div className="col-sm-12 col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_NOMBRE}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.viajeiros[index].nombre}
              name={`viajeiros[${index}].nombre`}
              dataTestid={`InputViajeirosNombre-${index}`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              mask="maskedOnlyAlphabets"
              touched={_touched && _touched.nombre}
              error={_errors && _errors.nombre}
            />
          </div>
        </div>

        <div className="col-sm-12 col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_APELIDO}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.viajeiros[index].apelido}
              name={`viajeiros[${index}].apelido`}
              dataTestid={`InputViajeirosNombre-${index}`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              mask="maskedOnlyAlphabets"
              touched={_touched && _touched.apelido}
              error={_errors && _errors.apelido}
            />
          </div>
        </div>

        {indexValue === 1 && (
          <>
            <div className="col-sm-12 col-12">
              <div className="col-12">
                <label className={styles.labelTitle}>
                  {intl.LABEL_ADDRESS}
                </label>
              </div>

              <div className={`col-12 ${styles.inputField}`}>
                <Input
                  value={values.viajeiros[index].address}
                  name={`viajeiros[${index}].address`}
                  dataTestid={`InputTravellersAddress-${index}`}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={_touched && _touched.address}
                  error={_errors && _errors.address}
                />
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="col-12">
                <label className={styles.labelTitle}>{intl.LABEL_CITY}</label>
              </div>

              <div className={`col-12 ${styles.inputField}`}>
                <Input
                  value={values.viajeiros[index].city}
                  name={`viajeiros[${index}].city`}
                  dataTestid={`InputTravellersCity-${index}`}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={_touched && _touched.city}
                  error={_errors && _errors.city}
                  mask="maskedOnlyAlphabets"
                />
              </div>
            </div>

            {!isCO && (
              <div className="col-sm-6 col-12">
                <div className="col-12">
                  <label className={styles.labelTitle}>
                    {intl.LABEL_ZIP_CODE}
                  </label>
                </div>
                <div className={`col-12 ${styles.inputField}`}>
                  <Input
                    value={values.viajeiros[index].zip_code}
                    name={`viajeiros[${index}].zip_code`}
                    dataTestid={`InputTravellersZipCode-${index}`}
                    type="text"
                    onChange={event => {
                      setFieldValue('payment.postalCode', event.target.value);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    touched={_touched && _touched.zip_code}
                    error={_errors && _errors.zip_code}
                    mask="maskedZipCode"
                  />
                </div>
              </div>
            )}

            <div className="col-sm-12 col-12">
              <div className="col-12">
                <label className={styles.labelTitle}>
                  {intl.LABEL_STATE_OF_RESIDENCE}
                </label>
              </div>

              {isCO ? (
                <div className={`col-12 ${styles.inputField}`}>
                  <Select
                    label={intl.LABEL_STATE_OF_RESIDENCE}
                    name={`viajeiros[${index}].state_of_residence`}
                    dataTestid={`InputTravellersStateOfResidence-${index}`}
                    dangerouslySetInnerHTML={{
                      __html: intl.OPTIONS_DEPARTAMENTOS,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={_touched && _touched.state_of_residence}
                    error={_errors && _errors.state_of_residence}
                  />
                </div>
              ) : (
                <div className={`col-12 ${styles.inputField}`}>
                  <Input
                    value={values.viajeiros[index].state_of_residence}
                    name={`viajeiros[${index}].state_of_residence`}
                    dataTestid={`InputTravellersStateOfResidence-${index}`}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={_touched && _touched.state_of_residence}
                    error={_errors && _errors.state_of_residence}
                    mask="maskedOnlyAlphabets"
                  />
                </div>
              )}
            </div>

            <div className="col-sm-12 col-12">
              <div className="col-12">
                <label className={styles.labelTitle}>{intl.LABEL_EMAIL}</label>
              </div>

              <div className={`col-12 ${styles.inputField}`}>
                <Input
                  value={values.viajeiros[index].email}
                  name={`viajeiros[${index}].email`}
                  dataTestid={`InputViajeirosNombre-${index}`}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={_touched && _touched.email}
                  error={_errors && _errors.email}
                />
              </div>
            </div>

            <div className="col-sm-12 col-12">
              <div className="col-12">
                <label className={styles.labelTitle}>
                  {intl.LABEL_EMAIL_CONFIRM}
                </label>
              </div>

              <div className={`col-12 ${styles.inputField}`}>
                <Input
                  value={values.viajeiros[index].confirm_email}
                  name={`viajeiros[${index}].confirm_email`}
                  dataTestid={`InputViajeirosNombre-${index}`}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={_touched && _touched.confirm_email}
                  error={_errors && _errors.confirm_email}
                />
              </div>
            </div>
          </>
        )}

        <div className="col-sm-12 col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_MOBILE}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.viajeiros[index].mobile}
              name={`viajeiros[${index}].mobile`}
              dataTestid={`InputViajeirosNombre-${index}`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              touched={_touched && _touched.mobile}
              error={_errors && _errors.mobile}
              mask="maskedOnlyNumber"
            />
          </div>
        </div>

        <div className="col-sm-6 col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_BIRTH_DATE}</label>
          </div>
          <div className={`col-12 ${styles.inputField}`}>
            <Calendar
              value={values.viajeiros[index].date_of_birth}
              name={`viajeiros[${index}].date_of_birth`}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={_touched && _touched.date_of_birth}
              error={_errors && _errors.date_of_birth}
              setFieldTouched={setFieldTouched}
              maxDate={new Date()}
              extraAction={changeBirthDate}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className="col-sm-6 col-12">
          <div className="col-12">
            <label className={styles.labelTitle}>{intl.LABEL_AGE}</label>
          </div>

          <div className={`col-12 ${styles.inputField}`}>
            <Input
              value={values.viajeiros[index].age}
              name={`viajeiros[${index}].age`}
              dataTestid={`InputViajeirosAge-${index}`}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              touched={_touched && _touched.age}
              error={_errors && _errors.age}
              disabled
            />
          </div>
        </div>

        {idiom === langKc && (
          <div className="col-sm-12 col-12">
            <div className="col-12">
              <label className={styles.labelTitle}>
                {intl.LABEL_IDENTIFICATION}
              </label>
            </div>

            <div className={`col-12 ${styles.inputField}`}>
              <Input
                value={values.viajeiros[index].numer_identification}
                name={`viajeiros[${index}].numer_identification`}
                dataTestid={`InputViajeirosNombre-${index}`}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={_touched && _touched.numer_identification}
                error={_errors && _errors.numer_identification}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PersonArea;
