import React, { useState, useContext, useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
import { withRouter } from 'react-router';

import LoadingCircle from '../../../components/LoadingCircle/LoadingCircle';
import { LocationContext } from '../../../components/Location/Location';
import { BlogService } from '../../../services';
import styles from './BlogDetail.module.scss';
import CotizarFormStep1 from '../../../components/CotizarFormStep1/CotizarFormStep1';
import { htmlSerializer } from '../../../utils/richText';
import { dataLayerTrack } from '../../../utils/GTM_helper';

// TODO social buttons
function BlogDetail({ history, location, match }) {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState({});
  const { idiom, idiomAPI, country } = useContext(LocationContext);
  const { code_seo: codeSeo } = match.params;

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      try {
        const response = await Promise.resolve(
          BlogService.getBlogDetail(idiomAPI, codeSeo),
        );
        setPost(response);
        console.log('date', response);
      } catch (err) {
        setPost({});
        if (err.status_code === 404) {
          history.push('/blog');
        }
      } finally {
        setLoading(false);
      }
    };
    if (idiom) loadData();
  }, [idiom]);

  useEffect(() => {
    if (codeSeo !== null) {
      dataLayerTrack({}, idiom, country, location, codeSeo);
    }
  }, []);

  if (loading) {
    return (
      <h1 className={styles.loading}>
        <LoadingCircle />
      </h1>
    );
  }

  return (
    <div className={`${styles.blogDetail}`} data-testid="pageDetailBlog">
      <div className={styles.wrapBanner}>
        <img src={post.icon_url} alt={post.title} />
      </div>
      <div className={styles.content}>
        {post && (
          <div className={styles.textArea}>
            <h1>{post.title}</h1>
            <p className={styles.publishDate}>{post.last_publication_date}</p>
            <div className={styles.text}>
              <RichText
                render={post.description}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        )}
        <div className={styles.formArea}>
          <div className={styles.form}>
            <CotizarFormStep1 banner formType={idiom} setLoading={setLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(BlogDetail);
