import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import styles from './PersonArea.module.scss';
import Select from '../../../../Select/Select';
import AgeArea from './AgeArea/AgeArea';
import { safeGet } from '../../../../../utils/object';
import Button from '../../../../Button/Button';
import Input from '../../../../Input/Input';

function PersonArea(props) {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [typeOfPlanState, setTypeOfPlanState] = useState('Individual');
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    banner,
  } = props;

  const changeAgePersonItem = (e, numPersons) => {
    let countPerson;
    if (e !== '' && e.target && e.target.value) {
      countPerson = e.target.value;
    } else {
      countPerson = numPersons;
    }
    if (typeOfPlanState == 'Individual' && countPerson > 10) {
      return undefined;
    }
    if (typeOfPlanState == 'Familiar' && countPerson > 5) {
      return undefined;
    }
    const arrayAges = values.Quote.Ages;
    const newArrayAges = [];
    for (let index = 0; index < countPerson; index++) {
      const element = arrayAges[index] ? countPerson === 1 ? {value: ''} : arrayAges[index] : { value: '' };
      newArrayAges.push(element);
    }
    setFieldValue('Quote.Ages', newArrayAges);
    handleChange(e);
    return undefined;
  };

  const mappedAges = values.Quote.Ages.map((currentValue, index) => {
    const errors_s = errors.Quote && errors.Quote.Ages ? errors.Quote.Ages[index] : {};
    const touched_s = touched.Quote && touched.Quote.Ages ? touched.Quote.Ages[index] : {};
    return (
      <AgeArea
        dataTestId="inputAgeTraveller"
        key={`Ages-${index}`}
        _touched={touched_s}
        _errors={errors_s}
        index={index}
        errorNoMessage
        {...props}
      />
    );
  });

  const getErrorGroupMessage = () => {
    if (errors && errors.Quote && errors.Quote.Ages && touched && touched.Quote
      && touched.Quote.Ages) {
      let errorGroup;
      if (Array.isArray(errors.Quote.Ages)) {
        errorGroup = errors.Quote.Ages.find(age => (age && age.value ? age.value : false));
      } else {
        return errors.Quote.Ages;
      }
      return errorGroup ? errorGroup.value : '';
    }
    return undefined;
  };
  const handlePlanType = (e) => {
    const planType = e.target.value;
    handleChange(e);
    setTypeOfPlanState(e.target.value);
    setFieldValue('numberTraleversInput', 1);
    values.Quote.numberTraleversInput = 1;
    changeAgePersonItem({ isTrusted: true }, 1);
  };
  const handlePlusClick = (e) => {
    let numPersons = parseInt(values.Quote.numberTraleversInput, 10);
    let isIncrease = false;
    if (typeOfPlanState == 'Individual' && numPersons < 10) {
      isIncrease = true;
    }
    if (typeOfPlanState == 'Familiar' && numPersons < 5) {
      isIncrease = true;
    }
    if (isIncrease) {
      numPersons += 1;
      values.Quote.numberTraleversInput = numPersons;
      setFieldValue('numberTraleversInput', numPersons);
      changeAgePersonItem(e, numPersons);
    }
  };
  const handleMinusClick = (e) => {
    let numPersons = parseInt(values.Quote.numberTraleversInput, 10);
    if (numPersons > 1) {
      numPersons -= 1;
      values.Quote.numberTraleversInput = numPersons;
      setFieldValue('numberTraleversInput', numPersons);
      changeAgePersonItem(e, numPersons);
    }
  };

  return (
    <div className={`row ${styles.cotPeriodlArea}`}>
      <div className="col-sm-12 col-12">
        <div className="col-12">
          <label className={styles.labelTitle}>{intl.EMAIL}</label>
        </div>

        <div className={`col-12 ${styles.emailField}`}>
          <Input
            value={values.Quote.Email}
            name="Quote.Email"
            dataTestid="inputEmail"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={safeGet(touched, 'Quote.Email')}
            error={safeGet(errors, 'Quote.Email')}
          />
        </div>

        <div className="col-sm-12 col-12">
          <div className="col-14">
            <label className={styles.labelTitle}>{intl.PHONENUMBER}</label>
          </div>
          <div className={'col-14'}>
            <Input
              value={values.Quote.PhoneNumber}
              name="Quote.PhoneNumber"
              dataTestid="inputPhoneNumber"
              type="PhoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              touched={safeGet(touched, 'Quote.PhoneNumber')}
              error={safeGet(errors, 'Quote.PhoneNumber')}
              mask="maskedOnlyNumber"
            />
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-12">
        <div className="col-12">
          <label className={styles.labelTitle}>{intl.TYPE_OF_PLAN}</label>
        </div>
        <div className="col-12">
          <Select
            dataTestid="TypeOfPlan"
            name="Quote.TypeOfPlan"
            label={intl.LABEL_SELECT}
            value={values.Quote.TypeOfPlan}
            errorNoMessage={banner}
            onChange={handlePlanType}
          >
            <option value="Individual">Individual</option>
            <option value="Familiar">Familiar</option>
          </Select>
        </div>
      </div>
      <div className="col-sm-6 col-12">
        <div className="col-6 col-sm-12">
          <label className={styles.labelTitle}>{intl.NUMBER_PERSON}</label>
        </div>

        <div className="col-12 d-flex flex-row">
          <Button type="button" className={'start-0  w-25'} onClick={handleMinusClick} css={`${styles.personsBtn} ${styles.btnAWMinus} `}>
            <FontAwesomeIcon
              icon={faMinus}
              className={`${styles.fontAWMinus}`}
            />
          </Button>
          <div className={`numberTraleversInputDiv ${styles.numberTraleversInputs}`}>
            <Input
              dataTestid="inputNumberTravellers"
              name="Quote.numberTraleversInput"
              value={values.Quote.numberTraleversInput}
              touched={safeGet(touched, 'Quote.numberTraleversInput')}
              onBlur={handleBlur}
              error={safeGet(errors, 'Quote.numberTraleversInput')}
              errorNoMessage
              onChange={changeAgePersonItem}
              className="inputNumTravellersId"
              mask="maskedNumberOfPersons"
            />
          </div>
          <Button className={'end-0  w-25'} type="button" onClick={handlePlusClick} css={`${styles.personsBtn} ${styles.btnAWPlus}`}>
            <FontAwesomeIcon
              icon={faPlus}
              className={`${styles.fontAWPlus}`}
            />
          </Button>
        </div>
      </div>

      <div className="col-12">
        <div className="col-12">
          <label className={styles.labelAgeTitle}>{intl.AGE_PERSON}</label>
        </div>
        <div className="col-12">
          <div className={`row ${styles.agesArea}`}>{mappedAges}</div>
          {!banner && (
            <div className={`row ${styles.agesArea}`}>
              {errors.Quote
                && errors.Quote.Ages
                && errors.Quote.Ages.length
                && touched.Quote
                && touched.Quote.Ages
                && touched.Quote.Ages.length && (
                  <div className={styles.labelErrorGroup}>
                    <span>{getErrorGroupMessage()}</span>
                  </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonArea;
