import * as Yup from 'yup';
import moment from 'moment';
import { langMx, langKc, langKm } from '../../../../../location/urls';
import { CARD, PAYPAL } from '../../constants';

export const validationDataSchema = (intl, idiom) => {
  const object = {};

  object.payment = paymentSchema(intl, idiom);

  object.viajeiros = viajeiroSchema(intl, idiom);

  return Yup.object().shape(object);
};

const viajeiroSchema = (intl, idiom) => {
  const viajeiros = {};

  return Yup.array().of(
    Yup.lazy((value) => {
      const { index } = value;
      const count = index + 1;
      viajeiros.nombre = Yup.string().required(intl.NOMBRE_REQUIRED(count));
      viajeiros.apelido = Yup.string().required(intl.APELIDO_REQUIRED(count));
      viajeiros.mobile = Yup.string().required(intl.CELULAR_REQUIRED(count));
      viajeiros.date_of_birth = Yup.string()
        .test('74+', intl.AGE_NOT_ALLOWED, (date) => {
          const birthDate = moment(date);
          const today = moment(new Date());
          return today.diff(birthDate, 'years') <= 74;
        })
        .required(intl.DATE_OF_BIRTH_REQUIRED(count))
        .nullable();

      if (idiom === langKc) {
        viajeiros.numer_identification = Yup.string().required(
          intl.N_IDENTIFICATION_REQUIRED(count),
        );
      } else {
        viajeiros.numer_identification = Yup.string();
      }
      if (index === 0) {
        viajeiros.email = Yup.string()
          .email(intl.VALIDATE_EMAIL_VALID)
          .required(intl.EMAIL_REQUIRED(count));

        viajeiros.confirm_email = Yup.string()
          .test('confirmEmail', intl.EMAIL_NOT_VALID, function (confirmM) {
            return confirmM === this.parent.email;
          })
          .email(intl.VALIDATE_EMAIL_VALID)
          .required(intl.EMAIL_CONFIRM_REQUIRED(count));

        viajeiros.address = Yup.string().required(
          intl.DIRECTION_REQUIRED(count),
        );
        viajeiros.city = Yup.string().required(intl.CIUDADE_REQUIRED(count));

        if (idiom === langKc) {
          viajeiros.zip_code = Yup.string();
        } else {
          viajeiros.zip_code = Yup.string().required(
            intl.ZIP_CODE_REQUIRED(count),
          );
        }

        viajeiros.state_of_residence = Yup.string().required(
          intl.STATE_REQUIRED(count),
        );
      } else {
        viajeiros.email = Yup.string();
        viajeiros.confirm_email = Yup.string();
        viajeiros.address = Yup.string();
        viajeiros.city = Yup.string();

        viajeiros.zip_code = Yup.string();

        viajeiros.state_of_residence = Yup.string();
      }

      return Yup.object().shape({
        ...viajeiros,
      });
    }),
  );
};

const paymentSchema = (intl, idiom) => {
  const payment = {};

  payment.nombre = Yup.string().when(['tarjeta'], {
    is: (tarjeta) => tarjeta === CARD,
    then: Yup.string().required(intl.PAYMENT_NOMBRE_REQUIRED),
  });
  payment.postalCode = Yup.string().when(['tarjeta'], {
    is: (tarjeta) => tarjeta === CARD,
    then:
      idiom === langKc
        ? Yup.string()
        : Yup.string().required(intl.POSTAL_CODE_REQUIRED),
  });
  payment.number = Yup.string().when(['tarjeta'], {
    is: (tarjeta) => tarjeta === CARD,
    then:
      (idiom === langMx || idiom === langKm)
        ? Yup.string().min(10, intl.TARJETA_MINLENGTH).required(intl.TARJETA_REQUIRED)
        : Yup.string().required(intl.TARJETA_REQUIRED),
  });

  payment.date = Yup.string().when(['tarjeta'], {
    is: (tarjeta) => tarjeta === CARD,
    then: Yup.string().required(intl.VENCIMENTO_DATE_REQUIRED),
  });
  payment.cvc = Yup.string().when(['tarjeta'], {
    is: (tarjeta) => tarjeta === CARD,
    then: Yup.string().required(intl.CVC_REQUIRED),
  });

  return Yup.object().shape({
    ...payment,
  });
};
