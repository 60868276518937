import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './AMEXBeneficios.module.scss';
import amexbeneficios from '../../assets/img/amexbeneficios/amexbeneficios.jpg';
import { LocationContext } from '../../components/Location/Location';
import { dataLayerTrack } from '../../utils/GTM_helper';

function AMEXBeneficios() {
  const { country, idiom } = useContext(LocationContext);
  const location = useLocation();

  useEffect(() => {
    dataLayerTrack(
      {},
      idiom,
      country,
      location,
      'Estas son nuestras amex beneficios | AXA Assistance',
    );
  }, []);
  return (
    <div>
      <img src={amexbeneficios} className={styles.amexBenImg} alt="amexBeneficios" />
    </div>
  );
}

export default AMEXBeneficios;
