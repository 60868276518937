import React, { useState } from 'react';
import styles from './Tooltip.module.scss';

import iconTooltip from '../../assets/img/icon-help.png';

function Tooltip(props) {
  const { content } = props;
  const [show, setShow] = useState(false);

  const showContent = () => {
    setShow(true);
  };

  const hiddenContent = () => {
    setShow(false);
  };

  return (
    <div
      className={styles.tooltip}
      onMouseOver={showContent}
      onFocus={showContent}
      onBlur={hiddenContent}
      onMouseOut={hiddenContent}
      onClick={showContent}
    >
      <img src={iconTooltip} alt="?" />
      {show && <div className={styles.content}>{content}</div>}
    </div>
  );
}

export default Tooltip;
