export const BUTTON_MORE_BENEFITS = {
  es_kc: 'Más Beneficios',
  es_km: 'Más beneficios',
  es_mx: 'Más beneficios',
};

export const MORE_BENEFITS_LINKS = {
  es_kc: '/planes',
  es_km: '/mis-planes',
  es_mx: '/mis-planes',
};
