import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Dropdown.module.scss';

function LinkWrap(props) {
  const { link, children } = props;

  if (link) {
    return <Link to={link}>{children}</Link>;
  }
  return (
    <>
      {' '}
      {children}
      {' '}
    </>
  );
}

function Dropdown(props) {
  const {
    children, title, link, icon, dataTestId,
  } = props;
  const [show, setShow] = useState(false);

  const showMenu = () => setShow(true);
  const hiddenMenu = () => setShow(false);
  const classMenu = show ? styles.block : styles.none;

  return (
    <div
      data-testid={`dropdown-${dataTestId}`}
      className={styles.dropDown}
      onMouseOver={showMenu}
      onFocus={showMenu}
      onBlur={hiddenMenu}
      onMouseOut={hiddenMenu}
    >
      <LinkWrap link={link}>
        <div className={styles.wrapItem}>
          {icon && <img src={icon} alt="icon" />}
          <p data-testid={dataTestId} className={styles.title}>
            {title}
          </p>
        </div>
      </LinkWrap>
      <ul data-testid="menu-dropdown" className={`${styles.menu} ${classMenu}`}>
        {children}
      </ul>
    </div>
  );
}

export default Dropdown;
