import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './PlanesItem.module.scss';
import { prefixKc, prefixMx, prefixKm } from '../../../location/urls';
import * as translations from './intl';
import { IntlContext } from '../../../intl';

const getPrefixUrl = (country) => {
  let prefix = '';

  switch (country) {
    case prefixKc:
      prefix = 'planes';
      break;
    case prefixMx:
      prefix = 'mis-planes';
      break;
    case prefixKm:
      prefix = 'mis-planes';
      break;
    default:
      prefix = 'our-plans';
      break;
  }

  return prefix;
};

function PlanesItem(props) {
  const {
    value: {
      title,
      icon_url: iconUrl,
      shortcontent: shortContent,
      plane_uid: codeSeo,
    },
    country,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const prefixUrl = getPrefixUrl(country);
  const cicle = country === prefixMx || country === prefixKm ? styles.cicle : '';

  const getContent = (content) => (content ? (
    <>
      <div className={styles.planesItemDetailText}>
        {content}
      </div>
      <div className={styles.plansItemBtn}>
        <Link className="btn btn-primary" to={`/${prefixUrl}/${codeSeo}`}>{intl.TEXT_READ_MORE}</Link>
      </div>
    </>
  ) : (
    ''
  ));

  return (
    <div className={` ${styles.planesItem}`}>
      <div className={styles.planesItemImage}>
        <img className={cicle} title={title} alt="" src={iconUrl} />
      </div>
      <div className={styles.planesItemDetail}>
        <div className={styles.planesItemDetailTitle}>
          <h6>{title}</h6>
        </div>
        {getContent(shortContent)}
      </div>
    </div>
  );
}

export default PlanesItem;
