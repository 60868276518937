export const formatWithoutDecimal = (number) => {
  let digits = Number(number).toString();
  digits = digits.substr(0, digits.length - 2);
  return Number(digits);
};

export const formatThousandsWithoutDecimal = (number) => {
  const numberWithoutDecimal = formatWithoutDecimal(number);
  const digits = numberWithoutDecimal.toString();
  const digitsInitial = digits.substr(0, digits.length - 3);
  const digitsFinal = digits.substr(-3, digits.length);
  return digitsInitial.concat('.').concat(digitsFinal);
};
