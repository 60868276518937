/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_INSIGHTS_INSTRUMENTATIONKEY
      || '10210841-fced-448b-920c-1930dd4adfa4',
    disableExceptionTracking: true,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: createBrowserHistory(),
      },
    },
  },
});
ai.loadAppInsights();
const withTracking = withAITracking;
export default (Component) => (withTracking ? withTracking(reactPlugin, Component) : Component);
export const appInsights = ai.appInsights;
export { reactPlugin };
