import moment from 'moment';

const listUSACollision = ['Texas', 'New York', 'Oregon'];
const CollisionDamageWaiver = 'CDW';
export const listUSAMedical = [
  'Connecticut',
  'Illinois',
  'Ohio',
  'South Carolina',
  'New Hampshire',
  'Washington',
];
const PrimaryMedicalCoverage = 'PMU';
const listUSACancel = ['Washington', 'New York', 'New Hampshire'];
const CancelForAnyReason = 'CFA';

export const rulesOptionalBenefitsByCountry = (
  optionalBenefits,
  country,
  Quote,
  products,
) => {
  const cloneList = [...optionalBenefits];
  switch (country) {
    case 'us': {
      if (listUSACollision.includes(Quote.ZipCode)) {
        const index = cloneList.findIndex(
          (item) => item.BenefitCode === CollisionDamageWaiver,
        );
        if (index !== -1) {
          cloneList.splice(index, 1);
        }
      }

      const indexGold = products.findIndex(
        (product) => product.ProductCode === 'GOLD',
      );
      const indexGoldPlus = products.findIndex(
        (product) => product.ProductCode === 'Primary',
      );
      const indexPrimaryMedical = cloneList.findIndex(
        (item) => item.BenefitCode === PrimaryMedicalCoverage,
      );
      if (indexPrimaryMedical !== -1) {
        cloneList[indexPrimaryMedical].isAppear = new Array(cloneList.length);
        if (indexGold !== -1) cloneList[indexPrimaryMedical].isAppear[indexGold] = true;
        if (indexGoldPlus !== -1) cloneList[indexPrimaryMedical].isAppear[indexGoldPlus] = true;
      }
      if (listUSAMedical.includes(Quote.ZipCode)) {
        if (indexGold !== -1) {
          if (indexPrimaryMedical !== -1) {
            cloneList[indexPrimaryMedical].isDefault = [];
            cloneList[indexPrimaryMedical].isDefault[indexGold] = true;
          }
        }
      }

      const indexCancel = cloneList.findIndex(
        (item) => item.BenefitCode === CancelForAnyReason,
      );
      const totalTripConditionZero = Quote.TotalTripCost === '0';

      if (listUSACancel.includes(Quote.ZipCode) || totalTripConditionZero) {
        if (indexCancel !== -1) {
          cloneList.splice(indexCancel, 1);
        }
      } else {
        const departure = moment(new Date(Quote.DepartureDate));
        const deposit = moment(new Date(Quote.DepositDate));
        const today = moment(new Date());

        const diffDepartureDeposit = departure.diff(deposit, 'days');
        const diffDepositToday = deposit.diff(today, 'days');
        if (diffDepositToday < -13 && indexCancel !== -1) {
          cloneList[indexCancel].isAppear = new Array(cloneList.length);
          cloneList[indexCancel].isAppear.forEach((item) => {
            item.isApperar = false;
          });
        }
        if (diffDepartureDeposit <= 3) {
          if (indexCancel !== -1) {
            cloneList.splice(indexCancel, 1);
          }
        }
      }

      return cloneList;
    }
    default:
      return optionalBenefits;
  }
};
