import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const maskedCreditCard = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',

  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '**.****',
];

const bankCodeMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  integerLimit: null,
});

const onlyNumber = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  integerLimit: null,
});

const passportCharacters = (maxLength = 40) => {
  const passport = [];
  for (let i = 0; i < maxLength; i++) {
    passport.push(/[a-zA-Z0-9]/);
  }
  return passport;
};

const notNumbersCharacters = (maxLength = 100) => {
  const passport = [];
  for (let i = 0; i < maxLength; i++) {
    passport.push(/[^0-9]/);
  }
  return passport;
};

const onlyCharacters = (maxLength = 100) => {
  const passport = [];
  for (let i = 0; i < maxLength; i++) {
    passport.push(/^[A-Za-z\s]*$/);
  }
  return passport;
};

export const maskedCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const maskedCNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const maskedPassaport = passportCharacters();
export const maskedPrefixPhone = ['+', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
export const maskedPhone = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedPostalCode = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedPostalCodeBr = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedDigitsBank = [/\d/, /\d/, /\d/, /\d/];
export const maskedBankCode = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedCodeBank = bankCodeMask;
export const maskedOnlyNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedNotNumber = notNumbersCharacters();
export const maskedOnlyAlphabets = onlyCharacters();
export const maskLicenseNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedHour = [/[0-9]/, /\d/, ':', /[0-9]/, /\d/];
export const maskedCVC = [/\d/, /\d/, /\d/, /\d/];
export const maskedAge = [/[1-9]/, /\d/];
export const maskedMonthAndYear = [/\d/, /\d/, '/', /\d/, /\d/];
export const maskedZipCode = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const maskedNumberOfPersons = [/[1-9]|10/, /\d/];
export const maskedCardNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const maskedPhoneContact = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const maskedBin = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const maskedMoney = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  // integerLimit: 5,
  requireDecimal: true,
});
export const maskCardNumberOCT = [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '*', '*', '.', '*', '*', '*', '*', '.', '*', '*', /\d/, /\d/, /\d/, /\d/];
