import React, { useContext, useState } from 'react';
import styles from './ModalBenefits.module.scss';
import Modal from '../../Modal/Modal';
import BenefitRow from '../../CotizarFormStep2/BenefitRow/BenefitRow';
import { LocationContext } from '../../Location/Location';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { getAmountFormat } from '../../../utils/currencyISOCode';
import PlansBtnSelect from '../PlansBtnSelect/PlansBtnSelect';

const renderRowItem = (dataItem, indexProduct) => {
  const content = [dataItem.content[indexProduct]];
  return (
    <li key={dataItem.Id}>
      <BenefitRow
        list={[
          {
            description: dataItem.description,
            tooltip: dataItem.tooltip,
          },
          ...content,
        ]}
      />
      <div className="dropdown-divider" />
    </li>
  );
};

function ModalBenefits({
  optionalBenefits,
  complementaryBenefits,
  benefits,
  indexProduct,
  product,
  showModal,
  setShowModal,
  dataList,
}) {
  const { country, idiom } = useContext(LocationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    Recommended, Name, Amount, AmountWithoutPromotion,
  } = product[
    indexProduct
  ];
  const AmountPromotion = AmountWithoutPromotion !== Amount;
  const symbolAmount = '$';
  const content = benefits && benefits.map(dataItem => (dataItem.content[indexProduct].detail != '- -' ? renderRowItem(dataItem, indexProduct) : ''));

  const contentComplementary = complementaryBenefits
    && complementaryBenefits.map(dataItem => (dataItem.content[indexProduct].detail != '- -' ? renderRowItem(dataItem, indexProduct) : ''));

  const contentOptional = optionalBenefits
    && optionalBenefits.map(dataItem => (dataItem.content[indexProduct].detail != '- -' ? renderRowItem(dataItem, indexProduct) : ''));

  const benefitSection = (title, list) => (
    <>
      <li className={styles.rowTitle} key={title}>
        <h4 className={styles.sectionTitle}>
          <strong>{title}</strong>
        </h4>
        <div className="dropdown-divider" />
      </li>
      {list}
    </>
  );

  const removeModal = () => {
    setShowModal({ ...showModal, show: false, index: null });
  };

  useState(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Modal background active>
      <div className={styles.modal}>
        <div className={styles.modalTitle} key="modalTitle">
          <strong>{intl.COVERAGE_DESCRIPTION}</strong>
          {/* {attention && ( */}
          <span className={styles.spanClose} onClick={removeModal}>
            X
          </span>
          {/* )} */}
        </div>
        <div className={styles.modalBody}>
          <ul className={styles.listBenefits}>
            {content}
            {complementaryBenefits
              && complementaryBenefits.length > 0
              && benefitSection(
                intl.TITLE_SECTION_COMPLEMENTARY_BENEFITS,
                contentComplementary,
              )}
            {optionalBenefits
              && optionalBenefits.length > 0
              && (country !== 'mx' || country !== 'km')
              && benefitSection(
                intl.TITLE_SECTION_OPTIONAL_BENEFITS,
                contentOptional,
              )}
          </ul>
        </div>
        {Recommended && <div className={styles.bestChoice}>Best Choice</div>}
        <div
          className={
            Recommended
              ? styles.footer
              : `${styles.footer} ${styles.borderTopFooter}`
          }
        >
          <div className={styles.boxPlanSelected}>
            <div className={styles.productName}>{Name}</div>
            <div className={styles.planValueBenefit}>
              <strong className={styles.planBoxFinalValue}>
                <span className={styles.planSymbolValue}>{symbolAmount}</span>
                <span className={styles.planFinalValue}>
                  {AmountPromotion
                    ? getAmountFormat(Amount)
                    : getAmountFormat(AmountWithoutPromotion)}
                </span>
                {AmountPromotion && (
                  <span className={styles.planValue}>
                    {symbolAmount}
                    {getAmountFormat(AmountWithoutPromotion)}
                  </span>
                )}
              </strong>
            </div>
            <div className={styles.btnSelecPlan}>
              <PlansBtnSelect
                product={product[indexProduct]}
                productIndex={indexProduct}
                recommended
                optionalBenefits={optionalBenefits}
                intl={intl}
                dataList={dataList}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalBenefits;
