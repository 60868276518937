export const MODAL_TITLE = {
  es_kc: 'Cotización ajustada',
  es_km: 'Cotización ajustada',
  es_mx: 'Cotización ajustada',
};
export const ADJUSTED_BASED_ON = {
  es_kc: 'Su cotización se ajustó en función de:',
  es_km: 'Su cotización se ajustó en función de:',
  es_mx: 'Su cotización se ajustó en función de:',
};
export const BIRTH_NOTE_MATCH_AGE = {
  es_kc:
    '* La fecha de nacimiento no coincide con la edad de cotización de uno de los viajeros.',
  es_km:
    '* La fecha de nacimiento no coincide con la edad de cotización de uno de los viajeros.',
  es_mx:
    '* La fecha de nacimiento no coincide con la edad de cotización de uno de los viajeros.',
};
export const TEXT_NEW_VALUE = {
  es_kc: (newValue) => `La nueva cotización es $${Number(newValue).toFixed(2)}`,
  es_km: (newValue) => `La nueva cotización es $${Number(newValue).toFixed(2)}`,
  es_mx: (newValue) => `La nueva cotización es $${Number(newValue).toFixed(2)}`,
};
export const BTN_EDIT_INFO = {
  es_kc: 'Editar informacion',
  es_km: 'Editar informacion',
  es_mx: 'Editar informacion',
};
