import { PlanesPrismicService } from './prismic/index';

export default (axios, api) => ({
  getPlanesList: async (idiom = 'es-km') => {
    const headers = {
      'Accept-Language': idiom,
      'Content-type': 'application/json',
    };
    const result = await PlanesPrismicService.getPlanes(headers);
    return result;
  },
});
