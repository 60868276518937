import moment from 'moment';
import {
  EMPTY_STRING,
  MPH_DEFAULT_FISCAL_IDENTITY_CODE,
  RFC,
  MPH_DEFAULT_NATIONALITY_COLOMBIA,
  MALE,
  CAMPO_STATIC,
  DNI,
  MPH_DEFAULT_NATIONALITY_ARGENTINA,
  DEFAULT_COUNTRY_MEXICO,
  MPH_DEFAULT_NATIONALITY_MEXICANA,
} from '../constantsSubmit';
import { langKm, langMx } from '../../../../../location/urls';
import { formatDateOfBirthByAge } from '../../../../../utils/date';

export const formatMPHToApi = (props, Quote) => {
  const { formType } = props;

  const { Cotizar } = props.values;

  const { viajeiros, payment } = Cotizar;

  const firstViajeiro = viajeiros[0];

  const ageFirstViajeiro = Quote.Ages[0].value;

  const {
    nombre,
    apelido,
    confirm_email,
    date_of_birth,
    email,
    index,
    mobile,
    numer_identification,
    typeIdentification,
  } = firstViajeiro;

  const objectFormated = {};

  objectFormated.LastName = apelido;
  objectFormated.MotherLastName = EMPTY_STRING;
  objectFormated.FiscalIdentityCode = EMPTY_STRING;
  objectFormated.Name = nombre;
  objectFormated.MiddleName = EMPTY_STRING;
  objectFormated.IdentificationType = RFC;

  objectFormated.Identification = numer_identification;
  objectFormated.Nationality = MPH_DEFAULT_NATIONALITY_COLOMBIA;

  objectFormated.DateOfBirth = moment(
    formatDateOfBirthByAge(ageFirstViajeiro),
  ).format('YYYY-MM-DD');

  if (formType === langMx || formType === langKm) {
    objectFormated.Nationality = MPH_DEFAULT_NATIONALITY_MEXICANA;
  }

  objectFormated.Profession = EMPTY_STRING;
  objectFormated.Gender = MALE;
  objectFormated.Telephone = mobile;
  objectFormated.Mobile = mobile;
  objectFormated.Email = email;
  objectFormated.NombreRazonSocial = null;

  objectFormated.Address = addressSchema(firstViajeiro, payment, formType);

  return { ...objectFormated };
};

const addressSchema = (viajeiro, payment, formType) => {
  const address = {};
  address.Street = viajeiro.address || EMPTY_STRING;
  address.Number = EMPTY_STRING;
  address.Complement = EMPTY_STRING;
  address.Neighborhood = EMPTY_STRING;
  address.City = viajeiro.city || EMPTY_STRING;
  address.State = viajeiro.state_of_residence || EMPTY_STRING;
  address.Country = MPH_DEFAULT_NATIONALITY_COLOMBIA;

  if (formType === langMx || formType === langKm) {
    address.Country = DEFAULT_COUNTRY_MEXICO;
  }

  address.ZipCode = viajeiro.zip_code || payment.postalCode;
  address.InteriorNumber = CAMPO_STATIC;

  return { ...address };
};
